import axios from "axios";
import { ORCHESTRATION_API_ENDPOINT } from "../utils/Constants";
import { generateAuthorizationToken } from "../utils/utilHelper";

export const validateToken = async (token) => {
  try {
    const response = await axios.get(
      `${ORCHESTRATION_API_ENDPOINT}/user/validateToken?token=${token}`,
      {
        headers: {
          Authorization: generateAuthorizationToken(),
        },
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};
