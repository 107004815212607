import {
  CONST_DOMAIN_MAPPING,
  CONST_SEARCH_PAGE_URL,
  CONST_SEARCH_URL_MIN_VALID_LENGTH,
  CONST_SEARCH_URL_PARAM_KEY,
  CONST_SEARCH_VALUE_PARAM_KEY,
  CONST_TAG,
  DOMAIN,
  WHITELISTED_DOMAIN,
  WHITELISTED_URL_PREFIX,
} from "./Constants";

import moment from "moment";
import "moment-timezone";
import {
  PriceAnalyserResponseSchema,
  PriceHistoryResponseSchema,
  PriceResponseSchema,
} from "../schema/PriceHistorySchema";
import {
  SearchProductDetailsResponseSchema,
  SearchProductListResponseSchema,
} from "../schema/SearchSchema";
import { DealsModel, DealsResponseSchema } from "../schema/DealsSchema";

moment.tz.setDefault("Asia/Kolkata");

export const getTaggedURL = (url: string) : string => {
  let domain = getDomain(url);
  switch (domain) {
    case DOMAIN.AMAZON:
      url+= CONST_TAG + process.env.REACT_APP_AMAZON_TAG;
      break;
    default:
      break;
  }
  return url;
}

export const getCleanSearchURL = (url: string): string | null => {
  let domain = getDomain(url),
    cleanURL = null;
  switch (domain) {
    case DOMAIN.AMAZON:
      cleanURL = getCleanAmazonUrl(url);
      break;
    case DOMAIN.FLIPKART:
      cleanURL = getCleanFlipkartUrl(url);
      break;
    default:
      break;
  }
  return cleanURL;
};

function getCleanAmazonUrl(url: string) {
  const productIndex = url.indexOf("/dp/");

  if (productIndex !== -1) {
    let endProductIndex = url.indexOf("/", productIndex + 4);
    if (endProductIndex !== -1) {
      const baseUrl = url.substring(0, endProductIndex).split("/ref=")[0];
      return baseUrl;
    }
    endProductIndex = url.indexOf("?", productIndex + 4);
    if (endProductIndex !== -1) {
      const baseUrl = url.substring(0, endProductIndex).split("/ref=")[0];
      return baseUrl;
    }
  }
  return url.split("/ref=")[0];
}

function getCleanFlipkartUrl(url: string) {
  try {
    const urlObj = new URL(url);
    const basePath = urlObj.pathname;
    const query = urlObj.search;

    if (query !== null && query.includes("pid=")) {
      const queryParams = query.substring(1).split("&");
      let pidValue = null;

      for (const param of queryParams) {
        if (param.startsWith("pid=")) {
          pidValue = param;
          break;
        }
      }

      if (pidValue !== null) {
        return urlObj.protocol + "//" + urlObj.host + basePath + "?" + pidValue;
      } else {
        return url;
      }
    } else {
      return url;
    }
  } catch (error) {
    throw new Error("Invalid URL: " + url);
  }
}

export const isSearchLinkValid = (
  searchLink: string,
  existingLinkInURL: string
): boolean => {
  const isNotEmpty = searchLink && searchLink.trim() !== "";
  const isDifferentURL =
    existingLinkInURL.trim() === "" || existingLinkInURL !== searchLink;
  const isLengthValid = searchLink.length >= CONST_SEARCH_URL_MIN_VALID_LENGTH;
  for (const prefix of WHITELISTED_URL_PREFIX) {
    if (searchLink.startsWith(prefix)) {
      return Boolean(isNotEmpty && isDifferentURL && isLengthValid);
    }
  }
  return false;
};

export const isDomainValid = (domain: DOMAIN | null): boolean => {
  const isNotEmpty = domain && domain.trim() !== "";
  if (isNotEmpty) {
    for (const whitelistDomain of WHITELISTED_DOMAIN) {
      if (domain === whitelistDomain) {
        return true;
      }
    }
  }
  return false;
};

export const generateAuthorizationToken = () => {
  let token = moment().valueOf().toString(),
    secretKey = process.env.REACT_APP_AUTHORIZATION_SECRET_KEY;
  return Number(token) * Number(secretKey);
};

export const getDomain = (url: string): DOMAIN | null => {
  const domainMatch = url.match(/:\/\/([^/]+)/);
  if (domainMatch && domainMatch[1]) {
    const parts = domainMatch[1].split(".");
    if (parts.length >= 2) {
      const domain: string = parts[parts.length - 2].toUpperCase();
      const mappedDomain = CONST_DOMAIN_MAPPING[domain] || domain;
      return mappedDomain as DOMAIN;
    }
  }
  return null;
};

export const handleBuyOnButtonClick = (url: string) => {
  const newWindow = window.open(
   getTaggedURL(url),
    "_blank",
    "noopener,noreferrer"
  );
  if (newWindow) {
    newWindow.opener = null;
  }
};

export const getProductList = (deals: any) => {
  let products = [];
  if (
    deals &&
    deals.lightningDeals != null &&
    deals.lightningDeals.length > 0
  ) {
    for (const obj of deals.lightningDeals) {
      obj.dealType = "Lightning Deal";
      if (
        obj.previousPrice &&
        obj.latestPrice &&
        Number(obj.previousPrice) - Number(obj.latestPrice) > 0
      ) {
        obj.priceDrop = obj.previousPrice - obj.latestPrice;
      } else {
        obj.priceDrop = "N/A";
      }
      products.push(obj);
    }
  }
  if (deals && deals.hotDeals != null && deals.hotDeals.length > 0) {
    for (const obj of deals.hotDeals) {
      obj.dealType = "Hot Deal";
      if (
        obj.previousPrice &&
        obj.latestPrice &&
        Number(obj.previousPrice) - Number(obj.latestPrice) > 0
      ) {
        obj.priceDrop = obj.previousPrice - obj.latestPrice;
      } else {
        obj.priceDrop = "N/A";
      }
      products.push(obj);
    }
  }
  if (deals && deals.deals != null && deals.deals.length > 0) {
    for (const obj of deals.deals) {
      obj.dealType = "Deal";
      if (
        obj.previousPrice &&
        obj.latestPrice &&
        Number(obj.previousPrice) - Number(obj.latestPrice) > 0
      ) {
        obj.priceDrop = obj.previousPrice - obj.latestPrice;
      } else {
        obj.priceDrop = "N/A";
      }
      products.push(obj);
    }
  }
  return products;
};

export const isValidName = (name: string) => {
  const nameRegex = /^[A-Za-z]{2,}$/;
  return nameRegex.test(name);
};

export const isSingUpDateValid = (selectedDate: any) => {
  const currentDate = new Date();
  const selectedDateObj = new Date(selectedDate);

  const twelveYearsAgo = new Date();
  twelveYearsAgo.setFullYear(currentDate.getFullYear() - 12);
  twelveYearsAgo.setHours(0, 0, 0, 0);

  return selectedDateObj.getTime() <= twelveYearsAgo.getTime();
};

export const isDateValid = (selectedDate: any) => {
  const minAcceptedDate = new Date();
  minAcceptedDate.setFullYear(minAcceptedDate.getFullYear() - 12);
  const selectedDateObj = new Date(selectedDate);
  return selectedDateObj <= minAcceptedDate;
};

export const isStrongPassword = (password: any) => {
  const strongPasswordRegex =
    /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
  return strongPasswordRegex.test(password);
};

export const isValidSearchValue = (
  searchValue: string | null | undefined
): boolean => {
  return typeof searchValue === "string" && searchValue.trim().length >= 2;
};

export const isValidSearchURL = (searchURL: string): boolean => {
  const isNotEmpty = searchURL && searchURL.trim() !== "";
  const isLengthValid = searchURL.length >= CONST_SEARCH_URL_MIN_VALID_LENGTH;
  for (const prefix of WHITELISTED_URL_PREFIX) {
    if (searchURL.startsWith(prefix)) {
      return Boolean(isNotEmpty && isLengthValid);
    }
  }
  return false;
};

export const isSearchProductDetailsValid = (
  searchResponse: SearchProductDetailsResponseSchema | null | undefined
): boolean => {
  return Boolean(
    searchResponse &&
      typeof searchResponse.title === "string" &&
      typeof searchResponse.image === "string" &&
      typeof searchResponse.url === "string" &&
      (searchResponse.domain === DOMAIN.AMAZON ||
        searchResponse.domain === DOMAIN.FLIPKART)
  );
};

export const isSearchProductListValid = (
  response: SearchProductListResponseSchema[] | null | undefined
): boolean => {
  const urlPattern = /^https:\/\/.*/;
  if (response) {
    for (const item of response) {
      if (
        !item.title ||
        !item.image ||
        !urlPattern.test(item.image) ||
        !item.domain ||
        !urlPattern.test(item.url)
      ) {
        return false;
      }
      if (item.rating && isNaN(parseFloat(item.rating))) {
        return false;
      }
      if (
        item.discountPrice &&
        isNaN(parseFloat(item.discountPrice.toString()))
      ) {
        return false;
      }
      if (
        item.originalPrice &&
        isNaN(parseFloat(item.originalPrice.toString()))
      ) {
        return false;
      }
    }
    return true;
  } else {
    return false;
  }
};

export const isPriceHistoryResponseValid = (
  response: PriceHistoryResponseSchema | null | undefined
): boolean => {
  if (
    !response ||
    !validatePriceAnalyserResponse(response.analysis) ||
    !validatePriceResponse(response.minimumPrice) ||
    !validatePriceResponse(response.lastPriceDrop) ||
    !validatePriceResponse(response.maximumPrice) ||
    !isPriceHistoryGraphDataValid(response.price)
  ) {
    return false;
  }
  return true;
};

export const isPriceHistoryGraphDataValid = (
  price: Record<string, number>
): boolean => {
  return price && Object.keys(price).length > 0;
};

function validatePriceAnalyserResponse(
  response: PriceAnalyserResponseSchema
): boolean {
  return !isNaN(parseFloat(response.status.toString()));
}

function validatePriceResponse(response: PriceResponseSchema): boolean {
  return !isNaN(parseFloat(response.price.toString()));
}

export const findSearchNavigationURL = (searchText: string): string => {
  let navigationURL = CONST_SEARCH_PAGE_URL;
  if (isValidSearchURL(searchText)) {
    navigationURL += CONST_SEARCH_URL_PARAM_KEY + searchText;
  } else {
    navigationURL +=
      CONST_SEARCH_VALUE_PARAM_KEY + searchText.replace(/ +/g, "+");
  }
  return navigationURL;
};

export const validateDealsResponse = (
  response: DealsResponseSchema
): boolean => {
  if (
    response &&
    Array.isArray(response.lightningDeals) &&
    Array.isArray(response.hotDeals) &&
    Array.isArray(response.deals)
  ) {
    return (
      response.lightningDeals.every((deal: DealsModel) =>
        validateDealsModel(deal)
      ) &&
      response.hotDeals.every((deal: DealsModel) => validateDealsModel(deal)) &&
      response.deals.every((deal: DealsModel) => validateDealsModel(deal))
    );
  }
  return false;
};

function validateDealsModel(deal: DealsModel): boolean {
  return (
    deal &&
    typeof deal.title === "string" &&
    typeof deal.previousPrice === "number" &&
    typeof deal.previousDate === "string" &&
    typeof deal.latestPrice === "number" &&
    typeof deal.latestDate === "string" &&
    typeof deal.image === "string" &&
    typeof deal.domain === "string" &&
    typeof deal.url === "string"
  );
}

export const updateAgoPrice = (providedTimestamp: string) => {
  const currentTime = moment();
  const providedTime = moment(providedTimestamp);
  const duration = moment.duration(currentTime.diff(providedTime));

  let timeDifferenceString = "Last updated ";

  if (duration.years() > 0) {
    timeDifferenceString += `${duration.years()} year${
      duration.years() > 1 ? "s" : ""
    } ago`;
  } else if (duration.months() > 0) {
    timeDifferenceString += `${duration.months()} month${
      duration.months() > 1 ? "s" : ""
    } ago`;
  } else if (duration.days() > 0) {
    timeDifferenceString += `${duration.days()} day${
      duration.days() > 1 ? "s" : ""
    } ago`;
  } else if (duration.hours() > 0) {
    timeDifferenceString += `${duration.hours()} hour${
      duration.hours() > 1 ? "s" : ""
    } ago`;
  } else if (duration.minutes() > 0) {
    timeDifferenceString += `${duration.minutes()} minute${
      duration.minutes() > 1 ? "s" : ""
    } ago`;
  } else {
    timeDifferenceString += `${duration.seconds()} second${
      duration.seconds() > 1 ? "s" : ""
    } ago`;
  }

  return timeDifferenceString;
};

export const isRefreshNeeded = (providedTimestamp: string) => {
  const currentTime = moment();
  const providedTime = moment(providedTimestamp);
  const duration = moment.duration(currentTime.diff(providedTime));

  let isRefreshRequired = true;

  if (duration.hours() <= 1) {
    isRefreshRequired = false;
  }

  return isRefreshRequired;
};
