import {
  Box,
  Radio,
  Stack,
  Button,
  Drawer,
  Divider,
  IconButton,
  Typography,
  RadioGroup,
  FormControlLabel,
} from "@mui/material";
import Scrollbar from "../scrollbar";
import Iconify from "../../customElements/Iconify";

export const SORT_BY_OPTIONS = [
  { value: "latest", label: "Latest" },
  { value: "priceDesc", label: "Price: High-Low" },
  { value: "priceAsc", label: "Price: Low-High" },
];
export const FILTER_STORE_OPTIONS = ["Amazon", "Flipkart"];
export const FILTER_PRICE_OPTIONS = [
  { value: "below", label: "Below ₹499" },
  { value: "between", label: "Between ₹499 - ₹999" },
  { value: "above", label: "Above ₹999" },
];

export default function ShopFilterSidebar({
  openFilter,
  onOpenFilter,
  onCloseFilter,
  filterValue,
  setFilterValue,
}) {
  const handleStoreChange = (e) => {
    setFilterValue({ ...filterValue, store: e.target.value });
  };

  const handlePriceChange = (e) => {
    setFilterValue({ ...filterValue, alertPrice: e.target.value });
  };

  return (
    <>
      <Button
        disableRipple
        color="inherit"
        endIcon={<Iconify icon="ic:round-filter-list" />}
        onClick={onOpenFilter}
      >
        Filters&nbsp;
      </Button>

      <Drawer
        anchor="right"
        open={openFilter}
        onClose={onCloseFilter}
        PaperProps={{
          sx: { width: 280, border: "none", overflow: "hidden" },
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ px: 1, py: 2 }}
        >
          <Typography variant="subtitle1" sx={{ ml: 1 }}>
            Filters
          </Typography>
          <IconButton onClick={onCloseFilter}>
            <Iconify icon="eva:close-fill" />
          </IconButton>
        </Stack>

        <Divider />

        <Scrollbar>
          <Stack spacing={3} sx={{ p: 3 }}>
            <div>
              <Typography variant="subtitle1" gutterBottom>
                Stores
              </Typography>
              <RadioGroup
                name="storeFilter"
                value={filterValue.store}
                onChange={handleStoreChange}
              >
                {FILTER_STORE_OPTIONS.map((item) => (
                  <FormControlLabel
                    key={item}
                    value={item}
                    control={<Radio />}
                    label={item}
                  />
                ))}
              </RadioGroup>
            </div>

            <div>
              <Typography variant="subtitle1" gutterBottom>
                Alert Price
              </Typography>
              <RadioGroup
                name="alertPriceFilter"
                value={filterValue.alertPrice}
                onChange={handlePriceChange}
              >
                {FILTER_PRICE_OPTIONS.map((item) => (
                  <FormControlLabel
                    key={item.value}
                    value={item.value}
                    control={<Radio />}
                    label={item.label}
                  />
                ))}
              </RadioGroup>
            </div>
          </Stack>
        </Scrollbar>

        <Box sx={{ p: 3 }}>
          <Button
            fullWidth
            size="large"
            type="submit"
            color="inherit"
            variant="outlined"
            onClick={() => {
              setFilterValue({
                store: "",
                alertPrice: "",
              });
              onCloseFilter();
            }}
            startIcon={<Iconify icon="ic:round-clear-all" />}
          >
            Clear All
          </Button>
        </Box>
      </Drawer>
    </>
  );
}
