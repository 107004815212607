import {
  Box,
  Radio,
  Stack,
  Button,
  Drawer,
  Divider,
  IconButton,
  Typography,
  RadioGroup,
  FormControlLabel,
} from "@mui/material";
import Scrollbar from "../../components/scrollbar";
import Iconify from "../../customElements/Iconify";
import "./search.scss";
import { StyledRating } from "../../customElements/StyledRating";

export const SORT_BY_OPTIONS = [
  { value: "popularity", label: "Popularity" },
  { value: "priceDesc", label: "Price: High-Low" },
  { value: "priceAsc", label: "Price: Low-High" },
];
export const FILTER_STORE_OPTIONS = ["Amazon", "Flipkart"];
export const FILTER_PRICE_OPTIONS = [
  { value: "below", label: "Below ₹499" },
  { value: "between", label: "Between ₹499 - ₹999" },
  { value: "above", label: "Above ₹999" },
];
export const FILTER_RATING_OPTIONS = [
  "up4Star",
  "up3Star",
  "up2Star",
  "up1Star",
];
// ----------------------------------------------------------------------

export default function SearchFilterSidebar({
  openFilter,
  onOpenFilter,
  onCloseFilter,
  filterValue,
  setFilterValue,
}) {
  const handleStoreChange = (e) => {
    setFilterValue({ ...filterValue, store: e.target.value });
  };

  const handlePriceChange = (e) => {
    setFilterValue({ ...filterValue, discountPrice: e.target.value });
  };

  const handleRatingChange = (e) => {
    setFilterValue({ ...filterValue, rating: e.target.value });
  };
  return (
    <>
      <Button
        disableRipple
        color="inherit"
        endIcon={<Iconify icon="ic:round-filter-list" />}
        onClick={onOpenFilter}
      >
        Filters&nbsp;
      </Button>

      <Drawer
        anchor="right"
        open={openFilter}
        onClose={onCloseFilter}
        PaperProps={{
          sx: { width: 280, border: "none", overflow: "hidden" },
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ px: 1, py: 2 }}
        >
          <Typography variant="subtitle1" sx={{ ml: 1 }}>
            Filters
          </Typography>
          <IconButton onClick={onCloseFilter}>
            <Iconify icon="eva:close-fill" />
          </IconButton>
        </Stack>

        <Divider />

        <Scrollbar>
          <Stack spacing={3} sx={{ p: 3 }}>
            <div>
              <Typography variant="subtitle1" gutterBottom>
                Stores
              </Typography>
              <RadioGroup
                name="storeFilter"
                value={filterValue.store}
                onChange={handleStoreChange}
              >
                {FILTER_STORE_OPTIONS.map((item) => (
                  <FormControlLabel
                    key={item}
                    value={item}
                    control={<Radio />}
                    label={item}
                  />
                ))}
              </RadioGroup>
            </div>

            <div>
              <Typography variant="subtitle1" gutterBottom>
                Price
              </Typography>
              <RadioGroup
                name="discountPriceFilter"
                value={filterValue.discountPrice}
                onChange={handlePriceChange}
              >
                {FILTER_PRICE_OPTIONS.map((item) => (
                  <FormControlLabel
                    key={item.value}
                    value={item.value}
                    control={<Radio />}
                    label={item.label}
                  />
                ))}
              </RadioGroup>
            </div>
            <div>
              <Typography variant="subtitle1" gutterBottom>
                Rating
              </Typography>
              <RadioGroup
                name="discountRatingFilter"
                value={filterValue.rating}
                onChange={handleRatingChange}
              >
                {FILTER_RATING_OPTIONS.map((item, index) => (
                  <FormControlLabel
                    key={item}
                    value={item}
                    control={
                      <Radio
                        disableRipple
                        color="default"
                        icon={<StyledRating readOnly value={4 - index} />}
                        checkedIcon={
                          <StyledRating readOnly value={4 - index} />
                        }
                        sx={{
                          "&:hover": { bgcolor: "transparent" },
                        }}
                      />
                    }
                    label={
                      <div
                        className={
                          filterValue.rating === item ? "activeRating" : null
                        }
                      >
                        & Up
                      </div>
                    }
                    sx={{
                      my: 0.5,
                      borderRadius: 1,
                      "&:hover": { opacity: 0.48 },
                    }}
                  />
                ))}
              </RadioGroup>
            </div>
          </Stack>
        </Scrollbar>

        <Box sx={{ p: 3 }}>
          <Button
            fullWidth
            size="large"
            type="submit"
            color="inherit"
            variant="outlined"
            onClick={() => {
              setFilterValue({
                store: "",
                alertPrice: "",
              });
              onCloseFilter();
            }}
            startIcon={<Iconify icon="ic:round-clear-all" />}
          >
            Clear All
          </Button>
        </Box>
      </Drawer>
    </>
  );
}
