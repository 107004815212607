import { useState } from "react";
import { Avatar, Snackbar, Alert, Grid } from "@mui/material";
import { useAppContext } from "../../store/AppContextProvider";
import { isSingUpDateValid, isValidName } from "../../utils/utilHelper";
import { updateUserInfoAPI } from "../../APIs/updateUserInfoAPI";

const ProfileAvatars = () => {
  const { loggedIn, userInfo, setUserInfo } = useAppContext();
  const [error, setError] = useState("");
  const [avatar, setAvatar] = useState(null);
  const vertical = "top";
  const horizontal = "center";

  const numbers = Array.from({ length: 25 }, (_, index) => index);

  const changeAvatar = async (avatar) => {
    if (loggedIn && userInfo) {
      if (
        avatar !== userInfo.profilePictureUrl &&
        isValidName(userInfo.firstName) &&
        isValidName(userInfo.lastName) &&
        isSingUpDateValid(userInfo.dateOfBirth) &&
        userInfo.profileCoverUrl &&
        userInfo.profilePictureUrl
      ) {
        setAvatar(avatar);
        setError("");
        userInfo.profilePictureUrl = avatar;
        const response = await updateUserInfoAPI(userInfo);
        if (response && Object.keys(response).length >= 6) {
          setUserInfo(response);
        } else {
          setError(response);
        }
      }
      setAvatar(null);
    } else {
      setError("Please login to change");
    }
  };

  return (
    <>
      <Snackbar
        open={error && error.length > 0 ? true : false}
        autoHideDuration={1000}
        onClose={() => setError("")}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert severity="error" sx={{ width: "100%" }}>
          {error}
        </Alert>
      </Snackbar>
      <Grid
        container
        spacing={2}
        style={{ display: "flex", justifyContent: "center" }}
      >
        {numbers.map((number) => (
          <Grid
            item
            sm={4}
            md={3}
            xl={2}
            key={`avatar${number}`}
            onClick={() => changeAvatar(`avatar_${number}`)}
          >
            <Avatar
              className={
                loggedIn &&
                userInfo &&
                Object.keys(userInfo).length >= 6 &&
                ((userInfo.profilePictureUrl === `avatar_default` &&
                  `avatar_${number}` === "avatar_0") ||
                  userInfo.profilePictureUrl === `avatar_${number}`)
                  ? "activeAvatar"
                  : "inactiveAvatar"
              }
              src={
                avatar === "avatar_" + number
                  ? "/assets/gif/updating2.gif"
                  : `/assets/images/avatars/avatar_${number}.jpg`
              }
              alt="photoURL"
              sx={{ width: 80, height: 80, m: 1.2 }}
            />
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default ProfileAvatars;
