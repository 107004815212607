import axios from "axios";
import { ORCHESTRATION_API_ENDPOINT } from "../utils/Constants";
import moment from "moment";
import "moment-timezone";
import {
  generateAuthorizationToken,
  isSearchProductDetailsValid,
} from "../utils/utilHelper";
import { ErrorMessages } from "../helper/ErrorMessageEnum";
import { SearchProductDetailsFromURLAPIResponse } from "../schema/CommonSchema";
import { SearchProductDetailsResponseSchema } from "../schema/SearchSchema";

moment.tz.setDefault("Asia/Kolkata");

export const fetchDetailsFromSearchURL_API = async (
  search_url: string,
  refresh: boolean = false
): Promise<SearchProductDetailsFromURLAPIResponse> => {
  let productDetailResponse: SearchProductDetailsFromURLAPIResponse = {
    data: null,
    error: null,
  };
  let data: SearchProductDetailsResponseSchema | null = null,
    error: string | null = null;
  try {
    const response = await axios.get(
      `${ORCHESTRATION_API_ENDPOINT}/getDetails?url=${search_url}&refresh=${refresh}`,
      {
        headers: {
          Authorization: generateAuthorizationToken(),
        },
      }
    );
    if (response.status === 200) {
      let searchResponse: SearchProductDetailsResponseSchema = response.data;
      if (isSearchProductDetailsValid(searchResponse)) {
        data = searchResponse;
      } else {
        throw new Error(ErrorMessages.SOMETHING_WENT_WRONG);
      }
    } else {
      throw new Error(ErrorMessages.SOMETHING_WENT_WRONG);
    }
  } catch (e: any) {
    if (!e) {
      error = ErrorMessages.SOMETHING_WENT_WRONG;
    } else {
      error = e.message;
    }
  }
  productDetailResponse.data = data;
  productDetailResponse.error = error;
  return productDetailResponse;
};
