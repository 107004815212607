import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { handleSearch } from "../action/SearchPageAction";
import { Box, Step, StepLabel, Stepper, styled } from "@mui/material";
import SnackBarError from "../customElements/SnackBar/SnackBarError";
import { SearchProductListComponent } from "../components/search/SearchProductListComponent";
import { SearchURLProductDetailsComponent } from "../components/search/SearchURLProductDetailsComponent";
import SnackBarSuccess from "../customElements/SnackBar/SnackBarSuccess";
import { useAppContext } from "../store/AppContextProvider";
import { NAV_WIDTH } from "../utils/Constants";
import { storeDataWithTimestamp } from "../utils/caching";
import {
  isSearchProductDetailsValid,
} from "../utils/utilHelper";
import { fetchDetailsFromSearchURL_API } from "../APIs/fetchDetailsFromSearchURLAPI";
import { RotatingLines } from "react-loader-spinner";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import EmojiObjectsIcon from "@mui/icons-material/EmojiObjects";
import HistoryIcon from "@mui/icons-material/History";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";

const steps = [
  { label: "Analysing the URL", icon: <EmojiObjectsIcon /> },
  { label: "Gathering Product Information", icon: <HistoryIcon /> },
  { label: "Collecting Price History", icon: <EmojiObjectsIcon /> },
  { label: "Calculating Savings Potential", icon: <AttachMoneyIcon /> },
  { label: "Finalizing the Report", icon: <AssignmentTurnedInIcon /> },
];

const ActiveStepLabel = styled(StepLabel)({
  "&.MuiStepLabel-active": {
    color: "amber", // Customize the color for the active step
  },
});

const SearchPage = () => {
  const location = useLocation();
  const { setSearchBarOpen, setIsSearching, windowWidth, isMobile } =
    useAppContext();
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [searchURLProductDetails, setSearchURLProductDetails] = useState({});
  const [searchValueProductList, setSearchValueProductList] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [activeStep, setActiveStep] = useState(-1);
  const [showSteps, setShowSteps] = useState(false);
  const totalTime = 7000; // 7 seconds
  const stepTime = totalTime / steps.length;

  useEffect(() => {
    window.scrollTo(0, 0);
    let params = new URLSearchParams(location.search);
    handleSearch(
      params,
      setIsLoading,
      setSearchURLProductDetails,
      setSearchValueProductList,
      setErrorMessage
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  const handleRefreshOnDemand = async () => {
    setRefresh(true);
    const search_url = searchURLProductDetails.url;
    let productDetailsFromSearchURL = null;
    productDetailsFromSearchURL = await fetchDetailsFromSearchURL_API(
      search_url,
      true
    );
    if (
      !productDetailsFromSearchURL.error &&
      isSearchProductDetailsValid(productDetailsFromSearchURL.data)
    ) {
      productDetailsFromSearchURL = productDetailsFromSearchURL.data;
      storeDataWithTimestamp(search_url,productDetailsFromSearchURL);
      setSearchURLProductDetails({ ...productDetailsFromSearchURL });
      setRefresh(false);
    } else {
      setErrorMessage(productDetailsFromSearchURL.error);
      setRefresh(false);
    }
  };

  useEffect(() => {
    setShowSteps(false);
    setActiveStep(-1);
    setSearchBarOpen(isLoading);
    setIsSearching(isLoading);
    setTimeout(() => {
      if (isLoading) {
        setShowSteps(true);

        for (let i = 0; i < steps.length; i++) {
          if (isLoading) {
            setTimeout(() => {
              setActiveStep(i);
            }, stepTime * i);
          }
        }
      } else {
        setActiveStep(-1);
        setShowSteps(false);
      }
    }, 2000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, setIsSearching, setSearchBarOpen]);

  return (
    <div className="search_page_container">
      <SnackBarSuccess
        successMessage={successMessage}
        setSuccessMessage={setSuccessMessage}
      />
      <SnackBarError
        errorMessage={errorMessage}
        setErrorMessage={setErrorMessage}
      />
      {!isLoading ? (
        <div className="search_page_value_content">
          {searchValueProductList ? (
            <SearchProductListComponent
              searchValueProductList={searchValueProductList}
            />
          ) : (
            <div className="search_page_url_content">
              {searchURLProductDetails ? (
                <SearchURLProductDetailsComponent
                  searchURLProductDetails={searchURLProductDetails}
                  handleRefreshOnDemand={handleRefreshOnDemand}
                  refresh={refresh}
                  setErrorMessage={setErrorMessage}
                  setSuccessMessage={setSuccessMessage}
                />
              ) : (
                <div className="search_page_no_result">
                  <Box
                    component="img"
                    alt="empty_home_page"
                    src="/assets/illustrations/no_result_found.svg"
                    sx={{
                      height: 420,
                      px: "20px",
                      mx: "auto",
                      my: { xs: 5, sm: 10 },
                    }}
                  />
                </div>
              )}
            </div>
          )}
        </div>
      ) : (
        <div
          style={{
            minWidth: 380,
            width: isMobile ? windowWidth : windowWidth - NAV_WIDTH - 100,
            height: "70vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {showSteps ? (
            <Stepper activeStep={activeStep} orientation="vertical">
              {steps.map((step, index) => (
                <Step key={step.label}>
                  <ActiveStepLabel
                    icon={
                      index < activeStep ? (
                        <CheckCircleIcon style={{ color: "green" }} />
                      ) : (
                        step.icon
                      )
                    }
                  >
                    {step.label}
                    {activeStep === 4 && index === activeStep && " ..."}
                  </ActiveStepLabel>
                </Step>
              ))}
            </Stepper>
          ) : (
            <RotatingLines
              strokeColor="grey"
              strokeWidth="4"
              animationDuration="0.75"
              width="75"
              visible={true}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default SearchPage;
