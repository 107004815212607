export const formatDate = (dateString) => {
  const monthsAbbreviation = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const date = new Date(Number(dateString));
  const day = date.getUTCDate();
  const monthAbbreviation = monthsAbbreviation[date.getUTCMonth()];
  const year = date.getUTCFullYear();

  return `${day}-${monthAbbreviation}-${year}`;
};

export const getTimeAgo = (timestamp) => {
  const currentTime = new Date().getTime();
  const givenTime = new Date(Number(timestamp)).getTime();
  const timeDifference = currentTime - givenTime;
  const seconds = Math.floor(timeDifference / 1000);

  if (seconds < 60) {
    return seconds === 1 ? `${seconds} second ago` : `${seconds} seconds ago`;
  }

  const minutes = Math.floor(seconds / 60);
  if (minutes < 60) {
    return minutes === 1 ? `${minutes} minute ago` : `${minutes} minutes ago`;
  }

  const hours = Math.floor(minutes / 60);
  return hours === 1 ? `${hours} hour ago` : `${hours} hours ago`;
};

export const getTimeAgoFromDate = (timestamp) => {
  const givenTime = new Date(timestamp);
  const currentTime = new Date();
  const timeDifference = currentTime - givenTime;
  const seconds = Math.floor(timeDifference / 1000);

  if (seconds < 60) {
    return seconds === 1 ? `${seconds} second ago` : `${seconds} seconds ago`;
  }

  const minutes = Math.floor(seconds / 60);
  if (minutes < 60) {
    return minutes === 1 ? `${minutes} minute ago` : `${minutes} minutes ago`;
  }

  const hours = Math.floor(minutes / 60);
  return hours === 1 ? `${hours} hour ago` : `${hours} hours ago`;
};
