import { useEffect, useState } from "react";
import {
  Link,
  Stack,
  IconButton,
  InputAdornment,
  TextField,
  Checkbox,
  FormControlLabel,
  Alert,
  Snackbar,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Mail } from "@mui/icons-material";
import { userRequest } from "../../APIs/userRequestAPI";
import { useAppContext } from "../../store/AppContextProvider";
import Iconify from "../../customElements/Iconify";
import { useLocation, useNavigate } from "react-router-dom";

// ----------------------------------------------------------------------

export default function LoginForm() {
  const navigate = useNavigate();
  const location = useLocation();
  const [snackOpen, setSnackOpen] = useState({
    open: false,
    vertical: "top",
    horizontal: "center",
  });
  const { vertical, horizontal, open } = snackOpen;
  const { setLoggedIn } = useAppContext();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [forgetPassword, setForgetPassword] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [mailSent, setMailSent] = useState(false);

  const [showPassword, setShowPassword] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackOpen({ ...snackOpen, open: false });
  };

  useEffect(() => {
    setError("");
    if (location.hash === "#forgetPassword") {
      setForgetPassword(true);
    } else {
      setForgetPassword(false);
    }
  }, [location]);

  const handleClick = async () => {
    if (isValidEmail(email) && isStrongPassword(password)) {
      setError("");
      setIsLoading(true);
      let loginObj = {};
      loginObj.email = email;
      loginObj.password = password;
      loginObj.rememberMe = rememberMe;
      const response = await userRequest(loginObj, "login");
      if (response === true) {
        setSnackOpen({ ...snackOpen, open: true });
        localStorage.setItem("lastVisitedProfile", 1);
        setLoggedIn(true);
      } else {
        setIsLoading(false);
        setLoggedIn(false);
        setError(response);
      }
    } else {
      setIsLoading(false);
      setLoggedIn(false);
      setError("Invalid Credentials");
    }
  };

  const sendMail = async () => {
    if (isValidEmail(email)) {
      setIsLoading(true);
      let obj = { email: email };
      const response = await userRequest(obj, "forgetPassword");
      if (response === true) {
        setMailSent("true");
      } else {
        setError(response);
      }
    } else {
      setError("Invalid Email");
    }
    setIsLoading(false);
  };

  const isValidEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  };

  const isStrongPassword = (password) => {
    const strongPasswordRegex =
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
    return strongPasswordRegex.test(password);
  };

  return (
    <>
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert severity="success" sx={{ width: "100%" }}>
          Login Success
        </Alert>
      </Snackbar>
      <Stack spacing={3}>
        <TextField
          name="email"
          label="Email address"
          value={email}
          onChange={(e) => {
            setError("");
            setEmail(e.target.value);
          }}
        />

        {forgetPassword ? null : (
          <TextField
            name="password"
            label="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            type={showPassword ? "text" : "password"}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setShowPassword(!showPassword)}
                    edge="end"
                  >
                    <Iconify
                      icon={showPassword ? "eva:eye-fill" : "eva:eye-off-fill"}
                    />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        )}
      </Stack>
      {mailSent === "true" ? (
        <Stack sx={{ width: "100%", my: 2 }}>
          <Alert>
            Reset link email sent to <strong>{email}.</strong>
            <br />
            Please check your "Inbox" and "Spam" folder as well.
          </Alert>
          <Typography variant="subtitle2" sx={{ color: "text.disabled" }}>
            Please close this tab
          </Typography>
        </Stack>
      ) : (
        <>
          {forgetPassword ? null : (
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              sx={{ my: 2 }}
            >
              <FormControlLabel
                control={<Checkbox name="remember" />}
                label="Remember me"
                checked={rememberMe}
                onChange={(e) => setRememberMe(e.target.checked)}
              />
              <Link
                variant="subtitle2"
                underline="hover"
                sx={{ cursor: "pointer" }}
                onClick={() => {
                  setError("");
                  setForgetPassword(true);
                  navigate("/login#forgetPassword");
                }}
              >
                Forgot password?
              </Link>
            </Stack>
          )}
          {error && error.length > 0 && <Alert severity="error">{error}</Alert>}
          {forgetPassword ? (
            <LoadingButton
              fullWidth
              type="button"
              variant="contained"
              color="secondary"
              loading={isLoading}
              loadingIndicator="Sending..."
              onClick={sendMail}
              sx={{ my: 4 }}
              startIcon={<Mail />}
            >
              Send Reset Link
            </LoadingButton>
          ) : (
            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              loading={isLoading}
              loadingIndicator="Signing In..."
              variant="contained"
              onClick={handleClick}
            >
              Login
            </LoadingButton>
          )}
        </>
      )}
    </>
  );
}
