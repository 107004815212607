export const formatToIndianCurrency = (number) => {
  if (number != null) {
    const indianCurrencySymbol = "₹";

    // Convert the number to a string
    const numberStr = number.toString();

    // Separate integer and decimal parts
    const [integerPart, decimalPart] = numberStr.split(".");

    // Format the integer part with commas
    const formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    // Combine the formatted integer with the decimal part and add currency symbol
    const result =
      indianCurrencySymbol +
      formattedInteger +
      (decimalPart ? "." + decimalPart.slice(0, 2) : "");

    return result;
  } else return null;
};

export const calculateDiscountPercentage = (originalPrice, discountPrice) => {
  const percentageSymbol = "%";
  const space = " ";
  const off = "off";
  let roundedDiscountPercentage = null;
  // Ensure both originalPrice and discountPrice are valid positive numbers
  if (
    typeof originalPrice !== "number" ||
    originalPrice <= 0 ||
    typeof discountPrice !== "number" ||
    discountPrice <= 0
  ) {
    return roundedDiscountPercentage;
  }
  const discountAmount = originalPrice - discountPrice;
  const discountPercentage = (discountAmount / originalPrice) * 100;

  roundedDiscountPercentage = Math.round((discountPercentage * 100) / 100);

  if (roundedDiscountPercentage < 0.9 && roundedDiscountPercentage > 100) {
    return null;
  }
  return roundedDiscountPercentage + percentageSymbol + space + off;
};
