import { useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Header from "./header";
import Nav from "./nav";
import { ProductCartWidget } from "../components/products";
import { useAppContext } from "../store/AppContextProvider";

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const StyledRoot = styled("div")({
  display: "flex",
  minHeight: "100%",
  overflow: "hidden",
});

const Main = styled("div")(({ theme }) => ({
  flexGrow: 1,
  overflow: "auto",
  minHeight: "100%",
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up("lg")]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

const SpecialMessageMain = styled("div")(({ theme }) => ({
  flexGrow: 1,
  overflow: "auto",
  minHeight: "100%",
  paddingTop: APP_BAR_MOBILE + 24 + 50,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up("lg")]: {
    paddingTop: APP_BAR_DESKTOP + 24 + 50,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const { showSpecialMessage } = useAppContext();
  const [open, setOpen] = useState(false);
  const location = useLocation();

  return (
    <StyledRoot>
      <Header onOpenNav={() => setOpen(true)} />

      <Nav openNav={open} onCloseNav={() => setOpen(false)} />
      {showSpecialMessage ? (
        <SpecialMessageMain>
          <Outlet />
        </SpecialMessageMain>
      ) : (
        <Main>
          <Outlet />
        </Main>
      )}
      {location.pathname !== "/dashboard/alerts" &&
        location.pathname !== "/404" && (
          <ProductCartWidget showSpecialMessage={showSpecialMessage} />
        )}
    </StyledRoot>
  );
}
