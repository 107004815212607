import { fetchHomePageDeals_API } from "../APIs/fetchDealsAPI";
import { DealsHomePageAPIResponse } from "../schema/CommonSchema";
import { DEALS_HOMEPAGE_KEY } from "../utils/Constants";
import {
  getDataFromLocalStorage,
  storeDataWithTimestamp,
} from "../utils/caching";
import { validateDealsResponse } from "../utils/utilHelper";

export const fetchHomePageDeals = async (
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setDeals: React.Dispatch<
    React.SetStateAction<DealsHomePageAPIResponse | null>
  >,
  setErrorMessage: React.Dispatch<React.SetStateAction<string>>
) => {
  try {
    let dealsHomePageResponse = getDataFromLocalStorage(DEALS_HOMEPAGE_KEY);
    if (!validateDealsResponse(dealsHomePageResponse)) {
      dealsHomePageResponse = await fetchHomePageDeals_API();
      if (
        !dealsHomePageResponse.error &&
        validateDealsResponse(dealsHomePageResponse.data)
      ) {
        dealsHomePageResponse = dealsHomePageResponse.data;
        storeDataWithTimestamp(DEALS_HOMEPAGE_KEY, dealsHomePageResponse);
      } else {
        dealsHomePageResponse = null;
        setErrorMessage(dealsHomePageResponse.error);
      }
    }
    setDeals(dealsHomePageResponse);
  } catch (error: any) {
    setErrorMessage(error.message);
    console.error("Error fetching data: " + error.message);
  } finally {
    setTimeout(() => {
      setIsLoading(false);
    }, 10);
  }
};
