// @mui
import { styled } from "@mui/material/styles";
import { Alert, Badge, Snackbar } from "@mui/material";
// component
import { useState } from "react";
import { useAppContext } from "../../store/AppContextProvider";
import { useNavigate } from "react-router-dom";
import AddAlertIcon from "@mui/icons-material/AddAlert";

// ----------------------------------------------------------------------

const StyledRoot = styled("div")(({ theme, showSpecialMessage }) => ({
  zIndex: 999,
  right: 0,
  display: "flex",
  cursor: "pointer",
  position: "fixed",
  alignItems: "center",
  top: showSpecialMessage ? theme.spacing(30) : theme.spacing(24),
  height: theme.spacing(5),
  paddingLeft: theme.spacing(1.5),
  paddingRight: theme.spacing(1.5),
  paddingTop: theme.spacing(0.5),
  boxShadow: theme.customShadows.z20,
  color: theme.palette.text.primary,
  backgroundColor: theme.palette.background.paper,
  borderTopLeftRadius: Number(theme.shape.borderRadius) * 2,
  borderBottomLeftRadius: Number(theme.shape.borderRadius) * 2,
  transition: theme.transitions.create("opacity"),
  "&:hover": { opacity: 0.72 },
}));

// ----------------------------------------------------------------------

export default function CartWidget({ showSpecialMessage }) {
  const { loggedIn, alertCount } = useAppContext();
  const [error, setError] = useState("");
  const vertical = "top";
  const horizontal = "center";
  const navigate = useNavigate();
  return (
    <>
      <Snackbar
        open={error && error.length > 0 ? true : false}
        autoHideDuration={2000}
        onClose={() => setError("")}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert severity="error" sx={{ width: "100%" }}>
          {error}
        </Alert>
      </Snackbar>
      <StyledRoot
        showSpecialMessage={showSpecialMessage}
        onClick={() =>
          loggedIn
            ? navigate("/alerts")
            : setError("Please login to view alerts")
        }
      >
        <Badge showZero badgeContent={alertCount} color="error">
          <AddAlertIcon />
        </Badge>
      </StyledRoot>
    </>
  );
}
