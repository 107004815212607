import { DomainMapping } from "../schema/DomainMapping";

export const WHITELISTED_URL_PREFIX = [
  "https://www.amazon.in",
  "https://www.flipkart.com",
  "https://amzn.",
  "https://dl.flipkart.",
];

export enum DOMAIN {
  AMAZON = "AMAZON",
  FLIPKART = "FLIPKART",
}
export const CONST_DOMAIN_MAPPING: DomainMapping = {
  AMZN: DOMAIN.AMAZON,
  AMAZON: DOMAIN.AMAZON,
  FLIPKART: DOMAIN.FLIPKART,
};

export const WHITELISTED_DOMAIN = [DOMAIN.AMAZON, DOMAIN.FLIPKART];

export const CONST_SEARCH_URL_MIN_VALID_LENGTH = Number(20);

export const ORCHESTRATION_API_ENDPOINT =
  "https://orchestration.trackmyprice.in";

export const ORCHESTRATION_API_ENDPOINT_DEV = "http://localhost:8080";

export const CONST_VALID_CACHE_TIME_IN_MINUTES = Number(300);

export const CONST_VALID_ALL_CACHE_TIME_IN_MINUTES = Number(720);

export const CONST_SNACK_BAR_VERTICAL_ALIGNMENT = "top";

export const CONST_SNACK_BAR_HORIZONTAL_ALIGNMENT = "center";

export const CONST_SEARCH_URL_QUERY_PARAM_KEY = "url";

export const CONST_SEARCH_VALUE_QUERY_PARAM_KEY = "s";

export const CONST_PRODUCT_DETAILS_CACHE_PREFIX = "SEARCH$";

export const CONST_SEARCH_PAGE_URL = "/search";

export const CONST_SEARCH_URL_PARAM_KEY = "?url=";
export const CONST_TAG = "?tag=";

export const CONST_SEARCH_VALUE_PARAM_KEY = "?s=";

export const CONST_PRODUCT_PRICE_HISTORY_SUFFIX = "_priceHistory";

export const NAV_WIDTH = 280;

export const DEALS_HOMEPAGE_KEY = "TMYP$DEALS";

export const DEFAULT_HOMEPAGE_VIEW = "carousel";

export const TINY_URL = "https://tmyp.in/";
