import axios from "axios";
import { ORCHESTRATION_API_ENDPOINT } from "../utils/Constants";
import moment from "moment";
import "moment-timezone";
import { generateAuthorizationToken } from "../utils/utilHelper";

moment.tz.setDefault("Asia/Kolkata");

export const userRequest = async (formData, type) => {
  try {
    const response = await axios.post(
      `${ORCHESTRATION_API_ENDPOINT}/user/${type}`,
      formData,
      {
        withCredentials: true,
        headers: {
          Authorization: generateAuthorizationToken(),
        },
      }
    );
    if (response.status === 200 && response.data) {
      return true;
    } else {
      return "Unable to complete the request";
    }
  } catch (error) {
    if (error.response) {
      return error.response.data;
    } else {
      return "Invalid Request";
    }
  }
};
