import axios from "axios";
import { ORCHESTRATION_API_ENDPOINT } from "../utils/Constants";
import moment from "moment";
import "moment-timezone";
import {
  generateAuthorizationToken,
  isPriceHistoryResponseValid,
} from "../utils/utilHelper";
import { PriceHistoryAPIResponse } from "../schema/CommonSchema";
import { PriceHistoryResponseSchema } from "../schema/PriceHistorySchema";
import { ErrorMessages } from "../helper/ErrorMessageEnum";

moment.tz.setDefault("Asia/Kolkata");

export const fetchPriceHistory_API = async (
  productID: string
): Promise<PriceHistoryAPIResponse> => {
  let searchProductHistoryResponse: PriceHistoryAPIResponse = {
    data: null,
    error: null,
  };
  let data: PriceHistoryResponseSchema | null = null,
    error: string | null = null;
  try {
    const response = await axios.get(
      `${ORCHESTRATION_API_ENDPOINT}/priceHistory/${encodeURIComponent(
        productID
      )}`,
      {
        headers: {
          Authorization: generateAuthorizationToken(),
        },
      }
    );
    if (response.status === 200) {
      let priceHistoryResponse: PriceHistoryResponseSchema = response.data;
      if (isPriceHistoryResponseValid(priceHistoryResponse)) {
        data = priceHistoryResponse;
      } else {
        throw new Error(ErrorMessages.SOMETHING_WENT_WRONG);
      }
    } else {
      throw new Error(ErrorMessages.SOMETHING_WENT_WRONG);
    }
  } catch (e: any) {
    if (!e) {
      error = ErrorMessages.SOMETHING_WENT_WRONG;
    } else {
      error = e.message;
    }
  }
  searchProductHistoryResponse.data = data;
  searchProductHistoryResponse.error = error;
  return searchProductHistoryResponse;
};
