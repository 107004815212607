import { Typography, Divider } from "@mui/material";
import { ProductList } from "../products";
import "./homepage.scss";

const CarouselViewHomePage = ({ deals }) => {
  return (
    <div className="carousel_homepage_view">
      {deals.lightningDeals && deals.lightningDeals.length > 0 && (
        <div style={{ marginBottom: "48px" }}>
          <Divider />
          <Typography variant="h5" sx={{ my: 2 }} className="lightningDeals">
            <div className="lightning-text-animation">
              Lightning Deals ({deals.lightningDeals.length})
            </div>
          </Typography>
          <ProductList products={deals.lightningDeals} />
        </div>
      )}
      {deals.hotDeals && deals.hotDeals.length > 0 && (
        <div style={{ marginBottom: "48px" }}>
          <Divider />
          <Typography variant="h5" sx={{ my: 2 }} className="hotDeals">
            <div className="fire-text-animation">
              Hot Deals ({deals.hotDeals.length})
            </div>
          </Typography>
          <ProductList products={deals.hotDeals} />
        </div>
      )}
      {deals.deals && deals.deals.length > 0 && (
        <div style={{ marginBottom: "48px" }}>
          <Divider />
          <Typography variant="h5" sx={{ my: 2 }} className="deals">
            Trending Deals ({deals.deals.length})
          </Typography>
          <ProductList products={deals.deals} />
        </div>
      )}
    </div>
  );
};

export default CarouselViewHomePage;
