import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import { Button, Slider } from "@mui/material";
import { styled } from "@mui/material/styles";

export const LightGreyBackground = styled("div")(() => ({
  backgroundColor: "#f5f5f5",
  width: "100%",
  height: "250px",
  textAlign: "center",
}));

export const CustomButton = styled(Button)({
  background: "black",
  color: "white",
  "&:hover": {
    background: "black",
  },
  width: "165px",
  padding: "10px",
});

export const AmazonText = styled("span")({
  color: "#FF9900",
});

export const StyledProductImg = styled("img")({
  width: "100%",
  maxWidth: "420px",
  margin: "auto",
  objectFit: "contain",
  border: "2px solid #E8EBEE",
  borderRadius: "10px",
});

export const PriceAlertButton = styled(Button)({
  background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
  color: "white",
  width: "165px",
  padding: "10px",
});

export const IconWrapper = styled("span")({
  marginRight: "8px",
  display: "inline-block",
  fontSize: "0px",
});

export const ShakeIcon = styled(NotificationsActiveIcon)(({ shaking }) => ({
  animation: shaking === "true" ? "shake 0.6s infinite" : "none",
}));

export const ButtonText = styled("span")(({ theme }) => ({
  fontSize: "12px",
}));

export const PrettoSlider = styled(Slider)({
  color: "#52af77",
  height: 8,
  "& .MuiSlider-track": {
    border: "none",
  },
  "& .MuiSlider-thumb": {
    height: 24,
    width: 24,
    backgroundColor: "#fff",
    border: "2px solid currentColor",
    "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
      boxShadow: "inherit",
    },
    "&:before": {
      display: "none",
    },
  },
  "& .MuiSlider-valueLabel": {
    lineHeight: 1.2,
    fontSize: 12,
    background: "unset",
    padding: 0,
    width: 32,
    height: 32,
    borderRadius: "50% 50% 50% 0",
    backgroundColor: "#52af77",
    transformOrigin: "bottom left",
    transform: "translate(50%, -100%) rotate(-45deg) scale(0)",
    "&:before": { display: "none" },
    "&.MuiSlider-valueLabelOpen": {
      transform: "translate(50%, -100%) rotate(-45deg) scale(1)",
    },
    "& > *": {
      transform: "rotate(45deg)",
    },
  },
});
