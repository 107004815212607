import { useState } from "react";
import { Menu, Button, MenuItem, Typography } from "@mui/material";
import Iconify from "../../customElements/Iconify";

// ----------------------------------------------------------------------

const SORT_BY_OPTIONS = [
  { value: "latest", label: "Latest" },
  { value: "priceDesc", label: "Alert Price: High-Low" },
  { value: "priceAsc", label: "Alert Price: Low-High" },
];

export default function ShopProductSort({ sortValue, setSortValue }) {
  const [profile, setProfile] = useState("Latest");
  const [open, setOpen] = useState(null);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = (e, value) => {
    if (e && e.target && e.target.value !== undefined) {
      setSortValue(value);
      setProfile(e.target.textContent);
    }
    setOpen(null);
  };

  return (
    <>
      <Button
        color="inherit"
        disableRipple
        onClick={handleOpen}
        endIcon={
          <Iconify
            icon={open ? "eva:chevron-up-fill" : "eva:chevron-down-fill"}
          />
        }
      >
        Sort By:&nbsp;
        <Typography
          component="span"
          variant="subtitle2"
          sx={{ color: "text.secondary" }}
        >
          {profile}
        </Typography>
      </Button>
      <Menu
        keepMounted
        anchorEl={open}
        open={Boolean(open)}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        defaultValue={sortValue}
      >
        {SORT_BY_OPTIONS.map((option) => (
          <MenuItem
            key={option.value}
            selected={option.value === sortValue}
            onClick={(e) => handleClose(e, option.value)}
            sx={{ typography: "body2" }}
          >
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
