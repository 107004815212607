import AlertCard from "./AlertCard";
import { Grid } from "@mui/material";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

export default function AlertList({ alerts, deleteAlert }) {
  return (
    <Grid container spacing={3} style={{ padding: "6px" }}>
      {alerts.map((alert) => (
        <Grid
          item
          key={`alert_${alert.url}`}
          xs={12}
          sm={6}
          md={4}
          style={{ textAlign: "center" }}
        >
          <AlertCard alert={alert} deleteAlert={deleteAlert} />
        </Grid>
      ))}
    </Grid>
  );
}
