import { useEffect } from "react";
import { Helmet } from "react-helmet-async";

import {
  Avatar,
  Container,
  Grid,
  Paper,
  Typography,
  List,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import ProfileOptionsCard from "../components/profile/ProfileOptionsCard";
import { useState } from "react";
import { getProfileComponent } from "../config/profile";
import { useAppContext } from "../store/AppContextProvider";
import account from "../config/account";

export default function ProfilePage() {
  const { loggedIn, userInfo } = useAppContext();

  const [profileOption, setProfileOption] = useState(
    localStorage.getItem("lastVisitedProfile")
      ? Number(localStorage.getItem("lastVisitedProfile"))
      : 1
  );

  const handleProfileOptionChange = (value) => {
    if (loggedIn || value <= 2) {
      setProfileOption(value);
    }
  };

  useEffect(() => {
    localStorage.setItem("lastVisitedProfile", profileOption);
  }, [profileOption]);

  const AlignContent = styled("div")(({ theme }) => ({
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    flexDirection: "column",
    maxWidth: 480,
    margin: "auto",
    padding: theme.spacing(3, 0),
  }));

  const AlignAvatars = styled("div")(({ theme }) => ({
    display: "flex",
    justifyContent: "flex-start",
    flexWrap: "wrap",
    margin: "auto",
    padding: theme.spacing(3, 0),
  }));

  const ColoredAvatar = styled(Avatar)(() => ({
    border: "4px solid #637381",
  }));

  return (
    <>
      <Helmet>
        <title> Dashboard | Profile </title>
      </Helmet>

      <Container maxWidth="xl">
        <Grid container spacing={3}>
          <Grid item xs={12} sm={10} md={6} lg={4}>
            <Paper elevation={3}>
              <AlignContent>
                <ColoredAvatar
                  src={
                    userInfo && userInfo.profilePictureUrl
                      ? `/assets/images/avatars/${userInfo.profilePictureUrl}.jpg`
                      : account.photoURL
                  }
                  alt="photoURL"
                  sx={{ width: 180, height: 180 }}
                />
                <Typography variant="h4" sx={{ px: 5, mt: 2, mb: 1 }}>
                  {userInfo && userInfo.firstName
                    ? userInfo.firstName + " " + userInfo.lastName
                    : "Guest User"}
                </Typography>
                <Typography variant="subtitle2">
                  {userInfo && userInfo.email
                    ? userInfo.email
                    : "No Email Found"}
                </Typography>
              </AlignContent>
              <AlignContent>
                <List sx={{ p: 1 }}>
                  <ProfileOptionsCard
                    loggedIn={loggedIn}
                    profileOption={profileOption}
                    setProfileOption={handleProfileOptionChange}
                  />
                </List>
              </AlignContent>
            </Paper>
          </Grid>
          <Grid item xs={12} md={6} lg={8}>
            <Paper elevation={3}>
              <AlignAvatars>{getProfileComponent(profileOption)}</AlignAvatars>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
