import { Box, Card, Typography, Stack, Tooltip } from "@mui/material";
import { styled } from "@mui/material/styles";
import { formatToIndianCurrency } from "../../utils/formatNumber";
import { LoadingButton } from "@mui/lab";
import {
  findSearchNavigationURL,
  handleBuyOnButtonClick,
} from "../../utils/utilHelper";
import ClearIcon from "@mui/icons-material/Clear";
import { useNavigate } from "react-router-dom";
import Label from "../../customElements/Label";
import { getTimeAgoFromDate } from "../../utils/formattedDate";
import TrendingDownIcon from "@mui/icons-material/TrendingDown";

// ----------------------------------------------------------------------

const StyledProductImg = styled("img")({
  top: 40,
  left: 40,
  width: "80%",
  height: "80%",
  objectFit: "cover",
  position: "absolute",
  cursor: "pointer",
});

export default function AlertCard({ alert, deleteAlert }) {
  const navigate = useNavigate();
  const {
    productId,
    title,
    alertPrice,
    alertDate,
    currentPrice,
    currentDate,
    image,
    domain,
    url,
  } = alert;

  return (
    <Card style={{ margin: "4px" }}>
      <Box sx={{ pt: "100%", position: "relative" }}>
        {domain && (
          <Label
            variant="filled"
            color="error"
            sx={{
              zIndex: 9,
              top: 16,
              left: 16,
              position: "absolute",
              textTransform: "uppercase",
            }}
          >
            {domain}
          </Label>
        )}
        <Tooltip title="Delete">
          <ClearIcon
            sx={{
              cursor: "pointer",
              zIndex: 9,
              top: 16,
              right: 16,
              position: "absolute",
            }}
            onClick={() => deleteAlert(url, productId)}
          />
        </Tooltip>
        <StyledProductImg
          alt={title}
          src={image}
          onClick={() => handleBuyOnButtonClick(url)}
        />
      </Box>
      {currentPrice <= alertPrice && (
        <Label
          color="info"
          endIcon={<TrendingDownIcon />}
          variant="soft"
          sx={{
            zIndex: 9,
            width: "100%",
            height: "32px",
            textTransform: "uppercase",
          }}
        >
          Price Dropped
        </Label>
      )}
      <Stack spacing={2} sx={{ p: 3 }}>
        <div
          style={{
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          <Typography variant="subtitle2" noWrap>
            {title}
          </Typography>
        </div>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack
            direction="column"
            alignItems="left"
            justifyContent="space-between"
          >
            <Typography component="span" variant="subtitle2">
              Alert Price
            </Typography>

            <Typography
              component="span"
              sx={{ color: "green" }}
              style={{ fontWeight: "bolder" }}
            >
              {alertPrice && formatToIndianCurrency(alertPrice)}
            </Typography>
            <Typography
              component="span"
              variant="body1"
              sx={{
                fontSize: "12px",
                color: "text.disabled",
              }}
            >
              {alertDate}
            </Typography>
          </Stack>
          <Stack
            direction="column"
            alignItems="left"
            justifyContent="space-between"
          >
            <Typography component="span" variant="subtitle2">
              Current Price
            </Typography>
            <Typography component="span" style={{ fontWeight: "bolder" }}>
              {currentPrice && formatToIndianCurrency(currentPrice)}
            </Typography>
            <Typography
              component="span"
              variant="body1"
              sx={{
                fontSize: "12px",
                color: "text.disabled",
              }}
            >
              {currentDate && getTimeAgoFromDate(currentDate)}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
      <LoadingButton
        variant="text"
        sx={{ width: "100%" }}
        onClick={() => {
          navigate(findSearchNavigationURL(url));
        }}
      >
        View Details/Update Alert Price
      </LoadingButton>
    </Card>
  );
}
