import { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { styled } from "@mui/material/styles";
import { Link, Container, Typography } from "@mui/material";
import useResponsive from "../hooks/useResponsive";
import { useState } from "react";
import { useAppContext } from "../store/AppContextProvider";
import { useLocation, useNavigate } from "react-router-dom";
import Logo from "../customElements/Logo";
import SignUpForm from "../components/auth/SignUpForm";
import LoginForm from "../components/auth/LoginForm";

// ----------------------------------------------------------------------

const StyledRoot = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

const StyledSection = styled("div")(({ theme }) => ({
  width: "100%",
  maxWidth: 480,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  boxShadow: theme.customShadows.card,
  backgroundColor: theme.palette.background.default,
}));

const StyledContent = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  minHeight: "100vh",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function LoginPage() {
  const [type, setType] = useState("login");
  const { loggedIn } = useAppContext();
  const [forgetPassword, setForgetPassword] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const mdUp = useResponsive("up", "md");

  const handleType = () => {
    if (type === "login") {
      if (!forgetPassword) {
        setType("signup");
      } else {
        navigate("/login");
      }
    } else if (type === "signup") {
      setType("login");
    }
  };

  useEffect(() => {
    if (loggedIn === true || loggedIn === "true") {
      const lastURL = localStorage.getItem("lastUrl");
      if (lastURL) {
        navigate(lastURL, { replace: true });
      } else {
        navigate("/", { replace: true });
      }
    }
    if (location.hash === "#forgetPassword") {
      setForgetPassword(true);
    } else {
      setForgetPassword(false);
    }
  }, [loggedIn, navigate, location]);

  return (
    <>
      <Helmet>
        <title> {type === "login" ? "Login" : "SignUp"} | TrackMYPrice </title>
      </Helmet>

      <StyledRoot>
        <Logo
          sx={{
            position: "fixed",
            top: { xs: 16, sm: 24, md: 40 },
            left: { xs: 16, sm: 24, md: 40 },
          }}
        />

        {mdUp && (
          <StyledSection>
            <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
              {type === "login" ? "Hi, Welcome Back" : "Hello, Join Us"}
            </Typography>
            <img
              src={`/assets/illustrations/${
                type === "login"
                  ? "illustration_login.png"
                  : "illustration_signup.svg"
              }`}
              alt="login"
            />
          </StyledSection>
        )}

        <Container maxWidth="sm">
          <StyledContent>
            <Typography variant="h4" gutterBottom>
              {type === "login" ? "Sign in" : "Sign up"} to TrackMYPrice
            </Typography>

            <Typography variant="body2" sx={{ mb: 5 }}>
              {type === "login" && !forgetPassword
                ? "Don’t have an account? "
                : "Already have an account? "}
              <Link
                variant="subtitle2"
                sx={{ cursor: "pointer" }}
                onClick={handleType}
              >
                {type === "login" && !forgetPassword
                  ? "Create account"
                  : "login"}
              </Link>
            </Typography>

            {type === "login" ? <LoginForm /> : <SignUpForm />}
          </StyledContent>
        </Container>
      </StyledRoot>
    </>
  );
}
