/* eslint-disable jsx-a11y/iframe-has-title */
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import TelegramIcon from "@mui/icons-material/Telegram";
import GitHubIcon from "@mui/icons-material/GitHub";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { Box, Divider } from "@mui/material";
import Logo from "../../customElements/Logo";
import { useAppContext } from "../../store/AppContextProvider";
import { NAV_WIDTH } from "../../utils/Constants";

export default function FooterComponent() {
  const { isMobile } = useAppContext();
  return (
    <div style={{ marginLeft: !isMobile && `${NAV_WIDTH}px` }}>
      <Divider />
      <Box
        component="footer"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === "light"
              ? theme.palette.grey[200]
              : theme.palette.grey[800],
          px: 0.5,
          py: 2,
        }}
      >
        <Container maxWidth="lg">
          <Grid container spacing={1}>
            <Grid item xs={12} sm={10}>
              {!isMobile ? (
                <div style={{ display: "flex" }}>
                  <Logo sx={{ height: isMobile ? 30 : "inherit" }} />
                  <iframe
                    src="https://monitor.trackmyprice.in/badge?theme=light"
                    width="250"
                    height="30"
                    style={{ marginLeft: "20px" }}
                    frameborder="0"
                    scrolling="no"
                  ></iframe>
                </div>
              ) : (
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Logo sx={{ height: isMobile ? 30 : "inherit" }} />
                  <Link
                    href="https://t.me/trackmyprice"
                    target="_blank"
                    rel="noopener"
                    color="inherit"
                  >
                    <TelegramIcon sx={{ color: "#0088cc", ml: 2 }} />
                  </Link>
                  <Link
                    href="https://github.com/Maayur28"
                    target="_blank"
                    rel="noopener"
                    color="inherit"
                  >
                    <GitHubIcon sx={{ color: "#333", ml: 2 }} />
                  </Link>

                  <Link
                    href="https://www.linkedin.com/in/mayur28/"
                    target="_blank"
                    rel="noopener"
                    color="inherit"
                  >
                    <LinkedInIcon sx={{ color: "#0e76a8", ml: 2 }} />
                  </Link>
                </div>
              )}
              {isMobile && (
                <iframe
                  src="https://monitor.trackmyprice.in/badge?theme=light"
                  width="250"
                  height="30"
                  style={{ marginTop: "10px", marginLeft: "-5px" }}
                  frameborder="0"
                  scrolling="no"
                ></iframe>
              )}
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{
                  mt: 1,
                  fontSize: isMobile ? "11px" : "inherit",
                }}
              >
                TrackMYPrice provides real-time price tracking, historical data,
                and insightful analysis. With features like price drop alerts
                and email notifications, we ensure you never miss a deal.
              </Typography>
              {!isMobile && (
                <>
                  <Link
                    href="https://t.me/trackmyprice"
                    target="_blank"
                    rel="noopener"
                    color="inherit"
                  >
                    <TelegramIcon sx={{ color: "#0088cc", mt: 1 }} />
                  </Link>
                  <Link
                    href="https://github.com/Maayur28"
                    target="_blank"
                    rel="noopener"
                    color="inherit"
                  >
                    <GitHubIcon sx={{ color: "#333", mt: 1, ml: 2 }} />
                  </Link>

                  <Link
                    href="https://www.linkedin.com/in/mayur28/"
                    target="_blank"
                    rel="noopener"
                    color="inherit"
                  >
                    <LinkedInIcon sx={{ color: "#0e76a8", mt: 1, ml: 2 }} />
                  </Link>
                </>
              )}
            </Grid>
            <Grid item xs={12} sm={2}>
              <Typography
                variant="h6"
                color="text.primary"
                sx={{ fontSize: isMobile ? "14px" : "inherit" }}
              >
                Support
              </Typography>
              {!isMobile ? (
                <div>
                  <Link color="inherit" href="/about-us" underline="hover">
                    <Typography variant="body2" color="text.secondary">
                      About Us
                    </Typography>
                  </Link>
                  <Link color="inherit" href="/contact-us" underline="hover">
                    <Typography variant="body2" color="text.secondary">
                      Contact Us
                    </Typography>
                  </Link>
                  <Link
                    color="inherit"
                    href="/terms-and-conditions"
                    underline="hover"
                  >
                    <Typography variant="body2" color="text.secondary">
                      Terms and Conditions
                    </Typography>
                  </Link>
                  <Link
                    color="inherit"
                    href="/privacy-policy"
                    underline="hover"
                  >
                    <Typography variant="body2" color="text.secondary">
                      Privacy Policy
                    </Typography>
                  </Link>
                  <Link color="inherit" href="/disclaimer" underline="hover">
                    <Typography variant="body2" color="text.secondary">
                      Disclaimer
                    </Typography>
                  </Link>
                </div>
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Link color="inherit" href="/about-us" underline="hover">
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{ fontSize: isMobile ? "12px" : "inherit" }}
                    >
                      About Us
                    </Typography>
                  </Link>
                  <Link color="inherit" href="/contact-us" underline="hover">
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{ fontSize: isMobile ? "12px" : "inherit" }}
                    >
                      &nbsp;|&nbsp;Contact Us
                    </Typography>
                  </Link>
                  <Link
                    color="inherit"
                    href="/terms-and-conditions"
                    underline="hover"
                  >
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{ fontSize: isMobile ? "12px" : "inherit" }}
                    >
                      &nbsp;|&nbsp;TnC
                    </Typography>
                  </Link>
                  <Link
                    color="inherit"
                    href="/privacy-policy"
                    underline="hover"
                  >
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{ fontSize: isMobile ? "12px" : "inherit" }}
                    >
                      &nbsp;|&nbsp;Privacy Policy
                    </Typography>
                  </Link>
                  <Link color="inherit" href="/disclaimer" underline="hover">
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{ fontSize: isMobile ? "12px" : "inherit" }}
                    >
                      &nbsp;|&nbsp;Disclaimer
                    </Typography>
                  </Link>
                </Box>
              )}
            </Grid>
          </Grid>
          <Divider sx={{ my: 2 }} />
          <Box mt={2}>
            <Typography
              variant="body2"
              color="text.primary"
              align="left"
              sx={{ fontSize: isMobile ? "12px" : "inherit" }}
            >
              {"Copyright © "}
              {new Date().getFullYear()}
              <Link color="inherit" href="/" underline="hover">
                {" "}
                TrackMYPrice.
              </Link>{" "}
              | All Rights Reserved
            </Typography>
          </Box>
          <Box mt={1}>
            <Typography
              variant="body2"
              color="text.secondary"
              align="left"
              sx={{ fontSize: isMobile ? "11px" : "14px" }}
            >
              By accessing and utilizing this website, you are agreeing to abide
              by the{" "}
              <Link
                color="inherit"
                href="/terms-and-conditions"
                underline="hover"
              >
                Terms and Conditions
              </Link>{" "}
              and{" "}
              <Link color="inherit" href="/privacy-policy" underline="hover">
                Privacy Policy
              </Link>{" "}
              of TrackMYPrice. As the website is currently in its developmental
              stage, users are advised to exercise caution while navigating and
              interacting with its features.
            </Typography>
          </Box>
        </Container>
      </Box>
    </div>
  );
}
