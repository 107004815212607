import { useEffect } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import DashboardLayout from "./layouts/DashboardLayout";
import DashboardHomePage from "./pages/DashboardHomePage";
import ProfilePage from "./pages/ProfilePage";
import Page404 from "./pages/Page404";
import LoginPage from "./pages/LoginPage";
import SearchPage from "./pages/SearchPage";
import ValidateSignUp from "./pages/ValidateSignUp";
import ResetPassword from "./pages/ResetPassword";
import AlertPage from "./pages/AlertPage";
import ContactUs from "./layouts/footer/ContactUs";
import DisclaimerPage from "./layouts/footer/DisclaimerPage";
import AboutUs from "./layouts/footer/AboutUs";
import PrivacyPolicy from "./layouts/footer/PrivacyPolicy";
import TermsAndConditions from "./layouts/footer/Terms and Conditions";
import DealsPage from "./pages/DealsPage";

export default function Router() {
  const location = useLocation();

  useEffect(() => {
    localStorage.setItem("lastVisitedRoute", location.pathname);
  }, [location.pathname]);

  return (
    <Routes>
      <Route path="/dashboard/home" element={<Navigate to="/" replace />} />
      <Route path="/dashboard" element={<Navigate to="/" replace />} />
      <Route path="/home" element={<Navigate to="/" replace />} />
      <Route path="/" element={<DashboardLayout />}>
      <Route path="/" element={<DashboardHomePage />} />
        <Route path="/deals" element={<DealsPage />}/>
        <Route path="profile" element={<ProfilePage />} />
        <Route path="alerts" element={<AlertPage />} />
        <Route path="search" element={<SearchPage />} />
      </Route>

      <Route path="/validate" element={<ValidateSignUp />} />
      <Route path="/resetpassword" element={<ResetPassword />} />
      <Route path="/login" element={<LoginPage />} />
      <Route path="/about-us" element={<AboutUs />} />
      <Route path="/contact-us" element={<ContactUs />} />
      <Route path="/disclaimer" element={<DisclaimerPage />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
      <Route path="/404" element={<Page404 />} />
      <Route path="*" element={<Navigate to="/404" replace />} />
    </Routes>
  );
}
