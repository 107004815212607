import axios from "axios";
import { ORCHESTRATION_API_ENDPOINT } from "../utils/Constants";
import moment from "moment";
import "moment-timezone";
import {
  generateAuthorizationToken,
  isSearchProductListValid,
} from "../utils/utilHelper";
import { ErrorMessages } from "../helper/ErrorMessageEnum";
import { SearchProductListFromValueAPIResponse } from "../schema/CommonSchema";
import { SearchProductListResponseSchema } from "../schema/SearchSchema";

moment.tz.setDefault("Asia/Kolkata");

export const fetchDetailsFromSearchValue_API = async (
  search_value: string
): Promise<SearchProductListFromValueAPIResponse> => {
  let searchProductListResponse: SearchProductListFromValueAPIResponse = {
    data: null,
    error: null,
  };
  let data: SearchProductListResponseSchema[] | null = null,
    error: string | null = null;
  try {
    const response = await axios.get(
      `${ORCHESTRATION_API_ENDPOINT}/search?s=${search_value}`,
      {
        headers: {
          Authorization: generateAuthorizationToken(),
        },
      }
    );
    if (response.status === 200 && response.data) {
      let searchResponse: SearchProductListResponseSchema[] =
        response.data.searchResponse;
      if (isSearchProductListValid(searchResponse)) {
        data = searchResponse;
      } else {
        throw new Error(ErrorMessages.SOMETHING_WENT_WRONG);
      }
    } else {
      throw new Error(ErrorMessages.SOMETHING_WENT_WRONG);
    }
  } catch (e: any) {
    if (!e) {
      error = ErrorMessages.SOMETHING_WENT_WRONG;
    } else {
      error = e.message;
    }
  }
  searchProductListResponse.data = data;
  searchProductListResponse.error = error;
  return searchProductListResponse;
};
