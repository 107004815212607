import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Input,
  Slide,
  Button,
  IconButton,
  InputAdornment,
  ClickAwayListener,
  Typography,
  Backdrop,
} from "@mui/material";
import { FileCopyOutlined, Search, Clear } from "@mui/icons-material";
import { useSpring, animated } from "react-spring";
import { StyledSearchbar } from "../../customElements/StylesSearchbar";
import "./header.scss";
import SnackBarError from "../../customElements/SnackBar/SnackBarError";
import { useAppContext } from "../../store/AppContextProvider";
import {
  getCleanSearchURL,
  isValidSearchURL,
  isValidSearchValue,
} from "../../utils/utilHelper";
import {
  CONST_SEARCH_PAGE_URL,
  CONST_SEARCH_URL_PARAM_KEY,
  CONST_SEARCH_VALUE_PARAM_KEY,
} from "../../utils/Constants";
import Iconify from "../../customElements/Iconify";
import axios from "axios";

export default function Searchbar() {
  const navigate = useNavigate();
  const location = useLocation();
  const { searchBarOpen, setSearchBarOpen, isSearching } = useAppContext();
  const [copied, setCopied] = useState(false);
  const [isValidSearchText, setIsValidSearchText] = useState(true);
  const [searchText, setSearchText] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);
  const [fact, setFact] = useState(null);

  const handleClose = () => {
    if (!isSearching) {
      setSearchBarOpen(false);
    }
  };

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await axios.get(
          "https://api.api-ninjas.com/v1/facts",
          {
            headers: {
              "X-Api-Key": process.env.REACT_APP_FACT_API_KEY,
              "Content-Type": "application/json",
            },
          }
        );
        setFact(response.data[0].fact);
      } catch (error) {
        setFact(
          "After the Eiffel Tower was built, one person was killed during the installation of the lifts. No one was killed during the actual construction of the tower"
        );
      }
    }
    if (isSearching) {
      fetchData();
    } else {
      setFact(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSearching]);

  useEffect(() => {
    if (location.pathname === "/") {
      setSearchBarOpen(true);
      const timerId = setTimeout(() => {
        setSearchBarOpen(false);
      }, 600);
      return () => clearTimeout(timerId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePaste = () => {
    navigator.clipboard.readText().then((text) => {
      setSearchText(text);
      setCopied(true);
      setTimeout(() => setCopied(false));
    });
  };

  const handleSearch = async () => {
    let tempSearchText = searchText;
    if (tempSearchText.length > 0) {
      tempSearchText = tempSearchText.trim();
    }
    const urlPattern = /(https?:\/\/[^\s]+)/g;
    const urls = tempSearchText.match(urlPattern);
    if (urls) {
      tempSearchText = urls[0];
      if (isValidSearchURL(tempSearchText)) {
        tempSearchText = getCleanSearchURL(tempSearchText);
        navigate(
          CONST_SEARCH_PAGE_URL + CONST_SEARCH_URL_PARAM_KEY + tempSearchText
        );
      } else {
        setIsValidSearchText(false);
      }
    } else if (isValidSearchValue(tempSearchText)) {
      navigate(
        CONST_SEARCH_PAGE_URL +
          CONST_SEARCH_VALUE_PARAM_KEY +
          tempSearchText.replace(/ +/g, "+")
      );
    } else {
      setIsValidSearchText(false);
    }
  };

  const springProps = useSpring({
    transform: copied ? "scale(0.7)" : "scale(1)",
    config: {
      tension: copied ? 450 : 250,
      friction: copied ? 12 : 8,
    },
  });

  const handleMouseDown = () => {
    setCopied(true);
  };

  const handleMouseUp = () => {
    setCopied(false);
  };

  return (
    <>
      {errorMessage ? (
        <SnackBarError
          errorMessage={errorMessage}
          setErrorMessage={setErrorMessage}
        />
      ) : null}
      <ClickAwayListener onClickAway={handleClose}>
        <div className="search_bar">
          {!searchBarOpen && (
            <IconButton
              onClick={() => setSearchBarOpen(!searchBarOpen)}
              aria-label="search"
            >
              <Iconify icon="eva:search-fill" />
            </IconButton>
          )}

          <Slide direction="down" in={searchBarOpen}>
            <StyledSearchbar>
              <Input
                autoFocus
                fullWidth
                disableUnderline
                value={searchText}
                style={{
                  borderBottom: !isValidSearchText && "2px solid red",
                }}
                onChange={(e) => {
                  setIsValidSearchText(true);
                  setSearchText(e.target.value);
                }}
                onKeyDown={(event) => event.key === "Enter" && handleSearch()}
                placeholder="Search for products by name or paste their URLs from our supported stores."
                startAdornment={
                  <InputAdornment position="start">
                    <Iconify
                      aria-label="search"
                      onClick={handleClose}
                      icon="eva:search-fill"
                      sx={{
                        color: "text.disabled",
                        width: 20,
                        height: 20,
                        cursor: "pointer",
                      }}
                    />
                  </InputAdornment>
                }
                endAdornment={
                  searchText && (
                    <InputAdornment position="end">
                      <IconButton
                        edge="end"
                        onClick={() => {
                          setIsValidSearchText(true);
                          setSearchText("");
                        }}
                      >
                        <Clear />
                      </IconButton>
                    </InputAdornment>
                  )
                }
                sx={{ mr: 1, fontWeight: "fontWeightBold" }}
              />
              <div
                style={{
                  display: "flex",
                  alignItems: "flex-end",
                  alignContent: "flex-end",
                  height: "35px",
                  position: "absolute",
                  bottom: "0",
                  paddingLeft: "5px",
                }}
              >
                <Typography
                  variant="subtitle2"
                  sx={{ color: "#97A4B0", cursor: "default" }}
                >
                  Supported Stores:
                </Typography>
                <a
                  href="https://www.amazon.in"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="/assets/store/amazon.svg"
                    alt="Amazon"
                    width={48}
                    height={48}
                    style={{
                      marginBottom: "-16px",
                      marginLeft: "16px",
                    }}
                  />
                </a>
                <a
                  href="https://www.flipkart.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="/assets/store/flipkart.svg"
                    alt="Flipkart"
                    width={56}
                    height={56}
                    style={{
                      marginBottom: "-16px",
                      marginLeft: "24px",
                    }}
                  />
                </a>
              </div>
              <IconButton
                onMouseDown={handleMouseDown}
                onMouseUp={handleMouseUp}
                onClick={handlePaste}
                sx={{
                  mx: 1,
                  color: copied ? "#1e88e5" : "#757575",
                }}
              >
                <animated.span style={springProps}>
                  <FileCopyOutlined />
                </animated.span>
              </IconButton>
              <Button
                variant="contained"
                onClick={handleSearch}
                sx={{ display: "flex", alignItems: "center", px: 3 }}
              >
                <Search sx={{ display: "flex", alignItems: "center" }} />
                Search
              </Button>
              <Backdrop
                style={{ backgroundColor: "#F9FAFB" }}
                sx={{
                  color: "#fff",
                  zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={isSearching}
              >
                <span>
                  <Typography
                    variant="h5"
                    sx={{
                      color: "black",
                      margin: "0 10px",
                      cursor: "default",
                      fontSize: "16px",
                    }}
                  >
                    {fact != null && "Did you know?"}
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{
                      color: "#97A4B0",
                      margin: "0 10px",
                      cursor: "default",
                      fontSize: "13px",
                    }}
                  >
                    {fact != null ? fact : "LOADING..."}
                  </Typography>
                </span>
                {/* <Button
                  style={{
                    position: "absolute",
                    right: "1%",
                  }}
                  variant="contained"
                  color="error"
                  startIcon={<Stop />}
                  onClick={() => {
                    setIsSearching(false);
                  }}
                >
                  Stop
                </Button> */}
              </Backdrop>
            </StyledSearchbar>
          </Slide>
        </div>
      </ClickAwayListener>
    </>
  );
}
