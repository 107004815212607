import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  TextField,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Typography,
  Stack,
  Box,
} from "@mui/material";
import { Lock, Visibility, VisibilityOff } from "@mui/icons-material";
import { userRequest } from "../APIs/userRequestAPI";
import Logo from "../customElements/Logo";

const ResetPassword = () => {
  const location = useLocation();
  const [error, setError] = useState("");
  const [isChanged, setisChanged] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    password: "",
    confirmPassword: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let params = new URLSearchParams(location.search);
    if (params && params.get("verify")) {
      let token = params.get("verify");
      if (token && token.length >= 10) {
        formData.token = token;
        const response = await userRequest(formData, "resetPassword");
        if (response === true || response === false) {
          setisChanged(response);
        } else {
          setError(response);
        }
      } else {
        setisChanged(false);
      }
    } else {
      setisChanged(false);
    }
  };

  const handleToggleNewPassword = () => {
    setShowPassword((prevShow) => !prevShow);
  };

  const handleToggleConfirmPassword = () => {
    setShowConfirmPassword((prevShow) => !prevShow);
  };

  const isConfirmPasswordValid = formData.password === formData.confirmPassword;

  const isStrongPassword = (password) => {
    const strongPasswordRegex =
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
    return strongPasswordRegex.test(password);
  };

  return (
    <>
      <Box sx={{ px: 3, py: 4, display: "inline-flex" }}>
        <Logo />
      </Box>
      <div
        style={{
          width: "100%",
          height: "80%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {isChanged === false ? (
          <Stack
            component="span"
            direction="column"
            alignItems="center"
            justifyContent="center"
          >
            <img
              src="/assets/signup/signup_failed.svg"
              alt="success"
              width={200}
              height={200}
            />
            <Typography variant="h3" sx={{ mb: 2 }}>
              Failed to change
            </Typography>
            <Button
              variant="outlined"
              onClick={() => navigate("/forgetpassword", { replace: true })}
              color="error"
            >
              Try again
            </Button>
          </Stack>
        ) : (
          <>
            {isChanged === true ? (
              <Stack
                component="span"
                direction="column"
                alignItems="center"
                justifyContent="center"
              >
                <img
                  src="/assets/signup/signup_success.svg"
                  alt="success"
                  width={200}
                  height={200}
                />
                <Typography variant="h3">Thank you</Typography>
                <Typography variant="subtitle1" sx={{ mb: 4 }}>
                  Password Changed
                </Typography>
                <Button onClick={() => navigate("/login", { replace: true })}>
                  Login
                </Button>
              </Stack>
            ) : (
              <>
                {error.trim().length === 0 ? (
                  <div style={{ maxWidth: "320px" }}>
                    <form onSubmit={handleSubmit} style={{ padding: 24 }}>
                      <Typography variant="h5" sx={{ px: 0, mb: 4 }}>
                        Change Password
                      </Typography>
                      <Grid item xs={8}>
                        <TextField
                          type={showPassword ? "text" : "password"}
                          name="password"
                          label="Password"
                          value={formData.password}
                          onChange={handleChange}
                          fullWidth
                          required
                          error={
                            formData.password !== ""
                              ? !isStrongPassword(formData.password)
                              : false
                          }
                          helperText={
                            formData.password !== "" &&
                            !isStrongPassword(formData.password)
                              ? "Password must be at least 8 characters long and include at least one alphabet, one number, and one special character."
                              : ""
                          }
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton onClick={handleToggleNewPassword}>
                                  {showPassword ? (
                                    <VisibilityOff />
                                  ) : (
                                    <Visibility />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                      <Grid item xs={8} sx={{ pt: 2 }}>
                        <TextField
                          type={showConfirmPassword ? "text" : "password"}
                          name="confirmPassword"
                          label="Confirm Password"
                          value={formData.confirmPassword}
                          onChange={handleChange}
                          fullWidth
                          required
                          error={
                            formData.confirmPassword !== ""
                              ? !isConfirmPasswordValid
                              : false
                          }
                          helperText={
                            formData.confirmPassword !== "" &&
                            !isConfirmPasswordValid &&
                            "Passwords don't match"
                          }
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  onClick={handleToggleConfirmPassword}
                                >
                                  {showConfirmPassword ? (
                                    <VisibilityOff />
                                  ) : (
                                    <Visibility />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                      <Grid container spacing={2} sx={{ pt: 4 }}>
                        <Grid item xs={8}>
                          <Button
                            type="submit"
                            variant="contained"
                            color="secondary"
                            fullWidth
                            sx={{ px: 1.25 }}
                            startIcon={<Lock />}
                          >
                            Change Password
                          </Button>
                        </Grid>
                      </Grid>
                    </form>
                  </div>
                ) : (
                  <Stack
                    component="span"
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <img
                      src="/assets/signup/signup_error.svg"
                      alt="info"
                      width={200}
                      height={150}
                    />
                    <Typography variant="subtitle1" sx={{ my: 4 }}>
                      {error}
                    </Typography>
                    <Button
                      onClick={() =>
                        navigate("/login#forgetPassword", { replace: true })
                      }
                    >
                      ForgetPasword
                    </Button>
                  </Stack>
                )}
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default ResetPassword;
