import { useState } from "react";
// @mui
import {
  TextField,
  FormControl,
  FormLabel,
  Grid,
  Select,
  MenuItem,
  ListItemIcon,
  InputAdornment,
  Box,
  Autocomplete,
  IconButton,
  Stack,
  Button,
  Alert,
  Typography,
} from "@mui/material";
import {
  Male,
  Female,
  Person,
  Email,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { userRequest } from "../../APIs/userRequestAPI";
import { isStrongPassword } from "../../utils/utilHelper";
import { countries } from "../../utils/countries";

// ----------------------------------------------------------------------

export default function SignUpForm() {
  const [emailSent, setEmailSent] = useState(false);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [country, setCountry] = useState("India");

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
    dateOfBirth: "",
    gender: "Select",
  });

  const handleChange = (e) => {
    let { name, value } = e.target;
    if (value) {
      value = value.trim();
    }
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const minAcceptedDate = new Date();
  minAcceptedDate.setFullYear(minAcceptedDate.getFullYear() - 12);

  const isConfirmPasswordValid = formData.password === formData.confirmPassword;

  const handleToggleNewPassword = () => {
    setShowPassword((prevShow) => !prevShow);
  };

  const handleToggleConfirmPassword = () => {
    setShowConfirmPassword((prevShow) => !prevShow);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      !emailSent &&
      isValidName(formData.firstName) &&
      isValidName(formData.lastName) &&
      isValidEmail(formData.email) &&
      isStrongPassword(formData.password) &&
      formData.password === formData.confirmPassword &&
      isSingUpDateValid(formData.dateOfBirth)
    ) {
      setError("");
      setIsLoading(true);
      formData.profilePictureUrl =
        localStorage.getItem("profilePictureUrl") || "avatar_default";
      formData.profileCoverUrl =
        localStorage.getItem("profileCoverUrl") || "cover_default";
      formData.country = country;
      const response = await userRequest(formData, "signup");
      if (response === true) {
        setEmailSent(response);
      } else {
        setError(response);
      }
    } else {
      setError("Invalid Request");
    }
    setIsLoading(false);
  };

  const isValidName = (name) => {
    const nameRegex = /^[A-Za-z]{2,}$/;
    return nameRegex.test(name);
  };

  const isValidEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  };

  const isSingUpDateValid = (selectedDate) => {
    const currentDate = new Date();
    const selectedDateObj = new Date(selectedDate);

    const twelveYearsAgo = new Date();
    twelveYearsAgo.setFullYear(currentDate.getFullYear() - 12);
    twelveYearsAgo.setHours(0, 0, 0, 0);

    return selectedDateObj.getTime() <= twelveYearsAgo.getTime();
  };

  const isDateValid = (selectedDate) => {
    const selectedDateObj = new Date(selectedDate);
    return selectedDateObj <= minAcceptedDate;
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              name="firstName"
              label="First Name"
              value={formData.firstName}
              onChange={handleChange}
              required
              disabled={emailSent}
              error={
                formData.firstName !== ""
                  ? !isValidName(formData.firstName)
                  : false
              }
              helperText={
                formData.firstName !== "" && !isValidName(formData.firstName)
                  ? "Name must contain atleast 2 character"
                  : ""
              }
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              name="lastName"
              label="Last Name"
              value={formData.lastName}
              onChange={handleChange}
              required
              disabled={emailSent}
              error={
                formData.lastName !== ""
                  ? !isValidName(formData.lastName)
                  : false
              }
              helperText={
                formData.lastName !== "" && !isValidName(formData.lastName)
                  ? "Name must contain atleast 2 character"
                  : ""
              }
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="email"
              label="Email address"
              value={formData.email}
              onChange={handleChange}
              fullWidth
              required
              disabled={emailSent}
              error={
                formData.email !== "" ? !isValidEmail(formData.email) : false
              }
              helperText={
                formData.email !== "" && !isValidEmail(formData.email)
                  ? "Invalid email"
                  : "We won't share your email with anyone."
              }
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Email />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              type={showPassword ? "text" : "password"}
              name="password"
              label="Password"
              value={formData.password}
              onChange={handleChange}
              fullWidth
              required
              disabled={emailSent}
              error={
                formData.password !== ""
                  ? !isStrongPassword(formData.password)
                  : false
              }
              helperText={
                formData.password !== "" && !isStrongPassword(formData.password)
                  ? "Password must be at least 8 characters long and include at least one alphabet, one number, and one special character."
                  : ""
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleToggleNewPassword}>
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              type={showConfirmPassword ? "text" : "password"}
              name="confirmPassword"
              label="Confirm Password"
              value={formData.confirmPassword}
              onChange={handleChange}
              fullWidth
              disabled={emailSent}
              required
              error={
                formData.confirmPassword !== ""
                  ? !isConfirmPasswordValid
                  : false
              }
              helperText={
                formData.confirmPassword !== "" &&
                !isConfirmPasswordValid &&
                "Passwords don't match"
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleToggleConfirmPassword}>
                      {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} sx={{ my: 3 }}>
            <TextField
              name="dateOfBirth"
              label="Birthday"
              type="date"
              value={formData.dateOfBirth}
              onChange={handleChange}
              fullWidth
              required
              disabled={emailSent}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                max: minAcceptedDate.toISOString().split("T")[0],
              }}
              error={
                formData.dateOfBirth
                  ? !isDateValid(formData.dateOfBirth)
                  : false
              }
              helperText={
                formData.dateOfBirth && !isDateValid(formData.dateOfBirth)
                  ? "Age must be greater than 12 years."
                  : ""
              }
            />
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <FormLabel component="legend">Gender (optional)</FormLabel>
              <Select
                name="gender"
                disabled={emailSent}
                value={formData.gender}
                onChange={handleChange}
                variant="standard"
                label="Gender"
              >
                <MenuItem value="Select" disabled>
                  <ListItemIcon>
                    <Person />
                  </ListItemIcon>
                  Select an option
                </MenuItem>
                <MenuItem value="Male">
                  <ListItemIcon>
                    <Male />
                  </ListItemIcon>
                  Male
                </MenuItem>
                <MenuItem value="Female">
                  <ListItemIcon>
                    <Female />
                  </ListItemIcon>
                  Female
                </MenuItem>
                <MenuItem value="Other">
                  <ListItemIcon>
                    <Person />
                  </ListItemIcon>
                  Other
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6} sx={{ marginTop: "14px" }}>
            <FormControl fullWidth>
              <Autocomplete
                name="country"
                variant="standard"
                id="country-select-demo"
                options={countries}
                autoHighlight
                inputValue={country}
                onInputChange={(e, value) => {
                  if (e) {
                    setCountry(value);
                  }
                }}
                disabled={emailSent}
                getOptionLabel={(option) => option.label}
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                    {...props}
                  >
                    <img
                      loading="lazy"
                      width="20"
                      src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                      srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                      alt=""
                    />
                    {option.label} ({option.code}) +{option.phone}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    required
                    disabled={emailSent}
                    variant="standard"
                    {...params}
                    label="Choose a country"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "country",
                    }}
                  />
                )}
              />
            </FormControl>
          </Grid>
        </Grid>
        {error && error.length > 0 && <Alert severity="error">{error}</Alert>}
        {!emailSent ? (
          <Stack component="span" direction="column">
            <LoadingButton
              sx={{ mt: 4 }}
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={isLoading}
              loadingIndicator="Loading..."
            >
              SignUp
            </LoadingButton>
            <Typography
              variant="subtitle2"
              sx={{ opacity: 0.72, color: "grey" }}
            >
              We will send a verification message to your email address
            </Typography>
          </Stack>
        ) : (
          <Stack sx={{ width: "100%", my: 2 }}>
            <Alert
              action={
                <>
                  <Button
                    color="inherit"
                    size="small"
                    onClick={() => setEmailSent(false)}
                  >
                    Change email
                  </Button>
                </>
              }
            >
              Verification email sent to <strong>{formData.email}.</strong>
              <br />
              Please check your "Inbox" and "Spam" folder as well.
            </Alert>
          </Stack>
        )}
      </form>
    </>
  );
}
