import { useState } from "react";
import {
  TextField,
  Grid,
  IconButton,
  InputAdornment,
  Typography,
  Snackbar,
  Alert,
} from "@mui/material";
import { Lock, Visibility, VisibilityOff } from "@mui/icons-material";
import { isStrongPassword } from "../../utils/utilHelper";
import { userRequest } from "../../APIs/userRequestAPI";
import { LoadingButton } from "@mui/lab";

const ProfileSecurity = () => {
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const vertical = "top";
  const horizontal = "center";

  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      isStrongPassword(formData.currentPassword) &&
      isStrongPassword(formData.newPassword) &&
      isStrongPassword(formData.confirmPassword) &&
      isConfirmPasswordValid
    ) {
      if (formData.currentPassword !== formData.newPassword) {
        setError("");
        setIsLoading(true);
        const response = await userRequest(formData, "updatePassword");
        if (response === true) {
          setFormData({
            currentPassword: "",
            newPassword: "",
            confirmPassword: "",
          });
          setSuccess(true);
        } else {
          setError(response);
        }
      } else {
        setError("New password and current password cannot be same");
      }
    } else {
      setError("Invalid Request");
    }
    setIsLoading(false);
  };

  const isConfirmPasswordValid =
    formData.newPassword === formData.confirmPassword;

  const handleToggleCurrentPassword = () => {
    setShowCurrentPassword((prevShow) => !prevShow);
  };

  const handleToggleNewPassword = () => {
    setShowNewPassword((prevShow) => !prevShow);
  };

  const handleToggleConfirmPassword = () => {
    setShowConfirmPassword((prevShow) => !prevShow);
  };

  return (
    <div>
      <Snackbar
        open={success}
        autoHideDuration={2000}
        onClose={() => setSuccess(false)}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert severity="success" sx={{ width: "100%" }}>
          Password changed
        </Alert>
      </Snackbar>
      <Snackbar
        open={error && error.length > 0 ? true : false}
        autoHideDuration={3000}
        onClose={() => setError("")}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert severity="error" sx={{ width: "100%" }}>
          {error}
        </Alert>
      </Snackbar>
      <form onSubmit={handleSubmit} style={{ padding: 24 }}>
        <Typography variant="h5" sx={{ px: 0, mb: 4 }}>
          Change Password
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={8}>
            <TextField
              type={showCurrentPassword ? "text" : "password"}
              name="currentPassword"
              label="Current Password"
              value={formData.currentPassword}
              onChange={handleChange}
              fullWidth
              required
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleToggleCurrentPassword}>
                      {showCurrentPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} sm={8}>
            <TextField
              type={showNewPassword ? "text" : "password"}
              name="newPassword"
              label="New Password"
              value={formData.newPassword}
              onChange={handleChange}
              fullWidth
              required
              error={
                formData.newPassword !== ""
                  ? !isStrongPassword(formData.newPassword)
                  : false
              }
              helperText={
                formData.newPassword !== "" &&
                !isStrongPassword(formData.newPassword)
                  ? "Password must be at least 8 characters long and include at least one alphabet, one number, and one special character."
                  : ""
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleToggleNewPassword}>
                      {showNewPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} sm={8}>
            <TextField
              type={showConfirmPassword ? "text" : "password"}
              name="confirmPassword"
              label="Confirm Password"
              value={formData.confirmPassword}
              onChange={handleChange}
              fullWidth
              required
              error={!isConfirmPasswordValid}
              helperText={!isConfirmPasswordValid && "Passwords don't match"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleToggleConfirmPassword}>
                      {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ pt: 4 }}>
          <Grid item xs={8}>
            <LoadingButton
              fullWidth
              type="submit"
              variant="contained"
              color="secondary"
              loading={isLoading}
              loadingIndicator="Updating..."
              sx={{ p: 1.25 }}
              startIcon={<Lock />}
            >
              Update Password
            </LoadingButton>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default ProfileSecurity;
