import axios from "axios";
import { ORCHESTRATION_API_ENDPOINT } from "../utils/Constants";
import { generateAuthorizationToken } from "../utils/utilHelper";
import { removeCache } from "../store/AppContextProvider";

export const clearSession = async (setLoggedIn) => {
  try {
    const response = await axios.get(
      `${ORCHESTRATION_API_ENDPOINT}/session/clear`,
      {
        withCredentials: true,
        headers: {
          Authorization: generateAuthorizationToken(),
        },
      }
    );
    setLoggedIn(false);
    removeCache();
    if (response.status === 200 && response.data === true) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.error("Error fetching clearSession:", error.message);
    return false;
  }
};
