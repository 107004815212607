import { Grid } from "@mui/material";
import { useAppContext } from "../../store/AppContextProvider";
import AppWidgetSummary from "../../customElements/AppWidgetSummary";

export const MinMaxPriceInformationComponent = ({ priceHistory }) => {
  const { isMobile } = useAppContext();
  let {
    minimumPrice: {
      price: minimumPriceInHistory,
      date: minimumPriceInHistoryDate,
    },
    lastPriceDrop: {
      price: lastPriceDropInHistory,
      date: lastPriceDropInHistoryDate,
    },
    maximumPrice: {
      price: maximumPriceInHistory,
      date: maximumPriceInHistoryDate,
    },
  } = priceHistory;
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={4} style={{ textAlign: "center" }}>
        {minimumPriceInHistory && (
          <AppWidgetSummary
            title="Minimum Price"
            total={minimumPriceInHistory}
            date={minimumPriceInHistoryDate}
            color="info"
            isMobile={isMobile}
          />
        )}
      </Grid>
      <Grid item xs={12} sm={4} style={{ textAlign: "center" }}>
        {lastPriceDropInHistory && (
          <AppWidgetSummary
            title="Last Price Drop"
            total={lastPriceDropInHistory}
            date={lastPriceDropInHistoryDate}
            color="warning"
            isMobile={isMobile}
          />
        )}
      </Grid>
      <Grid item xs={12} sm={4} style={{ textAlign: "center" }}>
        {maximumPriceInHistory && (
          <AppWidgetSummary
            title="Maximum Price"
            total={maximumPriceInHistory}
            date={maximumPriceInHistoryDate}
            color="error"
            isMobile={isMobile}
          />
        )}
      </Grid>
    </Grid>
  );
};
