import { useState, useEffect } from "react";
import { Box, Typography, Tooltip, Chip, Modal, Button } from "@mui/material";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import {
  AmazonText,
  ButtonText,
  ShakeIcon,
} from "../../helper/SearchPageHelper";
import { handleSubscribeNotification } from "../../action/SearchPageAction";
import {
  handleBuyOnButtonClick,
  isRefreshNeeded,
  updateAgoPrice,
} from "../../utils/utilHelper";

import {
  CustomButton,
  IconWrapper,
  LightGreyBackground,
  PriceAlertButton,
} from "../../helper/SearchPageHelper";
import {
  calculateDiscountPercentage,
  formatToIndianCurrency,
} from "../../utils/formatNumber";
import { useAppContext } from "../../store/AppContextProvider";
import AlertOpenViewComponent from "../searchPage/AlertOpenViewComponent";
import ReactSpeedometer from "react-d3-speedometer";
import { StickyShareButtons } from "sharethis-reactjs";
import { MinMaxPriceInformationComponent } from "./MinMaxPriceInformationComponent";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import { Autoplay, FreeMode } from "swiper/modules";
import RefreshIcon from "@mui/icons-material/Refresh";
import InfoIcon from "@mui/icons-material/Info";
import "./search.scss";
import { StyledRating } from "../../customElements/StyledRating";

const SearchURLProductDetailsInfoComponent = ({
  searchURLProductDetails,
  handleRefreshOnDemand,
  refresh,
  priceHistory,
  setErrorMessage,
  setSuccessMessage,
}) => {
  const {
    productId,
    title,
    domain,
    inStock,
    rating,
    url,
    image,
    traits,
    discountPrice,
    originalPrice,
    lastUpdatedAt,
  } = searchURLProductDetails;

  const { isMobile, showSpecialMessage, windowWidth, userInfo } =
    useAppContext();
  const [openAddAlertView, setOpenAddAlertView] = useState(false);
  const [isShaking, setIsShaking] = useState("false");
  const [isPriceAlertPresent, setIsPriceAlertPresent] = useState(false);
  const [subscribed, setSubscribed] = useState(false);
  const [email, setEmail] = useState("");
  const [subsErrorMessage, setSubsErrorMessage] = useState(
    "We will notify you over this email"
  );
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  if (document.querySelector(".submit-email")) {
    document
      .querySelector(".submit-email")
      .addEventListener("mousedown", (e) => {
        e.preventDefault();
        document.querySelector(".subscription").classList.add("done");
      });
  }

  useEffect(() => {
    const shakeInterval = setInterval(() => {
      setIsShaking("true");
      setTimeout(() => {
        setIsShaking("false");
      }, 800);
    }, 3500);
    return () => {
      clearInterval(shakeInterval);
    };
  }, []);

  useEffect(() => {
    if (userInfo && userInfo.email) {
      setEmail(userInfo.email);
      setSubsErrorMessage("");
    }
  }, [userInfo]);

  const validateEmail = (inputEmail) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (inputEmail.length === 0) {
      setSubsErrorMessage("We will notify you over this email");
      return false;
    } else {
      if (!emailRegex.test(inputEmail)) {
        setSubsErrorMessage("Invalid email address");
        return false;
      } else {
        setSubsErrorMessage("");
        return true;
      }
    }
  };

  const handleInputChange = (event) => {
    const inputValue = event.target.value;
    setEmail(inputValue);
    validateEmail(inputValue);
  };

  const handleSubscribe = () => {
    if (validateEmail(email)) {
      handleSubscribeNotification(email, url, productId);
      setSubscribed(true);
    }
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  return (
    <div className="search_url_details_container">
      <Box>
        <Typography variant="subtitle1">{title}</Typography>
        <div className="search_url_details_rating_price">
          {rating && rating.ratingCount && (
            <Box sx={{ my: 2, display: "flex", flexWrap: "wrap" }}>
              <Tooltip title={rating.ratingCount} arrow disableInteractive>
                <Box sx={{ mr: 1, mt: 0.2, color: "#637381" }}>
                  {rating.ratingCount.split(" out")[0]}
                </Box>
              </Tooltip>
              <StyledRating
                name="half-rating-read"
                defaultValue={Number(rating.ratingCount.split(" out")[0])}
                precision={0.1}
                readOnly
              />
              <Box sx={{ ml: 1, color: "#637381" }}>
                | &nbsp;
                {rating.totalRated}
              </Box>
            </Box>
          )}
          {refresh ? (
            <div className="loader">
              <div className="bar1"></div>
              <div className="bar2"></div>
              <div className="bar3"></div>
              <div className="bar4"></div>
              <div className="bar5"></div>
              <div className="bar6"></div>
            </div>
          ) : (
            <>
              {discountPrice && originalPrice ? (
                <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                  <Typography variant="h4">
                    {formatToIndianCurrency(discountPrice)}
                  </Typography>
                  <Typography
                    variant="h6"
                    sx={{
                      pl: 1,
                      mb: 0.2,
                      color: "text.disabled",
                      textDecoration: "line-through",
                    }}
                  >
                    {formatToIndianCurrency(originalPrice)}
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    color="#2F903B"
                    sx={{
                      pl: 1,
                      mb: 0.2,
                    }}
                  >
                    {calculateDiscountPercentage(originalPrice, discountPrice)}
                  </Typography>
                </Box>
              ) : (
                <Typography
                  variant="h6"
                  color="#dbdbdb"
                  sx={{
                    pl: 1,
                    mb: 0.2,
                  }}
                >
                  Out Of Stock
                </Typography>
              )}
            </>
          )}
        </div>
        <div
          style={{
            display: "flex",
            height: "25px",
            justifyContent: "flex-start",
            alignItems: "flex-end",
          }}
        >
          {refresh ? (
            <Typography
              variant="subtitle2"
              sx={{
                pl: 0.5,
                fontSize: "13px",
              }}
            >
              Updating...
            </Typography>
          ) : (
            <Typography
              variant="subtitle2"
              sx={{
                pl: 0.5,
                marginRight: "10px",
                fontSize: "13px",
              }}
            >
              {lastUpdatedAt && updateAgoPrice(lastUpdatedAt)}
            </Typography>
          )}
          {!refresh && isRefreshNeeded(lastUpdatedAt) && (
            <Tooltip title="Refresh Price" arrow placement="right">
              <RefreshIcon
                onClick={handleRefreshOnDemand}
                sx={{ p: 0, m: 0, color: "#3498db", cursor: "pointer" }}
              />
            </Tooltip>
          )}
        </div>
        <Swiper
          style={{ margin: "12px 0" }}
          slidesPerView={"auto"}
          spaceBetween={10}
          freeMode={true}
          autoplay={{
            delay: 2500,
            disableOnInteraction: true,
          }}
          modules={[Autoplay, FreeMode]}
          className="mySwiper"
        >
          {traits &&
            traits.length > 0 &&
            traits.map((val, index) => (
              <SwiperSlide
                key={index + "_" + val}
                style={{ width: "fit-content" }}
              >
                <Chip label={val} />
              </SwiperSlide>
            ))}
        </Swiper>

        {priceHistory && Object.keys(priceHistory).length > 0 && (
          <MinMaxPriceInformationComponent priceHistory={priceHistory} />
        )}
        <div className="search_url_details_speedometer_subscribe">
          {!(discountPrice && originalPrice) && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                height: 150,
              }}
            >
              <Typography variant="subtitle1" sx={{ my: 2 }}>
                Do you want to get notified when product will be back In Stock?
              </Typography>
              <div className="content">
                <div className="subscription">
                  <input
                    className="add-email"
                    type="email"
                    placeholder="Enter your email"
                    value={email}
                    onChange={
                      userInfo && userInfo.email ? null : handleInputChange
                    }
                    style={{ paddingRight: "110px" }}
                    readOnly={userInfo && userInfo.email}
                  />
                  <button
                    className={
                      subsErrorMessage.trim().length === 0
                        ? "submit-email active-subs-button"
                        : "submit-email"
                    }
                    type="button"
                    onClick={handleSubscribe}
                    disabled={subsErrorMessage.length > 0}
                  >
                    <span
                      className={subscribed ? "after-submit" : "before-submit"}
                    >
                      {subscribed ? "Thank you for subscribing" : "Subscribe"}
                    </span>
                  </button>
                </div>
                {subsErrorMessage && (
                  <div
                    className={
                      subsErrorMessage === "Invalid email address"
                        ? "subs-error-message invalid-sub-email"
                        : "subs-error-message"
                    }
                  >
                    {subsErrorMessage}
                  </div>
                )}
              </div>
            </div>
          )}
          {priceHistory &&
            Object.keys(priceHistory).length > 0 &&
            priceHistory.analysis && (
              <Box sx={{ my: 2 }}>
                <LightGreyBackground style={{ position: "relative" }}>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Typography variant="h6">
                      Smart Shopping: Wait or Buy Today?
                    </Typography>
                    <InfoIcon
                      onClick={handleOpen}
                      style={{
                        cursor: "pointer",
                        marginLeft: "10px",
                        marginTop: "3px",
                        color: "#0077be",
                      }}
                    />
                  </div>
                  <ReactSpeedometer
                    minValue={0}
                    maxValue={100}
                    width={isMobile ? windowWidth - 40 : 360}
                    height={180}
                    value={
                      priceHistory &&
                      priceHistory.analysis &&
                      priceHistory.analysis.status * 100
                    }
                    currentValueText="Shop-o-Meter"
                    customSegmentLabels={[
                      {
                        text: "Avoid",
                        position: "INSIDE",
                        color: "#555",
                      },
                      {
                        text: "Wait",
                        position: "INSIDE",
                        color: "#555",
                      },
                      {
                        text: "Consider",
                        position: "INSIDE",
                        color: "#555",
                      },
                      {
                        text: "Buy",
                        position: "INSIDE",
                        color: "#555",
                      },
                    ]}
                    segments={4}
                    ringWidth={47}
                    needleHeightRatio={0.8}
                    needleTransitionDuration={6000}
                    needleTransition="easeElastic"
                    needleColor={"#A62A6C"}
                    textColor={"#c6c6c6"}
                  />
                  <Typography variant="subtitle2">
                    {priceHistory &&
                      priceHistory.analysis &&
                      priceHistory.analysis.message}
                  </Typography>
                </LightGreyBackground>
              </Box>
            )}
        </div>
        <Box sx={{ mt: 3, display: "flex" }}>
          <CustomButton
            variant="contained"
            endIcon={<ShoppingCartIcon />}
            onClick={() => handleBuyOnButtonClick(url)}
            style={{
              marginRight: "10px",
            }}
          >
            <ButtonText>
              View on &nbsp;
              <AmazonText>{domain}</AmazonText>
            </ButtonText>
          </CustomButton>
          {inStock && Boolean(inStock) && (
            <PriceAlertButton
              variant="contained"
              onClick={() => {
                setOpenAddAlertView(!openAddAlertView);
              }}
            >
              <IconWrapper>
                <ShakeIcon shaking={isShaking} />
              </IconWrapper>
              <ButtonText>
                {isPriceAlertPresent ? "Update Price Alert" : "Add Price Alert"}
              </ButtonText>
            </PriceAlertButton>
          )}
        </Box>
        <AlertOpenViewComponent
          discountPrice={discountPrice ? discountPrice : 0}
          openAddAlertView={openAddAlertView}
          setOpenAddAlertView={setOpenAddAlertView}
          setIsPriceAlertPresent={setIsPriceAlertPresent}
          setSuccessMessage={setSuccessMessage}
          setErrorMessage={setErrorMessage}
          url={url}
        />
        <StickyShareButtons
          config={{
            alignment: "right",
            color: "social",
            enabled: true,
            font_size: 16,
            labels: "counts",
            language: "en",
            min_count: 100,
            networks: ["whatsapp", "telegram"],
            padding: 12,
            radius: 4,
            show_total: true,
            show_mobile: true,
            show_toggle: true,
            size: 48,
            top: showSpecialMessage ? 280 : 240,
            image: image,
            description: title,
          }}
        />
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Price/Analysis Disclaimer
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              <ul style={{ listStyleType: "disc", paddingLeft: "20px" }}>
                <li>
                  <span>
                    Product prices and availability analysis are based on
                    historical data available in our database.
                  </span>
                </li>
                <li>
                  <span>
                    However, it is important to note that this analysis is not a
                    guarantee of the current or future prices of the products.
                  </span>
                </li>
                <li>
                  <span>
                    Prices and availability are accurate as of the date/time
                    indicated in the analysis and are subject to change without
                    notice.
                  </span>
                </li>
                <li>
                  <span>
                    Any price and availability information displayed on the
                    actual store websites (e.g., Amazon, Flipkart, etc.) at the
                    time of purchase will prevail and apply to the actual
                    purchase of the displayed products.
                  </span>
                </li>
                <li>
                  <span>
                    <strong>
                      We strongly recommend that you always visit the respective
                      store's website to verify the most up-to-date pricing and
                      availability information before making any purchasing
                      decisions.
                    </strong>
                  </span>
                </li>
              </ul>
            </Typography>
            <Button
              style={{ margin: "10px auto" }}
              variant="outlined"
              color="primary"
              onClick={handleClose}
              fullWidth
            >
              Agree and Close
            </Button>
          </Box>
        </Modal>
      </Box>
    </div>
  );
};

export default SearchURLProductDetailsInfoComponent;
