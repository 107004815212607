import { Helmet } from "react-helmet-async";
import { Container, Box } from "@mui/material";
import { useEffect, useState } from "react";
import SnackBarError from "../customElements/SnackBar/SnackBarError";
import HomePageLoading from "../components/homepage/HomePageLoading";
import ToggleButtonsHomePage from "../components/homepage/ToggleButtonsHomePage";
import HomePageView from "../components/homepage/HomePageView";
import { fetchHomePageDeals } from "../action/HomePageAction";
import { DEFAULT_HOMEPAGE_VIEW } from "../utils/Constants";

export default function DashboardHomePage() {
  const [deals, setDeals] = useState({});
  const [view, setView] = useState(DEFAULT_HOMEPAGE_VIEW);
  const [isloading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    fetchHomePageDeals(setIsLoading, setDeals, setErrorMessage);
  }, []);

  return (
    <>
      <Helmet>
        <title> Dashboard | Home </title>
      </Helmet>
      <SnackBarError
        errorMessage={errorMessage}
        setErrorMessage={setErrorMessage}
      />
      <Container maxWidth="xl">
        <ToggleButtonsHomePage view={view} setView={setView} />
        <>
          {isloading ? (
            <HomePageLoading view={view} />
          ) : (
            <div>
              {deals && Object.keys(deals).length > 0 ? (
                <HomePageView view={view} deals={deals} />
              ) : (
                <Box
                  component="img"
                  alt="empty_home_page"
                  src="/assets/illustrations/empty_home_page.svg"
                  sx={{ height: 420, mx: "auto", my: { xs: 5, sm: 10 } }}
                />
              )}
            </div>
          )}
        </>
      </Container>
    </>
  );
}
