import { addDays, startOfDay } from "date-fns";
import {
  Alignment,
  PriceGraphData,
  SearchProductDetailsResponseSchema,
  SearchProductListResponseSchema,
  SubscriptionData,
} from "../schema/SearchSchema";
import { formatDate } from "../utils/formattedDate";
import {
  isPriceHistoryResponseValid,
  isSearchProductDetailsValid,
  isSearchProductListValid,
  isValidSearchURL,
  isValidSearchValue,
} from "../utils/utilHelper";
import {
  getDetailsForSearchURLFromCache,
  getDetailsForSearchValueFromCache,
  getPriceHistoryForSearchURLFromCache,
  storeDataWithTimestamp,
} from "../utils/caching";
import { fetchDetailsFromSearchValue_API } from "../APIs/fetchDetailsFromSearchValue_API";
import {
  CONST_PRODUCT_DETAILS_CACHE_PREFIX,
  CONST_PRODUCT_PRICE_HISTORY_SUFFIX,
  CONST_SEARCH_URL_QUERY_PARAM_KEY,
  CONST_SEARCH_VALUE_QUERY_PARAM_KEY,
} from "../utils/Constants";
import { fetchDetailsFromSearchURL_API } from "../APIs/fetchDetailsFromSearchURLAPI";
import { PriceHistoryResponseSchema } from "../schema/PriceHistorySchema";
import { fetchPriceHistory_API } from "../APIs/fetchPriceHistory_API";
import { ErrorMessages } from "../helper/ErrorMessageEnum";
import { addSubscriptionAPI } from "../APIs/addSubscription";

export const handleSearch = async (
  params: any,
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setSearchURLProductDetails: React.Dispatch<
    React.SetStateAction<SearchProductDetailsResponseSchema | null>
  >,
  setSearchValueProductList: React.Dispatch<
    React.SetStateAction<SearchProductListResponseSchema[] | null>
  >,
  setErrorMessage: React.Dispatch<React.SetStateAction<string>>
) => {
  const search_url = params.get(CONST_SEARCH_URL_QUERY_PARAM_KEY);
  let search_value = params.get(CONST_SEARCH_VALUE_QUERY_PARAM_KEY);
  if (isValidSearchValue(search_value)) {
    setSearchURLProductDetails(null);
    search_value = search_value.trim().replace(/ +/g, "+");
    const cacheDetailsFromSearchValueKey =
      CONST_PRODUCT_DETAILS_CACHE_PREFIX + search_value.toUpperCase();
    let productListFromSearchValue =
      getDetailsForSearchValueFromCache(search_value);
    if (!isSearchProductListValid(productListFromSearchValue)) {
      setIsLoading(true);
      productListFromSearchValue = await fetchDetailsFromSearchValue_API(
        search_value
      );
      if (
        !productListFromSearchValue.error &&
        isSearchProductListValid(productListFromSearchValue.data)
      ) {
        productListFromSearchValue = productListFromSearchValue.data;
        storeDataWithTimestamp(
          cacheDetailsFromSearchValueKey,
          productListFromSearchValue
        );
      } else {
        setErrorMessage(productListFromSearchValue.error);
      }
    }
    setIsLoading(false);

    setSearchValueProductList(productListFromSearchValue);
  } else if (isValidSearchURL(search_url)) {
    localStorage.setItem("lastVisitedURL", search_url);
    setSearchValueProductList(null);
    let productDetailsFromSearchURL =
      getDetailsForSearchURLFromCache(search_url);
    if (!isSearchProductDetailsValid(productDetailsFromSearchURL)) {
      setIsLoading(true);
      productDetailsFromSearchURL = await fetchDetailsFromSearchURL_API(
        search_url
      );
      if (
        !productDetailsFromSearchURL.error &&
        isSearchProductDetailsValid(productDetailsFromSearchURL.data)
      ) {
        productDetailsFromSearchURL = productDetailsFromSearchURL.data;
        storeDataWithTimestamp(
          productDetailsFromSearchURL.url,
          productDetailsFromSearchURL
        );
      } else {
        setErrorMessage(productDetailsFromSearchURL.error);
      }
    }
    setIsLoading(false);
    setSearchURLProductDetails(productDetailsFromSearchURL);
  } else {
    setErrorMessage("Try something else!!!");
  }
};

export const fetchPriceHistory = async (
  search_url: string,
  setPriceHistory: React.Dispatch<
    React.SetStateAction<PriceHistoryResponseSchema | null>
  >,
  setErrorMessage: React.Dispatch<React.SetStateAction<string>>
) => {
  if (isValidSearchURL(search_url)) {
    let priceHistorytDetailsFromSearchURL =
      getPriceHistoryForSearchURLFromCache(search_url);
    if (!isPriceHistoryResponseValid(priceHistorytDetailsFromSearchURL)) {
      let productDetailsFromSearchURL =
        getDetailsForSearchURLFromCache(search_url);
      if (isSearchProductDetailsValid(productDetailsFromSearchURL)) {
        priceHistorytDetailsFromSearchURL = await fetchPriceHistory_API(productDetailsFromSearchURL.productId);
        if (
          !priceHistorytDetailsFromSearchURL.error &&
          isPriceHistoryResponseValid(priceHistorytDetailsFromSearchURL.data)
        ) {
          priceHistorytDetailsFromSearchURL = priceHistorytDetailsFromSearchURL.data;
          storeDataWithTimestamp(
            productDetailsFromSearchURL.productId + CONST_PRODUCT_PRICE_HISTORY_SUFFIX, priceHistorytDetailsFromSearchURL
          );
          setPriceHistory(priceHistorytDetailsFromSearchURL);
        } else {
          setErrorMessage(ErrorMessages.PRICE_ANAYLYSIS_UNAVAILABLE);
        }
      } else {
        setPriceHistory(priceHistorytDetailsFromSearchURL);
      }
    }
  }
};

export const handleSubscribeNotification = async (
  email: string,
  url: string,
  productId : string
): Promise<void> => {
  const obj: SubscriptionData = {
    productId : productId,
    url: url,
    email: email,
  };
  await addSubscriptionAPI(obj);
};

export const handleApplyDates = (
  dates: { startDate: Date; endDate: Date; key: string }[] | undefined,
  originalPriceHistoryGraphData: Record<string, number>
): PriceGraphData[] | undefined => {
  if (dates) {
    let priceGraphArr: PriceGraphData[] = [];
    Object.keys(originalPriceHistoryGraphData).forEach((timestamp) => {
      const startDate = formatDate(dates[0].startDate);
      const endDate = formatDate(dates[0].endDate);

      if (
        new Date(timestamp).getTime() >= new Date(startDate).getTime() &&
        new Date(timestamp).getTime() <= new Date(endDate).getTime()
      ) {
        const obj: PriceGraphData = {
          date: timestamp,
          price: originalPriceHistoryGraphData[timestamp],
        };
        priceGraphArr.unshift(obj);
      }
    });
    return priceGraphArr;
  }
};

export const generatePriceGraphData = (
  alignment: Alignment,
  originalPriceHistoryGraphData: Record<string, number> | undefined
): PriceGraphData[] | undefined => {
  let priceGraphArr: PriceGraphData[] = [];
  if (alignment && alignment !== "None" && originalPriceHistoryGraphData) {
    Object.keys(originalPriceHistoryGraphData).forEach((timestamp) => {
      if (alignment === "1W") {
        const endDate = formatDate(startOfDay(addDays(new Date(), -6)));
        if (new Date(timestamp).getTime() >= new Date(endDate).getTime()) {
          const obj: PriceGraphData = {
            date: timestamp,
            price: originalPriceHistoryGraphData[timestamp],
          };
          priceGraphArr.unshift(obj);
        }
      } else if (alignment === "1M") {
        const endDate = formatDate(startOfDay(addDays(new Date(), -29)));
        if (new Date(timestamp).getTime() >= new Date(endDate).getTime()) {
          const obj: PriceGraphData = {
            date: timestamp,
            price: originalPriceHistoryGraphData[timestamp],
          };
          priceGraphArr.unshift(obj);
        }
      } else if (alignment === "1Y") {
        const endDate = formatDate(startOfDay(addDays(new Date(), -364)));
        if (new Date(timestamp).getTime() >= new Date(endDate).getTime()) {
          const obj: PriceGraphData = {
            date: timestamp,
            price: originalPriceHistoryGraphData[timestamp],
          };
          priceGraphArr.unshift(obj);
        }
      }
    });
    return priceGraphArr;
  }
};

export const handleSearchValueProductListFilterAndSort = (
  tempSearchValueProductListData:
    | SearchProductListResponseSchema[]
    | null
    | undefined,
  filterValue: any,
  sortValue: string
) => {
  if (!tempSearchValueProductListData) {
    return [];
  }
  if (filterValue.store !== "") {
    const products = tempSearchValueProductListData.filter(
      (product: SearchProductListResponseSchema) =>
        product.domain === filterValue.store.toUpperCase()
    );
    tempSearchValueProductListData = products;
  }
  if (filterValue.discountPrice !== "") {
    if (filterValue.discountPrice === "below") {
      const products = tempSearchValueProductListData.filter(
        (product: SearchProductListResponseSchema) =>
          product.discountPrice < 499
      );
      tempSearchValueProductListData = products;
    } else if (filterValue.discountPrice === "between") {
      const products = tempSearchValueProductListData.filter(
        (product) =>
          product.discountPrice >= 499 && product.discountPrice <= 999
      );
      tempSearchValueProductListData = products;
    } else if (filterValue.discountPrice === "above") {
      const products = tempSearchValueProductListData.filter(
        (product) => product.discountPrice > 999
      );
      tempSearchValueProductListData = products;
    }
  }
  if (filterValue.rating !== "") {
    if (filterValue.rating === "up4Star") {
      const products = tempSearchValueProductListData.filter(
        (product) =>
          Number(product.rating && product.rating.split(" out")[0]) >= 4
      );
      tempSearchValueProductListData = products;
    } else if (filterValue.rating === "up3Star") {
      const products = tempSearchValueProductListData.filter(
        (product) =>
          Number(product.rating && product.rating.split(" out")[0]) >= 3
      );
      tempSearchValueProductListData = products;
    } else if (filterValue.discountPrice === "up2Star") {
      const products = tempSearchValueProductListData.filter(
        (product) =>
          Number(product.rating && product.rating.split(" out")[0]) >= 2
      );
      tempSearchValueProductListData = products;
    } else if (filterValue.discountPrice === "up1Star") {
      const products = tempSearchValueProductListData.filter(
        (product) =>
          Number(product.rating && product.rating.split(" out")[0]) >= 1
      );
      tempSearchValueProductListData = products;
    }
  }
  if (sortValue === "priceAsc") {
    tempSearchValueProductListData.sort(
      (a, b) => a.discountPrice - b.discountPrice
    );
  } else if (sortValue === "priceDesc") {
    tempSearchValueProductListData.sort(
      (a, b) => b.discountPrice - a.discountPrice
    );
  }
  return tempSearchValueProductListData;
};
