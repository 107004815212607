import { useEffect, useState } from "react";
import { TextField, Typography, Box, Alert } from "@mui/material";
import { sendContactUsMailAPI } from "../APIs/sendContactUsMail";
import { useNavigate } from "react-router-dom";
import SendIcon from "@mui/icons-material/Send";
import { LoadingButton } from "@mui/lab";

export default function ContactForm() {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError("");
    setSuccess("");
    const response = await sendContactUsMailAPI({ name, email, message });
    if (response && response.length > 0) {
      setName("");
      setEmail("");
      setMessage("");
      setIsLoading(false);
      setSuccess(response);
    } else {
      setIsLoading(false);
      setError("Failed to send!!!");
    }
  };

  useEffect(() => {
    if (success && success.length > 0) {
      setTimeout(() => {
        navigate("/", { replace: true });
      }, 1200);
    }
  }, [navigate, success]);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box sx={{ maxWidth: 600, mx: "auto", p: 2 }}>
        <Typography variant="h4" align="center" mb={2}>
          Contact Us
        </Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            fullWidth
            label="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            margin="normal"
            required
          />
          <TextField
            fullWidth
            label="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            margin="normal"
            required
            type="email"
            helperText="❤️ We promise to respond to you via this email address."
          />
          <TextField
            fullWidth
            label="Message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            margin="normal"
            required
            multiline
            rows={4}
          />
          {error && error.length > 0 && <Alert severity="error">{error}</Alert>}
          {success && success.length > 0 && (
            <Alert severity="success">{success}</Alert>
          )}
          <LoadingButton
            variant="contained"
            type="submit"
            sx={{ mt: 2 }}
            loading={isLoading}
            loadingPosition="end"
            endIcon={<SendIcon />}
          >
            Send
          </LoadingButton>
        </form>
      </Box>
    </Box>
  );
}
