import axios from "axios";
import { ORCHESTRATION_API_ENDPOINT } from "../utils/Constants";
import { generateAuthorizationToken } from "../utils/utilHelper";

export const deleteAlertAPI = async (url, productId) => {
  try {
    const response = await axios.delete(
      `${ORCHESTRATION_API_ENDPOINT}/alert?url=${url}&productId=${productId}`,
      {
        withCredentials: true,
        headers: {
          Authorization: generateAuthorizationToken(),
        },
      }
    );
    if (response.status === 200 && response.data) {
      return response.data;
    } else {
      return null;
    }
  } catch (error) {
    console.error("Error fetching deleteAlertAPI:", error.message);
    return null;
  }
};
