import axios from "axios";
import { ORCHESTRATION_API_ENDPOINT } from "../utils/Constants";
import { generateAuthorizationToken } from "../utils/utilHelper";

export const getPriceAlertAPI = async () => {
  try {
    const response = await axios.get(`${ORCHESTRATION_API_ENDPOINT}/alert`, {
      withCredentials: true,
      headers: {
        Authorization: generateAuthorizationToken(),
      },
    });
    if (response.status === 200 && response.data) {
      return response.data;
    } else {
      return null;
    }
  } catch (error) {
    console.error("Error fetching getPriceAlertAPI:", error.message);
    return null;
  }
};
