import { Helmet } from "react-helmet-async";
import { Container, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import Logo from "../../customElements/Logo";

export const DisclaimerPage = () => {
  return (
    <div className="aboutUs">
      <Helmet>
        <title> Disclaimer | TrackMYPrice </title>
      </Helmet>
      <Logo
        sx={{
          position: "relative",
          top: { xs: 16, sm: 24, md: 40 },
          left: { xs: 16, sm: 24, md: 40 },
        }}
      />
      <Container maxWidth="lg">
        <Grid container spacing={5} sx={{ padding: "96px 12px" }}>
          <Grid item xs={12}>
            <Typography
              variant="h3"
              sx={{
                padding: "24px",
                backgroundColor: "#fce8f3",
                textAlign: "center",
              }}
            >
              Disclaimer
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h5" sx={{ mb: 1 }}>
              Interpretation and Definitions
            </Typography>
            <Typography variant="h6" sx={{ mb: 1 }}>
              Interpretation
            </Typography>
            <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
              The words of which the initial letter is capitalized have meanings
              defined under the following conditions. The following definitions
              shall have the same meaning regardless of whether they appear in
              singular or in plural.
            </Typography>
            <Typography variant="h6" sx={{ mb: 1 }}>
              Definitions
            </Typography>
            <Typography variant="body1" color="text.secondary" sx={{ mb: 2 }}>
              For the purposes of this Disclaimer:
            </Typography>
            <ul>
              <li>
                <strong>Company</strong> (referred to as either &quot;the
                Company&quot;, &quot;We&quot;, &quot;Us&quot; or &quot;Our&quot;
                in this Disclaimer) refers to TrackMYPrice.
              </li>
              <li>
                <strong>Service</strong> refers to the Website.
              </li>
              <li>
                <strong>You</strong> means the individual accessing the Service,
                or the company, or other legal entity on behalf of which such
                individual is accessing or using the Service, as applicable.
              </li>
              <li>
                <strong>Website</strong> refers to TrackMYPrice, accessible from{" "}
                <a href="https://www.trackmyprice.in/">
                  https://www.trackmyprice.in/
                </a>
              </li>
            </ul>
            <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>
              Disclaimer
            </Typography>
            <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
              The information contained on the Service is for general
              information purposes only. The Company assumes no responsibility
              for errors or omissions in the contents of the Service. In no
              event shall the Company be liable for any special, direct,
              indirect, consequential, or incidental damages or any damages
              whatsoever, whether in an action of contract, negligence or other
              tort, arising out of or in connection with the use of the Service
              or the contents of the Service. The Company reserves the right to
              make additions, deletions, or modifications to the contents on the
              Service at any time without prior notice.
            </Typography>
            <Typography variant="h6" sx={{ mb: 1 }}>
              Method of Discount Calculation
            </Typography>
            <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
              Kindly note that the discounts displayed on our platform could
              differ from those presented on merchant websites. Our discount
              calculation method is rooted in the highest recorded price of a
              product, as opposed to solely relying on the Maximum Retail Price
              (M.R.P.). Unlike some merchant sites that calculate discounts
              based on the M.R.P. even if the product has never reached that
              price point, our calculations prioritize accuracy. Prices and
              discounts are rounded for enhanced readability.
            </Typography>
            <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>
              Affiliation and Earnings
            </Typography>
            <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
              Our cutting-edge platform offers more than just product listings.
              We provide you with real-time updates on product prices across a
              wide range of categories. Whether it's the latest electronics,
              trendy fashion items, or household essentials, we keep you
              informed about price changes as they happen. What's more, our
              historical data feature lets you delve into the past, exploring
              trends and making predictions.
            </Typography>
            <Typography variant="h6" sx={{ mb: 1 }}>
              Method of Discount Calculation
            </Typography>
            <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
              Kindly note that the discounts displayed on our platform could
              differ from those presented on merchant websites. Our discount
              calculation method is rooted in the highest recorded price of a
              product, as opposed to solely relying on the Maximum Retail Price
              (M.R.P.). Unlike some merchant sites that calculate discounts
              based on the M.R.P. even if the product has never reached that
              price point, our calculations prioritize accuracy. Prices and
              discounts are rounded for enhanced readability.
            </Typography>
            <Typography variant="h6" sx={{ mb: 1 }}>
              Precision in Pricing and Availability
            </Typography>
            <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
              Product prices and availability details are maintained as accurate
              as of the specified date and time. Nevertheless, these details are
              subject to change without prior notification. The pricing and
              availability information showcased on the official store of the
              merchant (such as Amazon, Flipkart, Myntra, etc.) at the time of
              purchase will govern the final transaction.
            </Typography>
            <Typography variant="h6" sx={{ mb: 1 }}>
              Price History Accuracy Disclaimer
            </Typography>
            <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
              We acknowledge the possibility of inaccuracies in the historical
              price data we provide. While we diligently strive to offer the
              most dependable insights into product price trends, there could be
              instances of discrepancies due to a range of factors, including
              data collection and updates. As a result, the price history
              analysis offered should be regarded as indicative rather than
              definitive.
            </Typography>
            <Typography variant="h6" sx={{ mb: 1 }}>
              Analysis Based on Price History Clarification
            </Typography>
            <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
              Our platform extends the provision of analysis based on historical
              price data we've collected. It is important to recognize that
              while we aim for accuracy, the analysis provided might not always
              be entirely precise. Variables and external factors can influence
              the interpretation of historical price trends, leading to
              potential discrepancies.
            </Typography>
            <Typography variant="h6" sx={{ mb: 1 }}>
              Brand Logos & Trademarks
            </Typography>
            <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
              All logos, brand names, and trademarks belong to their respective
              owners. We respect intellectual property rights and acknowledge
              the ownership of these entities.
            </Typography>
            <Typography variant="h6" sx={{ mb: 1 }}>
              Reporting Unappropriated Use
            </Typography>
            <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
              Should you come across any unauthorized or inappropriate use of
              your logo, brand name, or trademark, we encourage you to reach out
              to us at care@trackmyprice.in. To facilitate the resolution
              process, please include legitimate ownership information to verify
              your claim. This ensures that we can promptly rectify any misuse
              and prevents false or unauthorized requests.
            </Typography>
            <Typography variant="h6" sx={{ mb: 1 }}>
              Content & Information Source
            </Typography>
            <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
              The content, photos, text, and other materials presented on our
              Product pages may incorporate information sourced from various
              channels, including Store Listing Pages, Official Brand Websites,
              Product Brochures, Social Media Announcements by competent
              authorities or individuals, and other mediums. At times, this
              information is displayed as is, without alterations. While we
              exercise the utmost caution in obtaining and presenting
              information, we do not assert that the information provided on the
              website is entirely error-free or 100% accurate.
            </Typography>
            <Typography variant="h6" sx={{ mb: 1 }}>
              Correction of Incorrect Information
            </Typography>
            <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
              In the event that you identify any inaccuracies in the information
              provided, we invite you to reach out to us at care@trackmyprice.in
              or submit a correction through our platform. We are committed to
              addressing any discrepancies promptly and making necessary
              rectifications in a timely manner.
            </Typography>
            <Typography variant="h6" sx={{ mb: 1 }}>
              "Use at Your Own Risk" Disclaimer
            </Typography>
            <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
              All information in the Service is provided "as is", with no
              guarantee of completeness, accuracy, timeliness or of the results
              obtained from the use of this information, and without warranty of
              any kind, express or implied, including, but not limited to
              warranties of performance, merchantability and fitness for a
              particular purpose. The Company will not be liable to You or
              anyone else for any decision made or action taken in reliance on
              the information given by the Service or for any consequential,
              special or similar damages, even if advised of the possibility of
              such damages.
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default DisclaimerPage;
