import { Helmet } from "react-helmet-async";
import { useEffect, useState } from "react";
// @mui
import { Box, Container, Skeleton, Stack, Typography } from "@mui/material";
// components
import { getPriceAlertAPI } from "../APIs/getPriceAlertAPI";
import { ProductFilterSidebar, ProductSort } from "../components/products";
import AlertList from "../components/products/AlertList";
import { deleteAlertAPI } from "../APIs/deleteAlertAPI";
import { useNavigate } from "react-router-dom";
import { useAppContext } from "../store/AppContextProvider";

// ----------------------------------------------------------------------

export default function AlertPage() {
  const navigate = useNavigate();
  const { loggedIn, alertCount, setAlertCount } = useAppContext();
  const [openFilter, setOpenFilter] = useState(false);
  const [data, setData] = useState({});
  const [alerts, setAlerts] = useState([]);
  const [limit, setLimit] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [sortValue, setSortValue] = useState("latest");
  const [filterValue, setFilterValue] = useState({
    store: "",
    alertPrice: "",
  });

  useEffect(() => {
    if (data && data.alerts && data.alerts.length > 0) {
      let originalAlerts = [...data.alerts];
      if (originalAlerts) {
        if (filterValue.store !== "") {
          const products = originalAlerts.filter(
            (product) => product.domain === filterValue.store.toUpperCase()
          );
          originalAlerts = products;
        }
        if (filterValue.alertPrice !== "") {
          if (filterValue.alertPrice === "below") {
            const products = originalAlerts.filter(
              (product) => product.alertPrice < 499
            );
            originalAlerts = products;
          } else if (filterValue.alertPrice === "between") {
            const products = originalAlerts.filter(
              (product) =>
                product.alertPrice >= 499 && product.alertPrice <= 999
            );
            originalAlerts = products;
          } else if (filterValue.alertPrice === "above") {
            const products = originalAlerts.filter(
              (product) => product.alertPrice > 999
            );
            originalAlerts = products;
          }
        }
        if (sortValue === "priceAsc") {
          originalAlerts.sort((a, b) => a.alertPrice - b.alertPrice);
        } else if (sortValue === "priceDesc") {
          originalAlerts.sort((a, b) => b.alertPrice - a.alertPrice);
        } else {
          originalAlerts = originalAlerts.reverse();
        }
        setAlerts([...originalAlerts]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortValue, filterValue]);

  const handleOpenFilter = () => {
    setOpenFilter(true);
  };

  const handleCloseFilter = () => {
    setOpenFilter(false);
  };

  useEffect(() => {
    async function fetchAlert() {
      const response = await getPriceAlertAPI();
      if (response) {
        setData(JSON.parse(JSON.stringify(response)));
        const originalAlerts = [...response.alerts];
        const reversedAlerts = originalAlerts.reverse();
        setAlerts(reversedAlerts);
        setAlertCount(Number(response.count));
        setLimit(Number(response.limit));
      }
      setIsLoading(false);
    }
    if (loggedIn) {
      fetchAlert();
    } else {
      setIsLoading(false);
    }
  }, [loggedIn, navigate, setAlertCount]);

  const handleDeleteAlert = async (url, productId) => {
    const response = await deleteAlertAPI(url, productId);
    setData(JSON.parse(JSON.stringify(response)));
    const originalAlerts = [...response.alerts];
    const reversedAlerts = originalAlerts.reverse();
    setAlerts(reversedAlerts);
    setAlertCount(Number(response.count));
    setLimit(Number(response.limit));
  };

  return (
    <>
      <Helmet>
        <title> Dashboard: My Alerts | TrackMYPrice</title>
      </Helmet>

      <Container>
        <Typography variant="h4" sx={{ mb: 5 }}>
          Total Price Alert Products ( {alertCount}/{limit} )
        </Typography>

        {!isLoading ? (
          <>
            {alerts && alerts.length > 0 ? (
              <>
                <Stack
                  direction="row"
                  flexWrap="wrap-reverse"
                  alignItems="center"
                  justifyContent="flex-end"
                  sx={{ mb: 5 }}
                >
                  <Stack
                    direction="row"
                    spacing={1}
                    flexShrink={0}
                    sx={{ my: 1 }}
                  >
                    <ProductFilterSidebar
                      openFilter={openFilter}
                      onOpenFilter={handleOpenFilter}
                      onCloseFilter={handleCloseFilter}
                      filterValue={filterValue}
                      setFilterValue={setFilterValue}
                    />
                    <ProductSort
                      sortValue={sortValue}
                      setSortValue={setSortValue}
                    />
                  </Stack>
                </Stack>

                <AlertList alerts={alerts} deleteAlert={handleDeleteAlert} />
              </>
            ) : (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Box
                  component="img"
                  src="/assets/illustrations/empty_alert.svg"
                  sx={{ height: 420, mx: "auto" }}
                />
                <Typography
                  variant="subtitle2"
                  sx={{ opacity: 0.92, color: "grey" }}
                >
                  It seems like you have missed on potential savings! Start
                  maximizing your shopping experience by setting up your first
                  price alert today.
                </Typography>
              </div>
            )}
          </>
        ) : (
          <>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "flex-start",
              }}
            >
              {[1, 2, 3, 4, 5, 6].map((value) => (
                <div key={"skeleton" + value}>
                  <Skeleton
                    key={"skeleton" + value}
                    animation="wave"
                    variant="rectangular"
                    height={400}
                    style={{
                      width: "320px",
                      margin: "12px",
                      borderRadius: "10px",
                    }}
                  />
                  <Skeleton width="90%" style={{ margin: "12px" }} />
                  <Skeleton width="50%" style={{ margin: "12px" }} />
                </div>
              ))}
            </div>
          </>
        )}
      </Container>
    </>
  );
}
