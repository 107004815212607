import axios from "axios";
import { ORCHESTRATION_API_ENDPOINT } from "../utils/Constants";
import moment from "moment";
import "moment-timezone";
import { generateAuthorizationToken } from "../utils/utilHelper";

moment.tz.setDefault("Asia/Kolkata");

export const sendContactUsMailAPI = async (obj) => {
  try {
    const response = await axios.post(
      `${ORCHESTRATION_API_ENDPOINT}/contactUs`,
      obj,
      {
        withCredentials: true,
        headers: {
          Authorization: generateAuthorizationToken(),
        },
      }
    );
    if (response.status === 200 && response.data) {
      return response.data;
    } else {
      return "Unable to complete the request";
    }
  } catch (error) {
    return error.response.data;
  }
};
