import moment from "moment";
import "moment-timezone";
import {
  CONST_PRODUCT_DETAILS_CACHE_PREFIX,
  CONST_PRODUCT_PRICE_HISTORY_SUFFIX,
  CONST_VALID_ALL_CACHE_TIME_IN_MINUTES,
  CONST_VALID_CACHE_TIME_IN_MINUTES,
  DEALS_HOMEPAGE_KEY,
} from "./Constants";

moment.tz.setDefault("Asia/Kolkata");

export const storeDataWithTimestamp = (key, value) => {
  const currentTime = moment().toLocaleString();
  const data = {
    value,
    timestamp: currentTime,
  };
  localStorage.setItem(key, JSON.stringify(data));
};

export const getDataFromLocalStorage = (key) => {
  const storedData = localStorage.getItem(key);
  if (storedData) {
    const { value, timestamp } = JSON.parse(storedData);
    const parsedTimestamp = moment(
      timestamp,
      "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ"
    );
    const currentTime = moment();
    const timeDifference = moment.duration(currentTime.diff(parsedTimestamp));
    let expiryCacheTime = CONST_VALID_CACHE_TIME_IN_MINUTES;
    if (key === DEALS_HOMEPAGE_KEY) {
      expiryCacheTime = 30;
    }
    if (timeDifference.asMinutes() > expiryCacheTime) {
      localStorage.removeItem(key);
      return null;
    }
    return value;
  }
  return null;
};

export const removeCacheFromLocalStorge = (key) => {
  const storedData = localStorage.getItem(key);
  if (storedData && isValidJSON(storedData)) {
    const { timestamp } = JSON.parse(storedData);
    if (timestamp) {
      const parsedTimestamp = moment(
        timestamp,
        "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ"
      );
      const currentTime = moment();
      const timeDifference = moment.duration(currentTime.diff(parsedTimestamp));
      if (timeDifference.asMinutes() > CONST_VALID_ALL_CACHE_TIME_IN_MINUTES) {
        localStorage.removeItem(key);
      }
    }
  }
};

function isValidJSON(str) {
  try {
    JSON.parse(str);
    return true;
  } catch (e) {
    return false;
  }
}

export const getDetailsForSearchValueFromCache = (search_value) => {
  const cacheDetailsFromSearchValueKey =
    CONST_PRODUCT_DETAILS_CACHE_PREFIX + search_value;
  return getDataFromLocalStorage(cacheDetailsFromSearchValueKey);
};

export const getDetailsForSearchURLFromCache = (search_url) => {
  return getDataFromLocalStorage(search_url);
};

export const getPriceHistoryForSearchURLFromCache = (search_url) => {
  const priceHistoryCacheID =
    search_url + CONST_PRODUCT_PRICE_HISTORY_SUFFIX;
  return getDataFromLocalStorage(priceHistoryCacheID);
};
