import {
  Box,
  Card,
  Link,
  Typography,
  Stack,
  Tooltip,
  Button,
} from "@mui/material";
import { formatToIndianCurrency } from "../../utils/formatNumber";
import { isMobile } from "react-device-detect";
import { formatDate, getTimeAgo } from "../../utils/formattedDate";
import {
  findSearchNavigationURL,
  getTaggedURL,
  handleBuyOnButtonClick,
} from "../../utils/utilHelper";
import { useNavigate } from "react-router-dom";
import {
  Image,
  ImageBackdrop,
  ImageButton,
  ImageMarked,
  ImageSrc,
} from "../../helper/ProductCardHelper";
import Label from "../../customElements/Label";
import { StyledRating } from "../../customElements/StyledRating";

export default function ShopProductCard({ product }) {
  const navigate = useNavigate();
  const {
    title,
    rating,
    previousPrice,
    previousDate,
    latestPrice,
    latestDate,
    image,
    domain,
    url,
    dealType,
  } = product;

  return (
    <div style={{ width: "100%", margin: "auto" }}>
      <Card style={{ margin: "0" }}>
        <Box sx={{ position: "relative" }}>
          {domain && (
            <Label
              variant="filled"
              sx={{
                zIndex: 9,
                top: 10,
                right: 16,
                position: "absolute",
                textTransform: "uppercase",
              }}
            >
              Price Dropped by{" "}
              {formatToIndianCurrency(previousPrice - latestPrice)}
            </Label>
          )}
          <ImageButton
            focusRipple
            style={{
              width: isMobile ? "300px" : "355px",
              margin: "auto",
            }}
          >
            <ImageSrc style={{ backgroundImage: `url(${image})` }} />
            <ImageBackdrop className="MuiImageBackdrop-root" />
            <Image>
              <Typography
                component="span"
                variant="subtitle1"
                color="inherit"
                sx={{
                  position: "relative",
                  p: 4,
                  pt: 2,
                  pb: (theme) => `calc(${theme.spacing(1)} + 6px)`,
                }}
                onClick={() => handleBuyOnButtonClick(url)}
              >
                {domain}
                <ImageMarked className="MuiImageMarked-root" />
              </Typography>
            </Image>
          </ImageButton>
        </Box>

        <Stack spacing={2} sx={{ p: 3 }}>
          <Link
            href={getTaggedURL(url)}
            target="_blank"
            rel="noopener noreferrer"
            color="inherit"
            underline="none"
          >
            <Typography variant="subtitle2" noWrap>
              {title}
            </Typography>
          </Link>

          <Stack
            direction="row"
            alignItems="center"
            justifyContent="left"
            style={{ width: "240px !important" }}
          >
            <Box sx={{ mr: 1, mt: 0.2, color: "#637381" }}>
              {rating && rating.ratingCount
                ? rating.ratingCount.split(" out")[0]
                : "N/A"}
            </Box>
            <StyledRating
              name="half-rating-read"
              defaultValue={
                rating && rating.ratingCount
                  ? Number(rating.ratingCount.split(" out")[0])
                  : null
              }
              color="#FFA41C"
              precision={0.1}
              readOnly
            />
          </Stack>
          {dealType && (
            <Label
              color={
                dealType === "LIGHTNING DEALS"
                  ? "success"
                  : dealType === "HOT DEALS"
                  ? "warning"
                  : "default"
              }
              sx={{
                zIndex: 9,
                textTransform: "uppercase",
              }}
            >
              {dealType}
            </Label>
          )}

          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="subtitle2">
              <Stack
                direction="column"
                alignItems="left"
                justifyContent="space-between"
              >
                <Typography
                  component="span"
                  variant="body2"
                  sx={{
                    color: "text.disabled",
                  }}
                >
                  Previous Price
                </Typography>
                <Typography
                  component="span"
                  variant="body1"
                  sx={{
                    color: "text.disabled",
                    textDecoration: "line-through",
                  }}
                >
                  {previousPrice && formatToIndianCurrency(previousPrice)}
                </Typography>
                <Typography
                  component="span"
                  variant="body1"
                  sx={{
                    fontSize: "12px",
                    color: "text.disabled",
                  }}
                >
                  {previousDate && formatDate(previousDate)}
                </Typography>
              </Stack>
            </Typography>
            <Typography variant="subtitle1">
              <Stack
                direction="column"
                alignItems="right"
                justifyContent="space-between"
              >
                <Typography component="span" variant="body2">
                  Current Price
                </Typography>
                <Typography
                  component="span"
                  sx={{ color: "green" }}
                  style={{ fontWeight: "bolder" }}
                >
                  {latestPrice && formatToIndianCurrency(latestPrice)}
                </Typography>
                <Typography
                  component="span"
                  variant="body1"
                  sx={{
                    fontSize: "12px",
                    color: "text.disabled",
                  }}
                >
                  {latestDate && getTimeAgo(latestDate)}
                </Typography>
              </Stack>
            </Typography>
          </Stack>
        </Stack>
        <Tooltip
          title="Having doubt whether it's worth buying today?"
          disableInteractive
        >
          <Button
            className="doPriceAnalysis"
            variant="text"
            sx={{ width: "100%" }}
            onClick={() => {
              navigate(findSearchNavigationURL(url));
            }}
          >
            Unlock Price Insights
          </Button>
        </Tooltip>
      </Card>
    </div>
  );
}
