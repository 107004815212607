import { profileOptions } from "../../config/profile";
import { Card, Tooltip, Typography, styled } from "@mui/material";
import Iconify from "../../customElements/Iconify";

const ProfileOptionsCard = ({ loggedIn, profileOption, setProfileOption }) => {
  const StyledIcon = styled("div")(({ theme, color }) => ({
    margin: "0",
    display: "flex",
    borderRadius: "25%",
    alignItems: "center",
    backgroundColor: color,
    width: theme.spacing(5),
    height: theme.spacing(5),
    justifyContent: "center",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  }));

  return (
    <>
      {profileOptions.map((item) => (
        <Tooltip
          key={item.id}
          title="Please login to view/edit"
          arrow
          disableHoverListener={
            loggedIn === "true" || loggedIn === true || item.option <= 2
          }
        >
          <div onClick={() => setProfileOption(item.option)}>
            <Card
              className={
                loggedIn === "true" || loggedIn === true
                  ? null
                  : item.option > 2
                  ? "disabledCard"
                  : null
              }
              key={item.id}
              sx={{
                boxShadow: 0,
                display: "flex",
                alignItems: "center",
                width: "280px",
                height: "24px",
                p: 4,
                transition: "background-color 0.3s",
                backgroundColor:
                  profileOption === item.option ? "#F0F2F4" : "white",
                "&:hover": {
                  cursor: "pointer",
                  backgroundColor: "#F0F2F4",
                },
              }}
            >
              <StyledIcon color={item.backgroundColor}>
                <Iconify
                  icon={item.icon}
                  color={item.color}
                  width={20}
                  height={20}
                />
              </StyledIcon>

              <Typography variant="subtitle1" sx={{ opacity: 0.72, ml: 2 }}>
                {item.title}
              </Typography>
            </Card>
          </div>
        </Tooltip>
      ))}
    </>
  );
};

export default ProfileOptionsCard;
