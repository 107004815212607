import SvgColor from "../../customElements/SvgColor";

const icon = (name) => (
  <SvgColor
    src={`/assets/icons/navbar/${name}.svg`}
    sx={{ width: 1, height: 1 }}
  />
);

const commonNavConfig = [
  {
    className: "dashboard",
    title: "dashboard",
    path: "/",
    icon: icon("ic_dashboard"),
  },
  {
    className: "deals",
    title: "deals",
    path: "/deals",
    icon: icon("ic_deals"),
  },
  {
    className: "profile",
    title: "profile",
    path: "/profile",
    icon: icon("ic_user"),
  },
  {
    className: "alerts",
    title: "My Alerts",
    path: "/alerts",
    icon: icon("ic_bell"),
  },
];

export const loginConfig = [
  ...commonNavConfig,
  {
    className: "login",
    title: "login",
    path: "/login",
    icon: icon("ic_lock"),
  },
];

export const logoutConfig = [
  ...commonNavConfig,
  {
    className: "logout",
    title: "logout",
    path: "/",
    icon: icon("ic_disabled"),
  },
];
