import ProfileAvatars from "../components/profile/ProfileAvatars";
import ProfileCovers from "../components/profile/ProfileCovers";
import ProfileInformation from "../components/profile/ProfileInfomation";
import ProfileSecurity from "../components/profile/ProfileSecurity";

export const profileOptions = [
  {
    title: "Your Avatar",
    id: "yourAvatar",
    option: 1,
    icon: "ant-design:smile-filled",
    color: "#103996",
    backgroundColor: "#D1E9FC",
  },
  {
    title: "Your Cover",
    id: "yourCover",
    option: 2,
    icon: "ant-design:box-plot-filled",
    color: "#145ABB",
    backgroundColor: "#D0F2FF",
  },
  {
    title: "Personal Info",
    id: "personalInfo",
    option: 3,
    icon: "ant-design:profile-filled",
    color: "#B78103",
    backgroundColor: "#F4E5AF",
  },
  {
    title: "Security Settings",
    id: "securitySettings",
    option: 4,
    icon: "ant-design:security-scan-filled",
    color: "#B72136",
    backgroundColor: "#F3C7BE",
  },
];

export const getProfileComponent = (option) => {
  const profileCom = [
    <ProfileAvatars />,
    <ProfileCovers />,
    <ProfileInformation />,
    <ProfileSecurity />,
  ];
  return option >= 1 && option <= profileCom.length
    ? profileCom[option - 1]
    : null;
};
