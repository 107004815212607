import { useAppContext } from "../../store/AppContextProvider";
import { Divider, Skeleton } from "@mui/material";
const HomePageLoading = ({ view }) => {
  const { isMobile } = useAppContext();

  return (
    <>
      <Divider />
      <div
        style={{
          width: "100%",
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
        }}
      >
        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((value) => (
          <div key={"skeleton" + value}>
            <Skeleton
              key={"skeleton" + value}
              animation="wave"
              variant="rectangular"
              height={300}
              style={{
                width:
                  view === "carousel"
                    ? isMobile
                      ? "300px"
                      : "240px"
                    : isMobile
                    ? "300px"
                    : "280px",
                margin: "12px",
                borderRadius: "10px",
              }}
            />
            <Skeleton width="90%" style={{ margin: "12px" }} />
            <Skeleton width="50%" style={{ margin: "12px" }} />
          </div>
        ))}
      </div>
    </>
  );
};

export default HomePageLoading;
