import { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Typography,
  Input,
  InputAdornment,
  Link,
  Button,
} from "@mui/material";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import { useNavigate } from "react-router-dom";
import { useAppContext } from "../../store/AppContextProvider";
import { PrettoSlider } from "../../helper/SearchPageHelper";
import { getPriceAlertAPI } from "../../APIs/getPriceAlertAPI";
import { addPriceAlertAPI } from "../../APIs/addPriceAlert";
import { fetchDetailsFromSearchURL_API } from "../../APIs/fetchDetailsFromSearchURLAPI";

const AlertOpenViewComponent = ({
  discountPrice,
  openAddAlertView,
  setOpenAddAlertView,
  setIsPriceAlertPresent,
  setSuccessMessage,
  setErrorMessage,
  url,
}) => {
  const navigate = useNavigate();
  const { loggedIn, setAlertCount } = useAppContext();
  const [originalAlertPrice, setOriginalAlertPrice] = useState(0);
  const [alertPrice, setAlertPrice] = useState(0);

  useEffect(() => {
    async function fetchAlert() {
      if (loggedIn) {
        updateAlert(await getPriceAlertAPI());
      }
    }
    fetchAlert();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedIn]);

  useEffect(() => {
    if (alertPrice === 0 && discountPrice !== 0) {
      setAlertPrice(parseInt(discountPrice - discountPrice * 0.1));
    }
  }, [alertPrice, discountPrice]);

  const updateAlert = async (response) => {
    setAlertCount(Number(response.count));
    if (response && response.alerts != null && response.alerts.length > 0) {
      let prod_response = await fetchDetailsFromSearchURL_API(url);
      response.alerts.forEach((element) => {
        if (element.productId === prod_response.data.productId) {
          setAlertPrice(Number(element.alertPrice));
          setOriginalAlertPrice(Number(element.alertPrice));
          setOpenAddAlertView(true);
          setIsPriceAlertPresent(true);
        }
      });
    }
  };

  const handleInputChange = (event) => {
    setAlertPrice(
      event.target.value === "" || Number(event.target.value) <= 0
        ? discountPrice - 1
        : Number(event.target.value) >= discountPrice
        ? discountPrice - 1
        : Number(event.target.value)
    );
  };

  const addPriceAlert = async () => {
    if (loggedIn) {
      if (alertPrice >= 1 && alertPrice < discountPrice) {
        const obj = {
          url: url,
          alertPrice: alertPrice,
          isPriceAlert: true,
          isStockAlert: false,
        };

        const response = await addPriceAlertAPI(obj);
        setSuccessMessage("Updated");
        updateAlert(response);
      }
    } else {
      setErrorMessage("Login to add alert");
    }
  };

  const handleBlur = () => {
    if (alertPrice <= 0) {
      setAlertPrice(1);
    } else if (alertPrice >= Number(discountPrice)) {
      setAlertPrice(Number(discountPrice - 1));
    }
  };

  const handleLoginRedirect = () => {
    const url = new URL(window.location.href);
    const pathAndQuery = url.pathname + url.search;
    localStorage.setItem("lastUrl", pathAndQuery);
    navigate("/login");
  };

  const handleAlertChange = (newValue) => {
    setAlertPrice(newValue);
  };

  return (
    <Box
      sx={{ width: "100%", margin: "auto", marginTop: "50px" }}
      display={openAddAlertView ? "block" : "none"}
    >
      {!loggedIn ? (
        <Typography>
          {
            <Link onClick={handleLoginRedirect}>
              <Typography variant="subtitle2">Login</Typography>
            </Link>
          }
          to add price alert
        </Typography>
      ) : null}
      {discountPrice !== 0 ? (
        <Grid container spacing={1} alignItems="center">
          <Grid item xs={6} sx={{ p: 0 }}>
            <PrettoSlider
              min={1}
              max={Number(discountPrice) - 1}
              disabled={!loggedIn}
              valueLabelDisplay="auto"
              color="secondary"
              value={Number(alertPrice)}
              onChange={(_event, newValue) => handleAlertChange(newValue)}
              aria-labelledby="input-slider"
            />
          </Grid>
          <Grid item sx={2}>
            <Input
              value={Number(alertPrice)}
              size="large"
              disabled={!loggedIn}
              onChange={handleInputChange}
              onBlur={handleBlur}
              startAdornment={
                <InputAdornment position="start">
                  <CurrencyRupeeIcon />
                </InputAdornment>
              }
              style={{ width: "100px" }}
              inputProps={{
                min: 1,
                max: Number(discountPrice) - 1,
                type: "number",
                "aria-labelledby": "input-slider",
              }}
            />
          </Grid>
          <Grid item sx={3}>
            {originalAlertPrice !== alertPrice && (
              <Button
                variant="outlined"
                color="success"
                onClick={addPriceAlert}
              >
                Save
              </Button>
            )}
          </Grid>
        </Grid>
      ) : (
        <Typography variant="subtitle2">
          Product is out of stock!!! Please wait till product is back in stock
          to set price alert
        </Typography>
      )}
    </Box>
  );
};

export default AlertOpenViewComponent;
