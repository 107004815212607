import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import Router from "./routes";
import ThemeProvider from "./theme";
import FooterComponent from "./components/footer/FooterComponent";
import ArrowScrollToTop from "./components/scroll-to-top/ArrowScrollToTop";
import ScrollToTop from "./components/scroll-to-top/ScrollToTop";

export default function App() {
  return (
    <HelmetProvider>
      <BrowserRouter>
        <ThemeProvider>
          <ScrollToTop />
          <Router />
          <ArrowScrollToTop />
          <FooterComponent />
        </ThemeProvider>
      </BrowserRouter>
    </HelmetProvider>
  );
}
