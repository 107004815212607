import _, { filter } from "lodash";
import { useState } from "react";
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  TableContainer,
  TablePagination,
  Tooltip,
  Button,
} from "@mui/material";
import Scrollbar from "../scrollbar/Scrollbar";
import Label from "../../customElements/Label";
import { formatToIndianCurrency } from "../../utils/formatNumber";
import { formatDate } from "../../utils/formattedDate";
import {
  findSearchNavigationURL,
  getProductList,
  handleBuyOnButtonClick,
} from "../../utils/utilHelper";
import { useNavigate } from "react-router-dom";
import { UserListHead, UserListToolbar } from "../user";
import "./homepage.scss";
import { StyledRating } from "../../customElements/StyledRating";

const TABLE_HEAD = [
  { id: "title", label: "Name", alignRight: false },
  { id: "priceDrop", label: "Price Dropped By", alignRight: false },
  { id: "previousPrice", label: "Previous Price", alignRight: false },
  { id: "latestPrice", label: "Current Price", alignRight: false },
  { id: "domain", label: "Store", alignRight: false },
  { id: "dealType", label: "Deal Type", alignRight: false },
  { id: "rating", label: "Rating", alignRight: false },
  { id: "" },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query, orderBy) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  if (orderBy !== "rating") {
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
  }
  if (query) {
    return filter(
      array,
      (_user) => _user.title.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function HomePageProductTable({ deals }) {
  const navigate = useNavigate();
  const immutableCopy = _.cloneDeep(deals);
  let products = getProductList(immutableCopy);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState("desc");

  const [orderBy, setOrderBy] = useState("priceDrop");

  const [filterName, setFilterName] = useState("");

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleRequestSort = (event, property) => {
    if (property !== "rating") {
      const isAsc = order === "asc";
      setOrder(isAsc ? "desc" : "asc");
      setOrderBy(property);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - products.length) : 0;

  const filteredProducts = applySortFilter(
    products,
    getComparator(order, orderBy),
    filterName
  );

  const isNotFound = !filteredProducts.length && !!filterName;

  return (
    <>
      <Card>
        <UserListToolbar
          filterName={filterName}
          onFilterName={handleFilterByName}
        />

        <Scrollbar>
          <TableContainer>
            <Table>
              <UserListHead
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                onRequestSort={handleRequestSort}
              />
              <TableBody>
                {filteredProducts
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => {
                    const {
                      title,
                      domain,
                      url,
                      image,
                      latestDate,
                      latestPrice,
                      previousDate,
                      previousPrice,
                      rating,
                      dealType,
                      priceDrop
                    } = row;

                    return (
                      <TableRow hover key={url} tabIndex={-1}>
                        <TableCell
                          component="th"
                          scope="row"
                          padding="none"
                          style={{ cursor: "pointer" }}
                          onClick={() => handleBuyOnButtonClick(url)}
                        >
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={2}
                            style={{ paddingLeft: "10px" }}
                          >
                            <Avatar alt={title} src={image} />
                            <Typography
                              variant="subtitle2"
                              sx={{ width: "180px" }}
                              noWrap
                            >
                              {title}
                            </Typography>
                          </Stack>
                        </TableCell>
                        <TableCell align="left">
                          <Typography component="span" variant="subtitle1">
                            {formatToIndianCurrency(priceDrop)}
                          </Typography>
                        </TableCell>
                        <TableCell align="left">
                          <Stack
                            direction="column"
                            alignItems="left"
                            spacing={1}
                          >
                            <Typography
                              component="span"
                              variant="subtitle2"
                              sx={{ color: "text.disabled" }}
                            >
                              {previousPrice &&
                                formatToIndianCurrency(previousPrice)}
                            </Typography>
                            <Typography
                              component="span"
                              variant="body1"
                              sx={{
                                fontSize: "12px",
                                color: "text.disabled",
                              }}
                            >
                              {previousDate && formatDate(previousDate)}
                            </Typography>
                          </Stack>
                        </TableCell>
                        <TableCell align="left">
                          <Stack
                            direction="column"
                            alignItems="left"
                            spacing={1}
                          >
                            <Typography
                              component="span"
                              variant="subtitle2"
                              sx={{}}
                            >
                              {previousPrice &&
                                formatToIndianCurrency(latestPrice)}
                            </Typography>
                            <Typography
                              component="span"
                              variant="body1"
                              sx={{
                                fontSize: "12px",
                                color: "text.disabled",
                              }}
                            >
                              {previousDate && formatDate(latestDate)}
                            </Typography>
                          </Stack>
                        </TableCell>
                        <TableCell align="left">
                          <Label
                            color={domain === "AMAZON" ? "warning" : "info"}
                          >
                            {domain}
                          </Label>
                        </TableCell>
                        <TableCell align="left">
                          <Typography
                            className={
                              dealType === "Lightning Deal"
                                ? "lightningDeal"
                                : dealType === "Hot Deal"
                                ? "hotDeal"
                                : ""
                            }
                          >
                            {dealType}
                          </Typography>
                        </TableCell>
                        <TableCell align="left">
                          <Stack direction="column">
                            <StyledRating
                              name="half-rating-read"
                              defaultValue={
                                rating && rating.ratingCount
                                  ? Number(rating.ratingCount.split(" out")[0])
                                  : null
                              }
                              precision={0.1}
                              readOnly
                            />
                            <Typography
                              component="span"
                              variant="body1"
                              sx={{
                                fontSize: "12px",
                                color: "text.disabled",
                                ml: 0.5,
                              }}
                            >
                              {rating && rating.totalRated
                                ? rating.totalRated
                                : "N/A"}
                            </Typography>
                          </Stack>
                        </TableCell>
                        <TableCell>
                          <Tooltip
                            title="Having doubt whether it's worth buying today?"
                            disableInteractive
                          >
                            <Button
                              variant="contained"
                              sx={{ fontSize: "10px" }}
                              onClick={() => {
                                navigate(findSearchNavigationURL(url));
                              }}
                            >
                              Do Price Analysis
                            </Button>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>

              {isNotFound && (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                      <Paper
                        sx={{
                          textAlign: "center",
                        }}
                      >
                        <Typography variant="h6" paragraph>
                          Not found
                        </Typography>

                        <Typography variant="body2">
                          No results found for &nbsp;
                          <strong>&quot;{filterName}&quot;</strong>.
                          <br /> Try checking for typos or using complete words.
                        </Typography>
                      </Paper>
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Scrollbar>

        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={products.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Card>
    </>
  );
}
