import { Avatar, IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useAppContext } from "../../store/AppContextProvider";
import account from "../../config/account";

export default function AccountPopover() {
  const { userInfo } = useAppContext();
  const navigate = useNavigate();

  return (
    <>
      <IconButton
        className="profile_top"
        onClick={() => navigate("/profile")}
        sx={{
          p: 0,
        }}
      >
        <Avatar
          src={
            userInfo && userInfo.profilePictureUrl
              ? `/assets/images/avatars/${userInfo.profilePictureUrl}.jpg`
              : account.photoURL
          }
          style={{ width: "34px", height: "34px" }}
          alt="photoURL"
        />
      </IconButton>
    </>
  );
}
