import { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";

import { Autoplay, FreeMode, Navigation, Thumbs } from "swiper/modules";
const SearchURLProductDetailsImageCarousel = ({ subImages }) => {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  return (
    <>
      <Swiper
        style={{
          "--swiper-navigation-color": "#E83D95",
          "--swiper-pagination-color": "#007AFF",
        }}
        spaceBetween={10}
        navigation={true}
        thumbs={{ swiper: thumbsSwiper }}
        autoplay={{
          delay: 2500,
          disableOnInteraction: true,
        }}
        modules={[Autoplay, FreeMode, Navigation, Thumbs]}
        className="mySwiper"
      >
        {subImages.map((images) => (
          <SwiperSlide key={images + "_img"}>
            <img src={images} alt="subImages" />
          </SwiperSlide>
        ))}
      </Swiper>
      <div style={{ margin: "12px 0" }}></div>
      <Swiper
        onSwiper={setThumbsSwiper}
        spaceBetween={15}
        slidesPerView={4}
        freeMode={true}
        watchSlidesProgress={true}
        modules={[FreeMode, Navigation, Thumbs]}
        className="mySwiper"
      >
        {subImages.map((images) => (
          <SwiperSlide key={images + "_img_thumb"}>
            <img src={images} alt="subImages" />
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
};

export default SearchURLProductDetailsImageCarousel;
