import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { Box, Link, Drawer, Typography, Avatar } from "@mui/material";
import useResponsive from "../../hooks/useResponsive";
import Scrollbar from "../../components/scrollbar";
import { useAppContext } from "../../store/AppContextProvider";
import { loginConfig, logoutConfig } from "./config";
import { NAV_WIDTH } from "../../utils/Constants";
import Logo from "../../customElements/Logo";
import account from "../../config/account";
import NavSection from "../header/NavSection";

// ----------------------------------------------------------------------

const StyledAccount = styled("div")(({ theme, coverUrl }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundImage: `url(/assets/images/covers/${coverUrl}.jpg)`,
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
}));

export default function Nav({ openNav, onCloseNav }) {
  const { pathname } = useLocation();
  const { loggedIn, userInfo, setLoggedIn } = useAppContext();

  const isDesktop = useResponsive("up", "lg");

  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        "& .simplebar-content": {
          height: 1,
          display: "flex",
          flexDirection: "column",
        },
      }}
    >
      <Box sx={{ px: 3, py: 4, display: "inline-flex" }}>
        <Logo />
      </Box>

      <Box sx={{ mt: 2, mb: 5, mx: 2.5 }}>
        <Link href={"/profile"} underline="none">
          <StyledAccount
            coverUrl={
              userInfo && userInfo.profileCoverUrl
                ? userInfo.profileCoverUrl
                : "cover_default"
            }
          >
            <Avatar
              src={
                userInfo && userInfo.profilePictureUrl
                  ? `/assets/images/avatars/${userInfo.profilePictureUrl}.jpg`
                  : account.photoURL
              }
              alt="photoURL"
            />

            <Box sx={{ ml: 2 }}>
              <Typography variant="subtitle2" sx={{ color: "text.primary" }}>
                {userInfo && userInfo.firstName
                  ? userInfo.firstName + " " + userInfo.lastName
                  : account.displayName}
              </Typography>
            </Box>
          </StyledAccount>
        </Link>
      </Box>

      <NavSection
        data={loggedIn ? logoutConfig : loginConfig}
        setLoggedIn={setLoggedIn}
      />

      <Box sx={{ flexGrow: 1 }} />
    </Scrollbar>
  );

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV_WIDTH },
      }}
    >
      {isDesktop ? (
        <Drawer
          open
          variant="permanent"
          PaperProps={{
            sx: {
              width: NAV_WIDTH,
              bgcolor: "background.default",
              borderRightStyle: "dashed",
            },
          }}
        >
          {renderContent}
        </Drawer>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          ModalProps={{
            keepMounted: true,
          }}
          PaperProps={{
            sx: { width: NAV_WIDTH },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </Box>
  );
}
