import axios from "axios";
import { ORCHESTRATION_API_ENDPOINT } from "../utils/Constants";
import { DealsHomePageAPIResponse } from "../schema/CommonSchema";
import { ErrorMessages } from "../helper/ErrorMessageEnum";
import { DealsResponseSchema } from "../schema/DealsSchema";
import { validateDealsResponse } from "../utils/utilHelper";

export const fetchHomePageDeals_API =
  async (): Promise<DealsHomePageAPIResponse> => {
    let dealHomePageResponse: DealsHomePageAPIResponse = {
      data: null,
      error: null,
    };
    let data: DealsResponseSchema | null = null,
      error: string | null = null;
    try {
      const response = await axios.get(
        `${ORCHESTRATION_API_ENDPOINT}/getDeals`
      );
      if (response.status === 200) {
        let dealsResponse: DealsResponseSchema = response.data;
        if (validateDealsResponse(dealsResponse)) {
          data = dealsResponse;
        } else {
          throw new Error(ErrorMessages.SOMETHING_WENT_WRONG);
        }
      } else {
        throw new Error(ErrorMessages.SOMETHING_WENT_WRONG);
      }
    } catch (e: any) {
      if (!e) {
        error = ErrorMessages.SOMETHING_WENT_WRONG;
      } else {
        error = e.message;
      }
    }
    dealHomePageResponse.data = data;
    dealHomePageResponse.error = error;
    return dealHomePageResponse;
  };
