import {
  Box,
  Button,
  CircularProgress,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { validateToken } from "../APIs/validateToken";
import Logo from "../customElements/Logo";

const ValidateSignUp = () => {
  const location = useLocation();
  const [error, setError] = useState("");
  const [isVerified, setisVerified] = useState("");
  const navigate = useNavigate();
  useEffect(() => {
    const verifyToken = async () => {
      let params = new URLSearchParams(location.search);
      if (params && params.get("verify")) {
        let token = params.get("verify");
        if (token && token.length >= 10) {
          const response = await validateToken(token);
          if (response === true || response === false) {
            setisVerified(response);
          } else {
            setError(response);
          }
        } else {
          setisVerified(false);
        }
      } else {
        setisVerified(false);
      }
    };
    verifyToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <Box sx={{ px: 3, py: 4, display: "inline-flex" }}>
        <Logo />
      </Box>
      <div
        style={{
          width: "100%",
          height: "80%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {isVerified === false ? (
          <Stack
            component="span"
            direction="column"
            alignItems="center"
            justifyContent="center"
          >
            <img
              src="/assets/signup/signup_failed.svg"
              alt="success"
              width={200}
              height={200}
            />
            <Typography variant="h3" sx={{ mb: 2 }}>
              Verification failed
            </Typography>
            <Button
              variant="outlined"
              onClick={() => navigate("/login", { replace: true })}
              color="error"
            >
              SignUp again
            </Button>
          </Stack>
        ) : (
          <>
            {isVerified === true ? (
              <Stack
                component="span"
                direction="column"
                alignItems="center"
                justifyContent="center"
              >
                <img
                  src="/assets/signup/signup_success.svg"
                  alt="success"
                  width={200}
                  height={200}
                />
                <Typography variant="h3">Thank you</Typography>
                <Typography variant="subtitle1" sx={{ mb: 4 }}>
                  Your account has been verified
                </Typography>
                <Button onClick={() => navigate("/login", { replace: true })}>
                  Login
                </Button>
              </Stack>
            ) : (
              <>
                {error.trim().length === 0 ? (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <CircularProgress />
                    <Typography variant="subtitle1" sx={{ mt: 4 }}>
                      Verification in progress...
                    </Typography>
                  </div>
                ) : (
                  <Stack
                    component="span"
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <img
                      src="/assets/signup/signup_error.svg"
                      alt="info"
                      width={200}
                      height={150}
                    />
                    <Typography variant="subtitle1" sx={{ my: 4 }}>
                      {error}
                    </Typography>
                    <Button
                      onClick={() => navigate("/login", { replace: true })}
                    >
                      Login
                    </Button>
                  </Stack>
                )}
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default ValidateSignUp;
