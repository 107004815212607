import { useEffect, useState } from "react";
import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";
import "./scrollToTop.scss";

const ArrowScrollToTop = () => {
  useEffect(() => {
    if (typeof window != "undefined") {
      window.onscroll = function () {
        scrollFun();
      };
    }
  }, []);
  function scrollFun() {
    if (
      document.body.scrollTop > 100 ||
      document.documentElement.scrollTop > 100
    ) {
      setshowup(true);
    } else {
      setshowup(false);
    }
  }
  function scrollTopFun() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }
  const [showup, setshowup] = useState(false);
  return (
    <div>
      {showup && (
        <div className="scrollup-div" onClick={scrollTopFun}>
          <ArrowCircleUpIcon className="up-arrow-square" />
        </div>
      )}
    </div>
  );
};

export default ArrowScrollToTop;
