import CarouselViewHomePage from "./CarouselViewHomePage";
import ExpandedViewHomePage from "./ExpandedViewHomePage";
import HomePageProductTable from "./HomePageProductTable";

const HomePageView = ({ view, deals }) => {
  return (
    <>
      {view === "carousel" ? (
        <CarouselViewHomePage deals={deals} />
      ) : (
        <>
          {view === "expanded" ? (
            <ExpandedViewHomePage deals={deals} />
          ) : (
            <>
              <HomePageProductTable deals={deals} />
            </>
          )}
        </>
      )}
    </>
  );
};

export default HomePageView;
