import { useEffect, useState } from "react";
import { Container, Grid, Stack, Typography } from "@mui/material";
import { Helmet } from "react-helmet-async";
import SearchFilterSidebar from "./SearchFilterSidebar";
import SearchSort from "./SearchSort";
import { handleSearchValueProductListFilterAndSort } from "../../action/SearchPageAction";
import SearchProductListCard from "./SearchProductListCard";
import { isValidSearchURL } from "../../utils/utilHelper";
// ----------------------------------------------------------------------

export const SearchProductListComponent = ({ searchValueProductList }) => {
  const defaultSort = "popularity";
  const [modifiedSearchValueProductList, setModifiedSearchValueProductList] =
    useState([]);
  const [openFilter, setOpenFilter] = useState(false);
  const [productListSort, setProductListSort] = useState(defaultSort);
  const [productListFilter, setProductListFilter] = useState({
    store: "",
    discountPrice: "",
    rating: "",
  });

  useEffect(() => {
    if (searchValueProductList && searchValueProductList.length > 0) {
      let tempSearchValueProductListData = JSON.parse(
        JSON.stringify(searchValueProductList)
      );
      tempSearchValueProductListData =
        handleSearchValueProductListFilterAndSort(
          tempSearchValueProductListData,
          productListFilter,
          productListSort
        );
      setModifiedSearchValueProductList([...tempSearchValueProductListData]);
    }
  }, [productListSort, productListFilter, searchValueProductList]);

  useEffect(() => {
    if (
      modifiedSearchValueProductList &&
      modifiedSearchValueProductList.length > 0
    ) {
      setTimeout(() => {
        const lastVisitedURL = localStorage.getItem("lastVisitedURL");
        if (lastVisitedURL && isValidSearchURL(lastVisitedURL)) {
          const targetElement = document.getElementById(lastVisitedURL);
          if (targetElement) {
            targetElement.scrollIntoView({ behavior: "instant" });
          }
        }
      }, 1);
    }
  }, [modifiedSearchValueProductList]);

  return (
    <>
      <Helmet>
        <title> Dashboard: Search | TrackMYPrice</title>
      </Helmet>

      <Container>
        <Typography variant="h4" sx={{ mb: 5 }}>
          Showing {modifiedSearchValueProductList.length} out of{" "}
          {searchValueProductList.length} search results
        </Typography>
        <Stack
          direction="row"
          flexWrap="wrap-reverse"
          alignItems="center"
          justifyContent="flex-end"
          sx={{ mb: 5 }}
        >
          <Stack direction="row" spacing={1} flexShrink={0} sx={{ my: 1 }}>
            <SearchFilterSidebar
              openFilter={openFilter}
              onOpenFilter={() => setOpenFilter(true)}
              onCloseFilter={() => setOpenFilter(false)}
              filterValue={productListFilter}
              setFilterValue={setProductListFilter}
            />
            <SearchSort
              sortValue={productListSort}
              setSortValue={setProductListSort}
            />
          </Stack>
        </Stack>
        <Grid container spacing={3} style={{ padding: "6px" }}>
          {modifiedSearchValueProductList.map((product) => (
            <Grid
              item
              key={`search_${product.url}`}
              xs={12}
              sm={6}
              md={4}
              style={{ textAlign: "center" }}
            >
              <div id={`${product.url}`}>
                <SearchProductListCard product={product} />
              </div>
            </Grid>
          ))}
        </Grid>
      </Container>
    </>
  );
};
