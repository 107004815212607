import { useNavigate } from "react-router-dom";
import { Box, Card, Typography, Stack, Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import {
  calculateDiscountPercentage,
  formatToIndianCurrency,
} from "../../utils/formatNumber";
import { findSearchNavigationURL } from "../../utils/utilHelper";
import Label from "../../customElements/Label";

const StyledProductImg = styled("img")({
  top: 40,
  left: 40,
  width: "80%",
  height: "80%",
  objectFit: "contain",
  position: "absolute",
  cursor: "pointer",
});

export default function SearchProductListCard({ product }) {
  const navigate = useNavigate();
  const { title, originalPrice, discountPrice, image, domain, url, rating } =
    product;

  return (
    <Card style={{ margin: "4px 0" }}>
      <Box sx={{ pt: "100%", position: "relative" }}>
        {domain && (
          <Label
            variant="filled"
            color={domain === "AMAZON" ? "error" : "warning"}
            sx={{
              zIndex: 9,
              top: 16,
              right: 16,
              position: "absolute",
              textTransform: "uppercase",
            }}
          >
            {domain}
          </Label>
        )}
        <StyledProductImg
          alt={title}
          src={image}
          onClick={() => {
            navigate(findSearchNavigationURL(url));
          }}
        />
      </Box>

      <Stack spacing={2} sx={{ p: 3 }}>
        <div
          style={{
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          <Typography variant="subtitle2" noWrap>
            {title}
          </Typography>
        </div>
        <Label
          color={
            rating
              ? Number(rating.split(" out")[0]) > 4.2
                ? "success"
                : Number(rating.split(" out")[0]) >= 4
                ? "info"
                : Number(rating.split(" out")[0]) >= 3.8
                ? "primary"
                : Number(rating.split(" out")[0]) >= 3.5
                ? "secondary"
                : Number(rating.split(" out")[0]) >= 3.0
                ? "warning"
                : "error"
              : "default"
          }
          sx={{
            zIndex: 9,
            width: "fit-content",
          }}
        >
          {rating ? rating : "No Rating Available"}
        </Label>
        {discountPrice && originalPrice ? (
          <Box sx={{ display: "flex", alignItems: "flex-end" }}>
            <Typography variant="h4">
              {formatToIndianCurrency(discountPrice)}
            </Typography>
            <Typography
              variant="h6"
              sx={{
                pl: 1,
                mb: 0.2,
                color: "text.disabled",
                textDecoration: "line-through",
              }}
            >
              {formatToIndianCurrency(originalPrice)}
            </Typography>
            <Typography
              variant="subtitle1"
              color="#2F903B"
              sx={{
                pl: 1,
                mb: 0.2,
              }}
            >
              {calculateDiscountPercentage(originalPrice, discountPrice)}
            </Typography>
          </Box>
        ) : (
          <Typography
            variant="h6"
            color="#dbdbdb"
            sx={{
              pl: 1,
              mb: 0.2,
            }}
          >
            Out Of Stock
          </Typography>
        )}
      </Stack>
      <Button
        variant="text"
        sx={{ width: "100%" }}
        onClick={() => {
          navigate(findSearchNavigationURL(url));
        }}
      >
        View Details/Price Analysis
      </Button>
    </Card>
  );
}
