import { useState, useEffect } from "react";
import {
  Button,
  TextField,
  FormControl,
  FormLabel,
  Grid,
  Select,
  MenuItem,
  ListItemIcon,
  Typography,
  InputAdornment,
  Box,
  Autocomplete,
  Alert,
} from "@mui/material";
import { Male, Female, Person, Save, Cancel, Email } from "@mui/icons-material";
import "./profile.css";
import { countries } from "../../utils/countries";
import { useAppContext } from "../../store/AppContextProvider";
import { LoadingButton } from "@mui/lab";
import { updateUserInfoAPI } from "../../APIs/updateUserInfoAPI";

const ProfileInformation = () => {
  const { userInfo, setUserInfo } = useAppContext();
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState(null);
  const [country, setCountry] = useState("");

  const minAcceptedDate = new Date();
  minAcceptedDate.setFullYear(minAcceptedDate.getFullYear() - 12);

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    gender: "",
    dateOfBirth: "",
    phoneNumber: "",
    profilePictureUrl: "",
    profileCoverUrl: "",
    country: "",
  });

  useEffect(() => {
    if (userInfo && Object.keys(userInfo).length >= 6) {
      setFormData({
        firstName: userInfo.firstName,
        lastName: userInfo.lastName,
        gender: userInfo.gender,
        dateOfBirth: userInfo.dateOfBirth,
        profilePictureUrl: userInfo.profilePictureUrl,
        profileCoverUrl: userInfo.profileCoverUrl,
        country: userInfo.country,
      });
      setCountry(userInfo.country);
      setEmail(userInfo.email);
    }
  }, [userInfo]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      isValidName(formData.firstName) &&
      isValidName(formData.lastName) &&
      isSingUpDateValid(formData.dateOfBirth) &&
      formData.profileCoverUrl &&
      formData.profilePictureUrl
    ) {
      setError("");
      setIsLoading(true);
      formData.country = country;
      const response = await updateUserInfoAPI(formData);
      if (response && Object.keys(response).length >= 6) {
        setUserInfo(response);
      } else {
        setError(response);
      }
    } else {
      setError("Invalid Input");
    }
    setIsLoading(false);
  };

  const isValidName = (name) => {
    const nameRegex = /^[A-Za-z]{2,}$/;
    return nameRegex.test(name);
  };

  const isSingUpDateValid = (selectedDate) => {
    const currentDate = new Date();
    const selectedDateObj = new Date(selectedDate);

    const twelveYearsAgo = new Date();
    twelveYearsAgo.setFullYear(currentDate.getFullYear() - 12);
    twelveYearsAgo.setHours(0, 0, 0, 0);

    return selectedDateObj.getTime() <= twelveYearsAgo.getTime();
  };

  const isDateValid = (selectedDate) => {
    const selectedDateObj = new Date(selectedDate);
    return selectedDateObj <= minAcceptedDate;
  };

  const handleCancel = () => {
    setFormData({
      firstName: userInfo.firstName,
      lastName: userInfo.lastName,
      gender: userInfo.gender,
      dateOfBirth: userInfo.dateOfBirth,
      profilePictureUrl: userInfo.profilePictureUrl,
      profileCoverUrl: userInfo.profileCoverUrl,
      country: userInfo.country,
    });
    setCountry(userInfo.country);
    setEmail(userInfo.email);
  };

  return (
    <div>
      {email ? (
        <form onSubmit={handleSubmit} style={{ padding: 24 }}>
          <Typography variant="h5" sx={{ px: 0, mb: 4 }}>
            Personal Info
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                name="firstName"
                label="First Name"
                value={formData.firstName}
                onChange={handleChange}
                error={
                  formData.firstName !== ""
                    ? !isValidName(formData.firstName)
                    : false
                }
                helperText={
                  formData.firstName !== "" && !isValidName(formData.firstName)
                    ? "Name must contain atleast 2 character"
                    : ""
                }
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                name="lastName"
                label="Last Name"
                value={formData.lastName}
                onChange={handleChange}
                error={
                  formData.lastName !== ""
                    ? !isValidName(formData.lastName)
                    : false
                }
                helperText={
                  formData.lastName !== "" && !isValidName(formData.lastName)
                    ? "Name must contain atleast 2 character"
                    : ""
                }
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={8} sx={{ my: 3 }}>
              <TextField
                name="dateOfBirth"
                label="Date Of Birth"
                type="date"
                value={formData.dateOfBirth}
                onChange={handleChange}
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  max: minAcceptedDate.toISOString().split("T")[0],
                }}
                error={
                  formData.dateOfBirth
                    ? !isDateValid(formData.dateOfBirth)
                    : false
                }
                helperText={
                  formData.dateOfBirth && !isDateValid(formData.dateOfBirth)
                    ? "Age must be greater than 12 years."
                    : ""
                }
              />
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <FormLabel component="legend">Gender</FormLabel>
                <Select
                  name="gender"
                  value={formData.gender}
                  onChange={handleChange}
                  variant="standard"
                  label="Gender"
                >
                  <MenuItem value="Male">
                    <ListItemIcon>
                      <Male />
                    </ListItemIcon>
                    Male
                  </MenuItem>
                  <MenuItem value="Female">
                    <ListItemIcon>
                      <Female />
                    </ListItemIcon>
                    Female
                  </MenuItem>
                  <MenuItem value="Other">
                    <ListItemIcon>
                      <Person />
                    </ListItemIcon>
                    Other
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6} sx={{ marginTop: "14px" }}>
              <FormControl fullWidth>
                <Autocomplete
                  variant="standard"
                  id="country-select-demo"
                  options={countries}
                  autoHighlight
                  getOptionLabel={(option) => option.label}
                  inputValue={country}
                  onInputChange={(e, value) => {
                    if (e) {
                      setCountry(value);
                    }
                  }}
                  renderOption={(props, option) => (
                    <Box
                      component="li"
                      sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                      {...props}
                    >
                      <img
                        loading="lazy"
                        width="20"
                        src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                        srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                        alt=""
                      />
                      {option.label} ({option.code}) +{option.phone}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      required
                      variant="standard"
                      {...params}
                      label="Choose a country"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "country", // disable autocomplete and autofill
                      }}
                    />
                  )}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Typography variant="h5" sx={{ px: 0, mt: 6, mb: 4 }}>
            Contact Info
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                name="email"
                label="Email"
                value={email}
                fullWidth
                disabled
                required
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Email />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
          {error && error.length > 0 && <Alert severity="error">{error}</Alert>}
          <Grid container spacing={2} sx={{ pt: 6 }}>
            <Grid item xs={6}>
              <Button
                type="button"
                variant="outlined"
                color="secondary"
                onClick={handleCancel}
                fullWidth
                sx={{ p: 1.25 }}
                startIcon={<Cancel />}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item xs={6}>
              <LoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                startIcon={<Save />}
                loading={isLoading}
                loadingIndicator="Updating..."
              >
                Update
              </LoadingButton>
            </Grid>
          </Grid>
        </form>
      ) : (
        <div
          style={{
            width: "100%",
            marginLeft: "120px",
          }}
        >
          <Typography variant="h4">Loading...</Typography>
        </div>
      )}
    </div>
  );
};

export default ProfileInformation;
