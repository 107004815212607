import { useEffect, useState } from "react";
import { fetchHomePageDeals } from "../action/HomePageAction";
import ExpandedViewHomePage from "../components/homepage/ExpandedViewHomePage";
import { Helmet } from "react-helmet-async";
import HomePageLoading from "../components/homepage/HomePageLoading";
import SnackBarError from "../customElements/SnackBar/SnackBarError";

export default function DealsPage() {
  const [deals, setDeals] = useState({});
  const [isloading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    fetchHomePageDeals(setIsLoading, setDeals, setErrorMessage);
  }, []);

  return (
    <>
      <Helmet>
        <title> TrackMYPrice | Deals </title>
      </Helmet>
      <SnackBarError
        errorMessage={errorMessage}
        setErrorMessage={setErrorMessage}
      />
      {isloading ? (
        <HomePageLoading view={"expanded"} />
      ) : (
        <ExpandedViewHomePage deals={deals} dealsPerPage={25} />
      )}
    </>
  );
}
