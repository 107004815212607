import { Snackbar, Alert } from "@mui/material";

import {
  CONST_SNACK_BAR_VERTICAL_ALIGNMENT,
  CONST_SNACK_BAR_HORIZONTAL_ALIGNMENT,
} from "../../utils/Constants";
import { useEffect, useState } from "react";
import { ErrorMessages } from "../../helper/ErrorMessageEnum";

const SnackBarError = ({ errorMessage, setErrorMessage }) => {
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [displayMessage, setDisplayMessage] = useState(
    ErrorMessages.SOMETHING_WENT_WRONG
  );

  useEffect(() => {
    if (errorMessage && errorMessage.trim() !== "") {
      setDisplayMessage(errorMessage);
      setSnackBarOpen(true);
    }
  }, [errorMessage]);

  const handleCloseSnackbar = () => {
    setErrorMessage(null);
    setSnackBarOpen(false);
  };

  return (
    <Snackbar
      open={snackBarOpen}
      autoHideDuration={1500}
      onClose={handleCloseSnackbar}
      anchorOrigin={{
        vertical: CONST_SNACK_BAR_VERTICAL_ALIGNMENT,
        horizontal: CONST_SNACK_BAR_HORIZONTAL_ALIGNMENT,
      }}
    >
      <Alert severity="error" sx={{ width: "100%" }}>
        {displayMessage}
      </Alert>
    </Snackbar>
  );
};

export default SnackBarError;
