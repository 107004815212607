import { useState, useEffect } from "react";
import ShopProductCard from "./ProductCard";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation, Virtual } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/virtual";

export default function ProductList({ products }) {
  const [slidesPerView, setSlidesPerView] = useState(calculateSlidesPerView());

  function calculateSlidesPerView() {
    const screenWidth = window.innerWidth;
    if (screenWidth >= 1450) {
      return 4;
    } else if (screenWidth >= 1024) {
      return 3;
    } else if (screenWidth >= 768) {
      return 2;
    } else {
      return 1;
    }
  }

  useEffect(() => {
    function handleResize() {
      setSlidesPerView(calculateSlidesPerView());
    }
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div style={{ width: "100%", textAlign: "center" }}>
      <Swiper
        effect={"slide"}
        slidesPerView={slidesPerView}
        spaceBetween={slidesPerView === 1 ? 5 : 10}
        pagination={{
          type: "progressbar",
        }}
        navigation={true}
        modules={[Pagination, Navigation, Virtual]}
        className="mySwiper"
        virtual
      >
        {products.map((product) => (
          <SwiperSlide key={product.url}>
            <ShopProductCard product={product} />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}
