import { alpha, styled } from "@mui/material/styles";
import { Card, Typography } from "@mui/material";
import { formatToIndianCurrency } from "../utils/formatNumber";

const StyledIcon = styled("div")(({ theme }) => ({
  margin: "auto",
  display: "flex",
  borderRadius: "10%",
  alignItems: "center",
  width: theme.spacing(18),
  height: theme.spacing(6),
  justifyContent: "center",
}));

export default function AppWidgetSummary({
  title,
  total,
  isMobile,
  date,
  color = "primary",
  sx,
  ...other
}) {
  return (
    <Card
      sx={{
        height: 120,
        py: 2,
        boxShadow: 0,
        textAlign: "center",
        color: (theme) => theme.palette[color].darker,
        bgcolor: (theme) => theme.palette[color].lighter,
        ...sx,
      }}
      {...other}
    >
      <StyledIcon
        sx={{
          color: (theme) => theme.palette[color].dark,
          backgroundImage: (theme) =>
            `linear-gradient(135deg, ${alpha(
              theme.palette[color].dark,
              0
            )} 0%, ${alpha(theme.palette[color].dark, 0.24)} 100%)`,
        }}
      >
        {title}
      </StyledIcon>
      <Typography
        variant="subtitle1"
        sx={{ opacity: 0.72, fontWeight: "bolder" }}
      >
        Price: {formatToIndianCurrency(total)}
      </Typography>
      <Typography variant="subtitle2" sx={{ opacity: 0.72, color: "grey" }}>
        Date: {date}
      </Typography>
    </Card>
  );
}
