import { Grid } from "@mui/material";
import { StyledProductImg } from "../../helper/SearchPageHelper";
import SearchURLProductDetailsImageCarousel from "./SearchURLProductDetailsImageCarousel";

const SearchURLProductDetailsImageComponent = ({ image, subImages }) => {
  return (
    <Grid item xs={12} sm={6} md={4} style={{ textAlign: "center" }}>
      {subImages && subImages.length > 0 ? (
        <SearchURLProductDetailsImageCarousel subImages={subImages} />
      ) : (
        <StyledProductImg alt="productImage" src={image} />
      )}
    </Grid>
  );
};

export default SearchURLProductDetailsImageComponent;
