import { Helmet } from "react-helmet-async";
import { Button, Container, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";

import StartIcon from "@mui/icons-material/Start";
import { useNavigate } from "react-router-dom";
import Logo from "../../customElements/Logo";
// ----------------------------------------------------------------------

const AboutUs = () => {
  const navigate = useNavigate();
  return (
    <div className="aboutUs">
      <Helmet>
        <title> About Us | TrackMYPrice </title>
      </Helmet>
      <Logo
        sx={{
          position: "relative",
          top: { xs: 16, sm: 24, md: 40 },
          left: { xs: 16, sm: 24, md: 40 },
        }}
      />
      <Container maxWidth="lg">
        <Grid container spacing={5} sx={{ padding: "96px 12px" }}>
          <Grid item xs={12} sm={6}>
            <img
              src={`/assets/illustrations/illustration_aboutus.svg`}
              alt="aboutus"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="h4" sx={{ mb: 3 }}>
              About Us
            </Typography>
            <Typography variant="subtitle1" sx={{ opacity: 0.75, mb: 4 }}>
              At TrackMYPrice, we're your shopping ally. Our platform delivers
              real-time price tracking, historical insights, and analysis,
              ensuring informed choices. With price drop alerts and email
              notifications, you'll never miss a great deal. Transparency is key
              – while we strive for accuracy, data can change. We respect
              ownership rights and value collaboration. Join us to transform
              your shopping journey into a strategic, rewarding experience.
              Uncover the art of smarter shopping with PriceHistory today.
            </Typography>
            <Button
              type="button"
              variant="outlined"
              color="secondary"
              onClick={() => navigate("/")}
              sx={{ p: 1.25, width: "180px" }}
              endIcon={<StartIcon />}
            >
              Get Started
            </Button>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default AboutUs;
