import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Box, Container, Grid } from "@mui/material";

import SearchURLProductDetailsImageComponent from "../../components/searchPage/SearchURLProductDetailsImageComponent";
import { isSearchProductDetailsValid } from "../../utils/utilHelper";
import { fetchPriceHistory } from "../../action/SearchPageAction";
import SearchURLProductDetailsInfoComponent from "./SearchURLProductDetailsInfoComponent";
import PriceHistoryGraphComponent from "../../components/searchPage/PriceHistoryGraphComponent";
import { CONST_SEARCH_URL_QUERY_PARAM_KEY } from "../../utils/Constants";
import "./search.scss";

export const SearchURLProductDetailsComponent = ({
  searchURLProductDetails,
  handleRefreshOnDemand,
  refresh,
  setErrorMessage,
  setSuccessMessage,
}) => {
  const { image, subImages } = searchURLProductDetails;

  const location = useLocation();
  const [priceHistory, setPriceHistory] = useState({});

  useEffect(() => {
    if (isSearchProductDetailsValid(searchURLProductDetails)) {
      let params = new URLSearchParams(location.search);
      let search_url = params.get(CONST_SEARCH_URL_QUERY_PARAM_KEY);
      if (searchURLProductDetails != null && searchURLProductDetails.url) {
        search_url = searchURLProductDetails.url;
      }
      fetchPriceHistory(search_url, setPriceHistory, setErrorMessage);
    } else {
      setPriceHistory({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchURLProductDetails]);

  return (
    <div>
      <Container maxWidth="xl" sx={{ userSelect: "none" }}>
        {searchURLProductDetails && image && searchURLProductDetails.title ? (
          <Grid container spacing={3}>
            <SearchURLProductDetailsImageComponent
              image={image}
              subImages={subImages}
            />
            <Grid item xs={12} sm={6} md={8}>
              <SearchURLProductDetailsInfoComponent
                searchURLProductDetails={searchURLProductDetails}
                handleRefreshOnDemand={handleRefreshOnDemand}
                refresh={refresh}
                priceHistory={priceHistory}
                setErrorMessage={setErrorMessage}
                setSuccessMessage={setSuccessMessage}
              />
            </Grid>
            {priceHistory && Object.keys(priceHistory).length > 0 && (
              <PriceHistoryGraphComponent priceHistory={priceHistory} />
            )}
          </Grid>
        ) : (
          <div className="search_page_not_found">
            <Box
              component="img"
              alt="empty_home_page"
              src="/assets/illustrations/not-found.gif"
              sx={{
                height: "50vh",
                px: "10px",
                mx: "auto",
                mt: { xs: 5, sm: 10 },
              }}
            />
          </div>
        )}
      </Container>
    </div>
  );
};
