import { NavLink as RouterLink, useNavigate } from "react-router-dom";
import {
  Alert,
  Box,
  Button,
  List,
  ListItemText,
  Snackbar,
} from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import { useState } from "react";
import { styled } from "@mui/material/styles";
import { ListItemIcon, ListItemButton } from "@mui/material";
import { clearSession } from "../../APIs/clearSession";

const StyledNavItem = styled((props) => (
  <ListItemButton disableGutters {...props} />
))(({ theme }) => ({
  ...theme.typography.body2,
  height: 48,
  position: "relative",
  textTransform: "capitalize",
  color: theme.palette.text.secondary,
  borderRadius: theme.shape.borderRadius,
}));

const StyledNavItemIcon = styled(ListItemIcon)({
  width: 22,
  height: 22,
  color: "inherit",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

export default function NavSection({ data = [], setLoggedIn, ...other }) {
  return (
    <Box {...other}>
      <List disablePadding sx={{ p: 1 }}>
        {data.map((item) => (
          <NavItem key={item.title} item={item} setLoggedIn={setLoggedIn} />
        ))}
      </List>
    </Box>
  );
}

function NavItem({ item, setLoggedIn }) {
  const navigate = useNavigate();
  const { title, path, icon } = item;
  const [snackOpen, setSnackOpen] = useState({
    open: false,
    vertical: "top",
    horizontal: "center",
  });
  const { vertical, horizontal, open } = snackOpen;

  const handleLogout = () => {
    setSnackOpen({ ...snackOpen, open: true });
    clearSession(setLoggedIn);
    setTimeout(() => {
      navigate("/", { replace: true });
    }, 2000);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackOpen({ ...snackOpen, open: false });
  };

  return (
    <>
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert severity="info" sx={{ width: "100%" }}>
          Logging out
        </Alert>
      </Snackbar>
      {title === "logout" ? (
        <Button
          color="error"
          onClick={handleLogout}
          startIcon={<LogoutIcon />}
          sx={{ px: 3 }}
        >
          Logout
        </Button>
      ) : (
        <StyledNavItem
          className={`nav_${item.className}`}
          component={RouterLink}
          to={path}
          sx={{
            "&.active": {
              color: "text.primary",
              bgcolor: "action.selected",
              fontWeight: "fontWeightBold",
            },
          }}
        >
          <StyledNavItemIcon>{icon && icon}</StyledNavItemIcon>

          <ListItemText disableTypography primary={title} />
        </StyledNavItem>
      )}
    </>
  );
}
