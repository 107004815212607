import { createContext, useContext, useEffect, useState } from "react";
import { clearSession } from "../APIs/clearSession";
import axios from "axios";
import { ORCHESTRATION_API_ENDPOINT } from "../utils/Constants";
import { generateAuthorizationToken } from "../utils/utilHelper";
import { isMobile } from "react-device-detect";
import { removeCacheFromLocalStorge } from "../utils/caching";
import { getPriceAlertAPI } from "../APIs/getPriceAlertAPI";

export const ApiResponseContext = createContext();

export const AppContextProvider = ({ children }) => {
  const [loggedIn, setLoggedIn] = useState("");
  const [userInfo, setUserInfo] = useState(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [searchBarOpen, setSearchBarOpen] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [alertCount, setAlertCount] = useState(0);
  const showSpecialMessage = true;

  useEffect(() => {
    const updateWindowWidth = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", updateWindowWidth);
    return () => {
      window.removeEventListener("resize", updateWindowWidth);
    };
  }, []);

  useEffect(() => {
    const validateSession = async () => {
      try {
        const response = await axios.get(
          `${ORCHESTRATION_API_ENDPOINT}/session/validate`,
          {
            withCredentials: true,
            headers: {
              Authorization: generateAuthorizationToken(),
            },
          }
        );
        if (response.status === 200 && response.data === true) {
          setLoggedIn(true);
        } else {
          setLoggedIn(false);
        }
      } catch (error) {
        console.error("Error fetching validateSession:", error.message);
        setLoggedIn(false);
      }
    };
    validateSession();
  }, []);

  useEffect(() => {
    clearLocalStorage();
    if (loggedIn === false || loggedIn === "false") {
      setUserInfo(null);
    }
    if (loggedIn === true || loggedIn === "true") {
      const getUserInfo = async () => {
        try {
          const response = await axios.get(
            `${ORCHESTRATION_API_ENDPOINT}/user/userInfo`,
            {
              withCredentials: true,
              headers: {
                Authorization: generateAuthorizationToken(),
              },
            }
          );
          if (response.status !== 200) {
            clearSession(setLoggedIn);
          } else {
            setUserInfo(response.data);
          }
        } catch (error) {
          console.error("Error fetching getUserInfo:", error.message);
          clearSession(setLoggedIn);
        }
      };
      getUserInfo();
      async function fetchAlert() {
        const response = await getPriceAlertAPI();
        if (response) {
          setAlertCount(Number(response.count));
        }
      }
      fetchAlert();
    }
  }, [loggedIn]);

  return (
    <ApiResponseContext.Provider
      value={{
        setLoggedIn,
        loggedIn,
        userInfo,
        setUserInfo,
        windowWidth,
        searchBarOpen,
        setSearchBarOpen,
        isSearching,
        setIsSearching,
        isMobile,
        showSpecialMessage,
        alertCount,
        setAlertCount,
      }}
    >
      {children}
    </ApiResponseContext.Provider>
  );
};

export const removeCache = () => {
  for (let i = 0; i < localStorage.length; i++) {
    const key = localStorage.key(i);
    if (
      key.includes("AMAZON") ||
      key.startsWith("AMAZON") ||
      key.includes("FLIPKART") ||
      key.startsWith("FLIPKART") ||
      key.startsWith("SEARCH") ||
      key.startsWith("last")
    ) {
      localStorage.removeItem(key);
    }
  }
};

export const clearLocalStorage = () => {
  for (let i = 0; i < localStorage.length; i++) {
    removeCacheFromLocalStorge(localStorage.key(i));
  }
};

export function useAppContext() {
  return useContext(ApiResponseContext);
}
