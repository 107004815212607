import {
  Typography,
  Stack,
  ToggleButtonGroup,
  ToggleButton,
} from "@mui/material";
import ViewCarouselOutlinedIcon from "@mui/icons-material/ViewCarouselOutlined";
import ViewAgendaOutlinedIcon from "@mui/icons-material/ViewAgendaOutlined";
import TableViewIcon from "@mui/icons-material/TableView";

const ToggleButtonsHomePage = ({ view, setView }) => {
  const handleView = (_event, newView) => {
    if (newView !== null) {
      setView(newView);
    }
  };
  return (
    <Stack direction="row" alignItems="center" justifyContent="space-between">
      <Typography variant="h4" sx={{ mb: 5, mt: 2 }}>
        Hi, Welcome
      </Typography>
      <div className="home_toggleButtons">
        <ToggleButtonGroup
          value={view}
          exclusive
          onChange={handleView}
          aria-label="view"
        >
          <ToggleButton value="carousel" aria-label="carousel">
            <ViewCarouselOutlinedIcon />
          </ToggleButton>
          <ToggleButton value="expanded" aria-label="expanded">
            <ViewAgendaOutlinedIcon />
          </ToggleButton>
          <ToggleButton value="table" aria-label="table">
            <TableViewIcon />
          </ToggleButton>
        </ToggleButtonGroup>
      </div>
    </Stack>
  );
};

export default ToggleButtonsHomePage;
