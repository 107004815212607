import ShopProductCard from "./ProductCard";
import { Grid } from "@mui/material";
import { useRef } from "react";

export default function ProductListMobile({ products, other }) {
  const ref = useRef(null);
  return (
    <Grid
      container
      spacing={3}
      {...other}
      style={{ textAlign: "center" }}
      ref={ref}
    >
      {products.map((product) => (
        <Grid key={product.url} item xs={12} sm={6} md={3}>
          <ShopProductCard product={product} />
        </Grid>
      ))}
    </Grid>
  );
}
