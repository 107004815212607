import { Divider, TablePagination } from "@mui/material";
import ProductListMobile from "../products/ProductListMobile";
import "./homepage.scss";
import { useEffect, useState } from "react";
import _ from "lodash";

const ExpandedViewHomePage = ({ deals, dealsPerPage }) => {
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(
    dealsPerPage ? dealsPerPage : 10
  );
  const [allDeals, setAllDeals] = useState([]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  useEffect(() => {
    if (deals && Object.keys(deals).length > 0) {
      const immutableCopy = _.cloneDeep(deals);
      let temp = [];
      if (
        immutableCopy.lightningDeals &&
        immutableCopy.lightningDeals.length > 0
      ) {
        immutableCopy.lightningDeals.forEach((element) => {
          element.dealType = "LIGHTNING DEALS";
          temp.push(element);
        });
      }
      if (immutableCopy.hotDeals && immutableCopy.hotDeals.length > 0) {
        immutableCopy.hotDeals.forEach((element) => {
          element.dealType = "HOT DEALS";
          temp.push(element);
        });
      }
      if (immutableCopy.deals && immutableCopy.deals.length > 0) {
        immutableCopy.deals.forEach((element) => {
          element.dealType = "TRENDING DEALS";
          temp.push(element);
        });
      }
      let arr = [];
      setCount(temp.length);
      let pageStart = page * rowsPerPage;
      let pageEnd = pageStart + rowsPerPage;
      for (
        let index = pageStart;
        index < Math.min(pageEnd, temp.length);
        index++
      ) {
        arr.push(temp[index]);
      }
      setAllDeals([...arr]);
    }
  }, [count, deals, page, rowsPerPage]);

  return (
    <div className="expanded_homepage_view">
      {allDeals && allDeals.length > 0 && (
        <div style={{ marginBottom: "48px" }}>
          <Divider />
          <ProductListMobile products={allDeals} />
          <TablePagination
            sx={{ marginTop: "20px", float: "left" }}
            component="div"
            count={count}
            page={page}
            rowsPerPageOptions={[10, 25, 50]}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      )}
    </div>
  );
};

export default ExpandedViewHomePage;
