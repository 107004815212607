import { useState, useEffect } from "react";
import {
  Collapse,
  Grid,
  List,
  Typography,
  ToggleButtonGroup,
  ToggleButton,
  ListItemButton,
  ListItemText,
  Button,
} from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { subDays } from "date-fns";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Brush,
  ReferenceLine,
} from "recharts";
import { useAppContext } from "../../store/AppContextProvider";
import {
  generatePriceGraphData,
  handleApplyDates,
} from "../../action/SearchPageAction";
import { isMobile } from "react-device-detect";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { isPriceHistoryGraphDataValid } from "../../utils/utilHelper";
import {
  defaultStaticRanges,
  staticInputRange,
} from "../../utils/getStaticDateRange";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";

const PriceHistoryGraphComponent = ({ priceHistory }) => {
  let {
    minimumPrice: { price: priceHistoryMinimumPrice },
    maximumPrice: { price: priceHistoryMaximumPrice },
    price: originalPriceHistoryGraphData,
  } = priceHistory;
  const { windowWidth } = useAppContext();
  const [open, setOpen] = useState(false);
  const [alignment, setAlignment] = useState("1M");
  const [priceHistoryGraphData, setPriceHistoryGraphData] = useState([]);
  const [dates, setDates] = useState([
    {
      startDate: subDays(new Date(), 7),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  useEffect(() => {
    if (
      isPriceHistoryGraphDataValid(originalPriceHistoryGraphData) &&
      alignment !== "None"
    ) {
      setPriceHistoryGraphData(
        generatePriceGraphData(alignment, originalPriceHistoryGraphData)
      );
    }
  }, [alignment, originalPriceHistoryGraphData]);

  const applyDates = () => {
    setAlignment("None");
    let newDates = handleApplyDates(dates, originalPriceHistoryGraphData);
    setPriceHistoryGraphData([...newDates]);
  };

  const resetDates = () => {
    setAlignment("1M");
    setOpen(false);
  };

  return (
    <>
      <Grid item xs={12} sx={{ py: 2 }}>
        <Grid item xs={12}>
          <Typography variant="h5">PRICE HISTORY</Typography>
        </Grid>
        {priceHistoryGraphData && priceHistoryGraphData.length > 0 ? (
          <div className="price_history_content">
            <div
              style={{
                display: "flex",
                margin: "12px 0",
              }}
            >
              <div style={{ width: "175px" }}>
                <ToggleButtonGroup
                  color="primary"
                  value={alignment}
                  exclusive
                  onChange={(_event, newAlignment) =>
                    setAlignment(newAlignment)
                  }
                  aria-label="Platform"
                >
                  <ToggleButton value="1W">1W</ToggleButton>
                  <ToggleButton value="1M">1M</ToggleButton>
                  <ToggleButton value="1Y">1Y</ToggleButton>
                </ToggleButtonGroup>
              </div>
              {!isMobile && (
                <>
                  <Typography
                    variant="subtitle1"
                    color="grey"
                    sx={{
                      px: 4,
                      mt: 1.5,
                    }}
                  >
                    OR
                  </Typography>
                  <Grid item xs={12} style={{ display: "flex" }}>
                    <ListItemButton
                      onClick={() => setOpen(!open)}
                      style={{
                        maxWidth: "175px",
                        backgroundColor: "#e8e8e8",
                      }}
                    >
                      <ListItemText primary="Select Date" />
                      {open ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    {open && (
                      <Button
                        style={{ width: "140px", marginLeft: "5px" }}
                        variant="outlined"
                        onClick={applyDates}
                      >
                        Apply Dates
                      </Button>
                    )}
                    {open && alignment === "None" && (
                      <Button
                        style={{ width: "140px" }}
                        variant="text"
                        onClick={resetDates}
                      >
                        Reset Dates
                      </Button>
                    )}
                  </Grid>
                </>
              )}
            </div>
            <Grid item xs={12}>
              <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <DateRangePicker
                    style={{ zIndex: 10 }}
                    maxDate={new Date()}
                    staticRanges={defaultStaticRanges}
                    inputRanges={staticInputRange}
                    onChange={(item) => setDates([item.selection])}
                    showSelectionPreview={true}
                    editableDateInputs={true}
                    moveRangeOnFirstSelection={false}
                    months={2}
                    ranges={dates}
                    direction="horizontal"
                  />
                </List>
              </Collapse>
            </Grid>
            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                justifyContent: "flex-start",
                marginTop: "12px",
              }}
            >
              <LineChart
                width={
                  isMobile
                    ? windowWidth - 40
                    : windowWidth > 1500
                    ? 1400
                    : windowWidth - 120
                }
                height={400}
                data={priceHistoryGraphData}
              >
                <XAxis dataKey="date" />
                <YAxis />
                <CartesianGrid strokeDasharray="3 3" />
                <Tooltip />
                <Legend />
                <Line type="monotone" dataKey="price" stroke="#8884d8" />
                <ReferenceLine y={priceHistoryMinimumPrice} stroke="green" />
                <ReferenceLine y={priceHistoryMaximumPrice} stroke="red" />
                <Brush dataKey="price" height={30} stroke="#8884d8" />
              </LineChart>
            </Grid>
          </div>
        ) : (
          <div
            style={{
              width: "100%",
              height: "200px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography variant="h5" sx={{ opacity: 0.72, color: "grey" }}>
              Currently Unavailable! Please check again tomorrow.
            </Typography>
          </div>
        )}
      </Grid>
    </>
  );
};

export default PriceHistoryGraphComponent;
